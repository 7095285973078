import { AxiosError, AxiosResponse } from 'axios';
import { TAPIError } from '../types/api';

const makeErrorObject = (error: AxiosError<TAPIError>) : TAPIError => {
  let res: TAPIError = { detail: {}, statusCode: error.response?.status || 0 };
  if (error.response) {
    const { data: { detail } } = error.response as AxiosResponse<TAPIError>;
    if (detail) {
      res = { ...res, detail };
    } else if (!detail && !error.response && !!error.request && error.isAxiosError) {
      res = { ...res, detail: { Network: 'experiencing connection or CORS problem(s)' } };
    } else {
      res = { ...res, detail: { General: 'error of unknown nature :(' } };
    }
  } else if (error.request) {
    return error.request
  } else res = { ...res, detail: { Error: error.message } };
  return res;
};

export default makeErrorObject;
