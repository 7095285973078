import { 
  useCallback, 
  useEffect,
  useRef, 
  useState 
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Text from '../../ui/text';
import styles from '../delivery/delivery.module.css';
import Button from '../../ui/buttons/button';
import stylesButton from '../../components/questions/questions.module.css';
import cn from 'classnames';
import { deliveryDateInit } from '../../utils/data';
import { TDeliveryDate } from '../../services/types/data';
import { useFormDeliveryDate } from '../../utils/validation';
import List from '../../ui/list';
import { dateFormatter } from '../../utils/functions';
import { messages } from '../../utils/messages';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import Modal from '../../components/modal/modal';
import deleteDeliveryDateThunk from '../../thunks/delivery-dates/delete-delivery-date';
import ModalDelete from '../../components/modal-delete/modal-delete';
import FormDeliveryDate from '../../components/form-delivery-date/form-delivery-date';
import getDeliveryDatesThunk from '../../thunks/delivery-dates/delivery-dates';
import { LIMIT_DELIVERY_DATES } from '../../utils/constants';
import { Loader } from '../../ui/loader/loader';
import ScrollButton from '../../ui/buttons/scroll-button';

export function DeliveryDatePage() {
  const dispatch = useDispatch();
  const { deliveryDates } = useSelector(state => state.main);
  const { deliveryDatesAll } = useSelector(state => state.admin);
  const { 
    deliveryDatesRequest,
    deliveryDatesSuccess,
    deliveryDatesFailed,
    errorObject,
    skip, 
    lastPage,
  } = useSelector(state => state.apiAdmin);

  const [visible, setVisible] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [nameButton, setNameButton] = useState({
    form: 'Создать',
    filter: 'Показать все',
  });
  const [errorText, setErrorText] = useState('');

  const [deliveryDate, setDeliveryDate] = useState<TDeliveryDate>(deliveryDateInit);
  const { handleChange } = useFormDeliveryDate(deliveryDate, setDeliveryDate, setErrorText);

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const getDeliveryDates = useCallback((currentSkip: number) => {
    dispatch(getDeliveryDatesThunk({
      skip: currentSkip,
      limit: LIMIT_DELIVERY_DATES,
      all: true,
    }))
  }, [dispatch]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getDeliveryDates(skip);
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, getDeliveryDates, lastPage]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (deliveryDatesSuccess) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, deliveryDatesSuccess]
  );

  useEffect(() => {
    if (nameButton.filter === 'Показать актуальные') {
      getDeliveryDates(0)
    }
  }, [nameButton, getDeliveryDates]);

  useEffect(() => {
    if (deliveryDatesSuccess) {
      setVisible(prevVisible => ({
        ...prevVisible,
        add: false,
        edit: false,
      }));
      setDeliveryDate(deliveryDateInit);
    }
  }, [deliveryDatesSuccess]);

  useEffect(() => {
    if (deliveryDatesFailed && errorObject?.statusCode === 422) {
      setErrorText(messages.dateError)
    }
  }, [deliveryDatesFailed, errorObject?.statusCode]);

  useEffect(() => {
    setNameButton(prevName => ({
      ...prevName,
      form: visible.add ? 'Скрыть форму' : 'Создать',
    }));
    if (visible.add) {
      setDeliveryDate(deliveryDateInit);
      setErrorText('');
    }
  }, [visible]);

  const onClickToggle = () => {
    if (nameButton.form === 'Создать') {
      setVisible({
        ...visible,
        add: true,
      });
    }
    else setVisible({
      ...visible,
      add: false,
    });
  };

  const handleCloseModal = () => {
    setVisible({
      add: false,
      edit: false,
      delete: false,
    });
    setDeliveryDate(deliveryDateInit);
    setErrorText('');
  };

  const onClickEdit = (date: TDeliveryDate) => {
    setDeliveryDate(date)
    setVisible({
      ...visible,
      add: false,
      edit: true,
    });
    setErrorText('');
  };

  const onClickDelete = (date: TDeliveryDate) => {
    setDeliveryDate(date)
    setVisible({
      ...visible,
      add: false,
      delete: true,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteDeliveryDateThunk(deliveryDate.id));
    setVisible({
      ...visible,
      delete: false,
    });
  };

  const onClickFilter = () => {
    setNameButton({
      ...nameButton,
      filter: nameButton.filter === 'Показать все' ? 'Показать актуальные' : 'Показать все',
    });
  };

  const modalEdit = (
    <Modal
      header='Редактирование даты доставки'
      onClose={handleCloseModal}
      extraClass={styles.modal}
      classTitle={styles.modal_title}
    >
      <FormDeliveryDate
        handleCancel={handleCloseModal}
        value={deliveryDate}
        isAdd={visible.add}
        handleChange={handleChange}
        error={errorText}
      />
    </Modal>
  );

  return (
    <article className={styles.content}>
      <div className={styles.box}>
        <Button
          className={cn(stylesButton.button_admin, styles.button)}
          clickHandler={onClickFilter}
        >
          <Text text={nameButton.filter} />
        </Button>
        <Button
          className={cn(stylesButton.button_admin, styles.button)}
          clickHandler={onClickToggle}
        >
          <Text text={nameButton.form} />
        </Button>
      </div>
      {visible.add && (
        <FormDeliveryDate
          handleCancel={handleCloseModal}
          value={deliveryDate}
          isAdd={visible.add}
          handleChange={handleChange}
          error={errorText}
        />
      )}
      <List className={styles.list}>
        {(nameButton.filter === 'Показать все' ? deliveryDates : deliveryDatesAll)?.map(item => (
          <ListItem className={styles.item} key={item.id}>
            <Title
              type='h4'
              text={dateFormatter(new Date(item.date))}
              className={cn(styles.title, styles.grid_item)}
            />
            <Text text={item.comment} className={cn(styles.text, styles.grid_item)} />
            <Text 
              text={item.show ? 'Отображается' : 'Не отображается'}
              className={cn(styles.text, styles.grid_item)}
            />
            <EditButton onClickHandler={() => onClickEdit(item)} />
            <CloseButton
              onClose={() => onClickDelete(item)}
              size='small'
              del={!item['already-use'] ? true : false}
              disabled={item['already-use'] && true}
            />
          </ListItem>
        ))}
        {deliveryDatesRequest && (nameButton.filter === 'Показать все' ? deliveryDates.length > 0 : deliveryDatesAll.length > 0) && (
          <li>
            <Loader size='large' />
          </li>
        )}
        {(!deliveryDatesRequest && nameButton.filter === 'Показать все' ? deliveryDates.length === 0 : deliveryDatesAll.length === 0) && (
          <li>
            <Text text='По вашему запросу ничего не найдено' />
          </li>
        )}
        <li ref={loadMoreCallback}></li>
      </List>
      <ScrollButton />
      {visible.edit && modalEdit}
      {visible.delete && (
        <ModalDelete
          header='Подтвердите удаление даты доставки:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={deliveryDate.date && dateFormatter(new Date(deliveryDate.date))}
        />
      )}
    </article>
  )
};