import { AppThunk } from '../../services/types/store';
import { TAPIDataScore, TAPIError } from '../../services/types/api';
import { 
  postScoreFailed, 
  postScoreRequest,
  postScoreSuccess
} from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPostScore } from '../../services/api/api-scores';

const postScoreThunk: AppThunk = (
  data: TAPIDataScore,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postScoreRequest());
    await fetchPostScore(data, token);
    dispatch(postScoreSuccess());
  } catch (error) {
    dispatch(postScoreFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postScoreThunk;