import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  updateMyCartRequest,
  updateMyCartSuccess,
  updateMyCartFailed,
} from '../../store/api-slice';
import { TAPIError, TAPIDataCart } from '../../services/types/api';
import { TUpdateCartElement } from '../../services/types/data';
import { fetchUpdateMyCart } from '../../services/api/api-cart';

const updateMyCartThunk: AppThunk = (
  dataUpdate: Array<TUpdateCartElement>,
  token: string,
) => async (dispatch) => {
  const data: TAPIDataCart = {
    items: dataUpdate,
  };
  try {
    dispatch(updateMyCartRequest());
    await fetchUpdateMyCart(data, token);
    dispatch(updateMyCartSuccess());
  } catch (error) {
    dispatch(updateMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateMyCartThunk;