import { 
  useEffect,
  useState,
  FC,
  useRef,
} from 'react';
import Button from '../../ui/buttons/button';
import styles from './dropdown.module.css';
import { TArrSelect } from '../../services/types/data';
import List from '../../ui/list';
import CategoriesItem from '../categories-item/categories-item';
import cn from 'classnames';

type TDropdownProps = {
  categories: Array<TArrSelect>;
  active?: string;
}

const Dropdown: FC<TDropdownProps> = ({ 
  categories,
  active,
}) => {
  const [open, setOpen] = useState(false);
  const [activeTitle, setActiveTitle] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);

  const onClickHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setActiveTitle(categories.find(item => item.id === active)?.title)
  }, [active, categories]);
  
  useEffect(() => {
    setOpen(false)
  }, [active]);

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, []);

  return (
    <div className={styles.container}  ref={ref}>
      <Button 
        className={cn(styles.button, open && styles.button_up)}
        clickHandler={onClickHandler}
      >
        {activeTitle}
      </Button>
      {open && (
        <List className={styles.list}>
          {categories.map(item => (
            <CategoriesItem
              key={item.id}
              title={item.title}
              isSelect={true}
              id={item.id}
            />
          ))}
        </List>
      )}
    </div>
  )
};

export default Dropdown;
