import { FC, CSSProperties } from 'react';
import cn from 'classnames';
import styles from './searchbar.module.css';
import CloseButton from '../../ui/buttons/close-button';
import SearchButton from '../../ui/buttons/search-button';

interface ISearchBarProps {
  inputValue: string;
  visible: boolean,
  onClickClose: () => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSearch: () => void;
  style?: CSSProperties;
  classButton: string;
}

const SearchBar: FC<ISearchBarProps> = ({ 
  onClickClose,
  inputValue,
  onChangeInput,
  onClickSearch,
  visible,
  style,
  classButton,
}) => (
  <div 
    className={cn(styles.container)} 
    style={style}
  >
    <SearchButton 
      onClickHandler={onClickSearch} 
      extraClass={styles.button} 
    />
    <input 
      type='text' 
      onChange={onChangeInput} 
      value={inputValue} 
      className={cn(
        styles.input, 
        visible ? '' : styles.hide
      )}
    />
    <CloseButton 
      onClose={onClickClose} 
      size='small' 
      extraClass={classButton} 
      style={{
        opacity: visible ? '1' : '0'
      }}
    />
  </div>
)

export default SearchBar;