import { FC, CSSProperties } from 'react';
import cn from 'classnames';
import styles from './styles.module.css';

interface IButtonProps {
  children: React.ReactNode;
  className?: string;
  clickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}

const Button: FC<IButtonProps> = ({ 
  className, 
  children, 
  clickHandler,
  disabled,
  style,
  type = 'button'
}) => (
  <button 
    type={type} 
    className={cn(styles.button, className)}
    onClick={clickHandler}
    disabled={disabled}
    style={style}
  >
    {children}
  </button>
);

export default Button;