import { 
  useEffect,
  useRef,
  useCallback,
  FC,
} from 'react';
import { 
  useParams,
  useLocation
} from 'react-router-dom';
import {
  useDispatch, useSelector,
} from '../../services/hooks';
import List from '../../ui/list';
import Card from '../card/card';
import styles from './elements.module.css';
import { LIMIT_SEEDLINGS } from '../../utils/constants';
import { Loader } from '../../ui/loader/loader';
import ScrollButton from '../../ui/buttons/scroll-button';
import Text from '../../ui/text';
import getSeedlingsThunk from '../../thunks/products/seedlings';
import { TSeedling } from '../../services/types/data';
import CardAdmin from '../card-admin/card-admin';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

interface IElementsProps {
  category?: string;
  search?: string;
  seedlingId?: string;
  setSeedlingId?: React.Dispatch<React.SetStateAction<string>>;
}

const Elements: FC<IElementsProps> = ({ 
  category,
  search,
  seedlingId,
  setSeedlingId,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams<{ id?: string }>();
  const { name } = useParams<{ name?: string}>();
  const { skip, seedlingsRequest, lastPage } = useSelector(state => state.api);
  const { seedlingSuccess } = useSelector(state => state.apiAdmin);
  const { seedlings, categories } = useSelector(state => state.main);

  const categoryActive = categories?.find(category => category.id === id)?.title;
  const title = categoryActive ? categoryActive + ' | ' : '';

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const seedlingsData = pathname.includes('admin-panel') ?
    seedlings :
    seedlings?.filter(item => item.available === true);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          dispatch(getSeedlingsThunk({
            category: category ? category : id,
            skip,
            limit: LIMIT_SEEDLINGS,
            search: search ? search : name
          }))
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, category, dispatch, id, lastPage, name, search]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (seedlingsRequest) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, seedlingsRequest]
  );

  useEffect(() => {
    dispatch(getSeedlingsThunk({
      category: category ? category : id,
      skip: 0,
      limit: LIMIT_SEEDLINGS,
      search: search ? search : name,
    }))
  }, [pathname, seedlingSuccess, category, search, dispatch, id, name]);

  const card = (item: TSeedling) => {
    if (pathname.includes('admin-panel')) {
      return (
        <CardAdmin 
          key={item.id} 
          card={item} 
          seedlingId={seedlingId}
          setSeedlingId={setSeedlingId}
        />
      )
    } else {
      return <Card key={item.id} card={item} />
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${title}${linksNav.seedlings}`}</title>
      </Helmet>
      <List className={pathname.includes('admin-panel') ? styles.admin : styles.list}>
        {seedlingsData?.map(item => (
          card(item)
        ))}
        {seedlingsRequest && seedlingsData.length > 0 && (
          <li>
            <Loader size='large' />
          </li>
        )}
        {!seedlingsRequest && seedlingsData.length === 0 ? (
          <li className={styles.empty}>
            <Text
              text='По вашему запросу ничего не найдено'
              className={styles.text}
            />
          </li>
        ) : <li ref={loadMoreCallback}></li>}
      </List>
      <ScrollButton />
    </HelmetProvider>
  )
};

export default Elements;