import { FC, useState } from 'react';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import stylesButton from '../../components/questions/questions.module.css';
import FileInput from '../../components/file-input/file-input';
import Checkbox from '../../ui/inputs/checkbox';
import { TCategory, TFormSeedling } from '../../services/types/data';
import Input from '../../ui/inputs/input';
import Form from '../../ui/form/form';
import styles from './form-seedling.module.css';
import { TAPISeedlingFull } from '../../services/types/api';
import { useDispatch } from '../../services/hooks';
import updateSeedlingThunk from '../../thunks/products/update-seedling';
import Select from '../../ui/inputs/select';
import ReactQuill from 'react-quill-new';
import 'react-quill/dist/quill.snow.css';

interface IFormSeedlingProps {
  handleChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => void;
  values: TFormSeedling;
  categories: ReadonlyArray<TCategory> | null;
  error?: string;
  handleCancel: () => void;
  id: string;
  setClickId: React.Dispatch<React.SetStateAction<string>>;
  handleChangeImage: (file: string) => void;
  file: string | null;
}

const FormSeedling: FC<IFormSeedlingProps> = ({
  handleChange,
  values,
  categories,
  error,
  handleCancel,
  id,
  setClickId,
  handleChangeImage,
  file,
}) => {

  const dispatch = useDispatch();
  const [value, setValue] = useState(values['full-description']);

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const categoryId = categories?.find(({ title }) => title === values.category)?.id;

    const dataUpdateSeedling: TAPISeedlingFull = {
      ...values,
      image: file || '',
      category: categoryId || '',
      'full-description': value,
    };
    dispatch(updateSeedlingThunk(dataUpdateSeedling, id));
    setClickId(id)
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'align',
  ];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'align': [] }]
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  return (
    <Form
      name='seedling'
      onSubmit={onClickSubmit}
      className={styles.form}
      legend='Редактирование саженца'
      classFieldset={styles.fieldset}
      >
      <Input
        label='Название саженца'
        name='title'
        onChange={handleChange}
        required={true}
        minLength={3}
        maxLength={70}
        value={values.title}
        extraClass={styles.placeholder}
      />
      <FileInput
        classImage={styles.image}
        onChange={handleChangeImage}
        image={values?.image}
      />
      <Input
        label='Краткое описание'
        name='short-description'
        onChange={handleChange}
        required={true}
        maxLength={70}
        value={values['short-description']}
        extraClass={styles.placeholder}
      />
      <div className={styles.placeholder}>
        <Text text='Подробное описание' />
        <ReactQuill
          theme='snow'
          value={value}
          onChange={setValue}
          formats={formats}
          modules={modules}
        />
      </div>
      <Select
        name='category'
        value={values.category}
        onChange={handleChange}
        values={categories}
        label='Выбрать категорию'
      />
      <Input 
        label='Розничная цена'
        name='retail-price'
        onChange={handleChange}
        type='number'
        value={values['retail-price']}
        required
        extraClass={styles.placeholder}
        min={0}
      />
      <Input 
        label='Оптовая цена'
        name='wholesale-price'
        onChange={handleChange}
        type='number'
        value={values['wholesale-price']}
        required
        extraClass={styles.placeholder}
        min={0}
      />
      <Input 
        label='Наличие'
        name='stock'
        onChange={handleChange}
        type='number'
        value={values.stock}
        required
        extraClass={styles.placeholder}
        min={0}
        max={999998}
      />
      <Input 
        label='Порядок отображения'
        name='display-order'
        onChange={handleChange}
        type='number'
        value={values['display-order']}
        required
        extraClass={styles.placeholder}
        min={0}
        max={99}
      />
      <Checkbox
        name='available'
        label='Видимость'
        checked={values.available}
        onChange={handleChange}
      />
      {error && <Text text={error} className={styles.error} />}
      <div className={styles.box}>
        <Button
          className={stylesButton.button_admin}
          type='submit'
        >
          <Text text='Изменить' />
        </Button>
        <Button
          className={stylesButton.button_admin}
          clickHandler={handleCancel}
        >
          <Text text='Отменить' />
        </Button>
      </div>
    </Form>
  )
};

export default FormSeedling;