import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  getDeliveryChooseDatesFailed,
  getDeliveryChooseDatesRequest,
  getDeliveryChooseDatesSuccess,
} from '../../store/api-slice';
import { getDeliveryChooseDate } from '../../store/main-slice';
import { fetchDeliveryChooseDates } from '../../services/api/api-delivery-events';

const getDeliveryChooseDateThunk: AppThunk = (
  pointId: string,
) => async (dispatch) => {
  try {
    dispatch(getDeliveryChooseDatesRequest());
    const response = await fetchDeliveryChooseDates(pointId);
    dispatch(getDeliveryChooseDate(response.data));
    dispatch(getDeliveryChooseDatesSuccess());
  } catch (error) {
    dispatch(getDeliveryChooseDatesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getDeliveryChooseDateThunk;