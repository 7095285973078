import { AppThunk } from '../../services/types/store';
import { TAPIDataDeliveryPoint, TAPIError } from '../../services/types/api';
import { 
  postDeliveryPointsFailed,
  postDeliveryPointsRequest,
  postDeliveryPointsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPostDeliveryPoints } from '../../services/api/api-delivery-points';

const postDeliveryPointThunk: AppThunk = (
  data: TAPIDataDeliveryPoint,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postDeliveryPointsRequest());
    await fetchPostDeliveryPoints(data, token);
    dispatch(postDeliveryPointsSuccess());
  } catch (error) {
    dispatch(postDeliveryPointsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postDeliveryPointThunk;