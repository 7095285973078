import { FC } from 'react';
import styles from './form-delivery.module.css';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import stylesButton from '../questions/questions.module.css';
import Form from '../../ui/form/form';

interface IFormDeliveryProps {
  name: string;
  legend: string;
  onClickSubmit: (e: React.FormEvent) => void;
  handleCancel: () => void;
  children?: React.ReactNode;
  id?: string;
}

const FormDelivery: FC<IFormDeliveryProps> = ({
  name,
  legend,
  onClickSubmit,
  handleCancel,
  children,
  id,
}) => (
  <Form
    name={name}
    onSubmit={onClickSubmit}
    className={styles.form}
    legend={legend}
    classFieldset={styles.fieldset}
  >
    {children}
    <div className={styles.box}>
      <Button
        className={stylesButton.button_admin}
        type='submit'
      >
        <Text text={id ? 'Изменить' : 'Создать'} />
      </Button>
      <Button
        className={stylesButton.button_admin}
        clickHandler={handleCancel}
      >
        <Text text='Отменить' />
      </Button>
    </div>
  </Form>
);

export default FormDelivery;
