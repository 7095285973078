import { AppThunk } from '../../services/types/store';
import { fetchPostAnswer } from '../../services/api/api-questions';
import { TAPIError } from '../../services/types/api';
import { 
  answerFailed, 
  answerRequest, 
  answerSuccess 
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';

const postAnswerThunk: AppThunk = (
  questionId: string,
  answer: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(answerRequest());
    await fetchPostAnswer(questionId, answer, token);
    dispatch(answerSuccess());
  } catch (error) {
    dispatch(answerFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postAnswerThunk;