import {
  useSelector
} from '../../services/hooks';
import Title from '../../ui/title/title';
import { title } from '../../utils/data';
import CategoriesItem from '../categories-item/categories-item';
import List from '../../ui/list';
import styles from './categories.module.css';

function Categories() {
  const { categories } = useSelector(state => state.main);
  const categoriesData = categories?.filter(item => item.available === true)

  return (
    <section className={styles.container}>
      <Title type='h1' text={title} />
      <List className={styles.list}>
        {categoriesData?.map(item => (
          <CategoriesItem 
            key={item.id} 
            title={item.title} 
            image={item.image} 
            isSelect={false} 
            id={item.id}
          />
        ))}
      </List>
    </section>
  )
}

export default Categories;
