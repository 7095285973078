import { 
  useCallback, 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from '../../services/hooks';
import { LIMIT_ORDERS } from '../../utils/constants';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import getOrdersThunk from '../../thunks/orders/orders';
import { dateFormatter, priceFormat } from '../../utils/functions';
import Text from '../../ui/text';
import styles from './orders.module.css';
import cn from 'classnames';
import Checkbox from '../../ui/inputs/checkbox';
import Button from '../../ui/buttons/button';
import createStatementThunk from '../../thunks/orders/create-statement';
import Select from '../../ui/inputs/select';
import getOrdersChangeAllowedThunk from '../../thunks/orders/orders-change-allowed';
import ModalDelete from '../../components/modal-delete/modal-delete';


export function OrdersPage() {
  const dispatch = useDispatch();
  const { 
    skip, 
    lastPage,
    ordersRequest,
    getOrdersChangeAllowedSuccess,
    createStatementSuccess,
  } = useSelector(state => state.api);
  const { 
    orders,
    deliveryDates,
    ordersAllowed,
  } = useSelector(state => state.main);
  const { 
    token,
    'is-admin': isAdmin,
    'is-superuser': isSuperuser 
  } = useSelector(state => state.user);
  const [nameButton, setNameButton] = useState({
    new: 'Новые заказы',
    date: 'Выбрать дату доставки',
    allow: 'Заблокировать заказы',
  });
  const [visible, setVisible] = useState(false);
  const [selectValue, setSelectValue] = useState(deliveryDates[0]?.date);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (getOrdersChangeAllowedSuccess) {
      setModalVisible(false);
    }
  }, [getOrdersChangeAllowedSuccess]);

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const getOrders = useCallback((currentSkip: number) => {
    dispatch(getOrdersThunk(token, isAdmin || isSuperuser, {
      skip: currentSkip,
      limit: LIMIT_ORDERS,
      editable: nameButton.new === 'Новые заказы' ? false : true,
      'delivery-date': nameButton.date === 'Все заказы' ? selectValue : '',
    }));
  }, [dispatch, token, nameButton.new, nameButton.date, isAdmin, isSuperuser, selectValue]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getOrders(skip)
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, getOrders, lastPage]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (ordersRequest) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, ordersRequest]
  );

  useEffect(() => {
    token && getOrders(0)
  }, [dispatch, getOrders, token]);

  useEffect(() => {
    setNameButton(prevName => ({
      ...prevName,
      allow: ordersAllowed ? 'Заблокировать заказы' : 'Разрешить заказы'
    }))
  }, [ordersAllowed]);

  interface IStatement {
    [name: string]: boolean; 
  }

  const [statement, setStatement] = useState<IStatement>({});
  const prevStatementRef = useRef<IStatement>(statement);

  const onChangeChoose = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.checked;
    setStatement(prevStatement => ({
      ...prevStatement,
      [name]: value,
    }));
  }, []);

  const [listStatementId, setListStatementId] = useState<Array<string>>([])

  const createStatement = useCallback(() => {
    const listId = Object.keys(statement).filter(el => statement[el]);
    dispatch(createStatementThunk(listId, token));
  }, [statement, token, dispatch]);

  useEffect(() => {
    prevStatementRef.current = statement;
  }, [statement]);

  useEffect(() => {
    if (createStatementSuccess) {
      const newIds = Object.keys(prevStatementRef.current).filter(el => prevStatementRef.current[el]);
      setListStatementId(prevListStatementId => [
        ...prevListStatementId,
        ...newIds,
      ]);
      setStatement({});
    }
  }, [createStatementSuccess]);

  const onClickFilter = () => {
    setNameButton({
      ...nameButton,
      new: nameButton.new === 'Все заказы' ? 'Новые заказы' : 'Все заказы'
    })
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target
    const value = target.value;
    setSelectValue(value);
  };

  const onClickDate = () => {
    setVisible(nameButton.date === 'Все заказы' ? false : true)
    setNameButton({
      ...nameButton,
      date: nameButton.date === 'Все заказы' ? 'Выбрать дату доставки' : 'Все заказы'
    })
  };

  const onChangeAllow = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const confirmChangeAllow = () => {
    token && dispatch(getOrdersChangeAllowedThunk(token));
  };

  return (
    <section className={styles.content}>
      {(isAdmin || isSuperuser) && (<>
        <Button
          clickHandler={onClickFilter}
          className={styles.button}
        >
          <Text text={nameButton.new} />
        </Button>
        <div className={cn(styles.box, styles.right)}>
          {visible && (
            <Select
              name='date'
              valuesDeliveryDates={deliveryDates}
              onChange={handleChange}
              value={selectValue}
              label=''
            />
          )}
          <Button
            className={cn(styles.button, styles.size)}
            clickHandler={onClickDate}
            >
            <Text text={nameButton.date} />
          </Button>
        </div>
        <Button
          clickHandler={createStatement}
          className={styles.button}
        >
          <Text text='Сформировать ведомость' />
        </Button>
        <Button
          clickHandler={onChangeAllow}
          className={cn(styles.button, !ordersAllowed && styles.color)}
        >
          <Text text={nameButton.allow} />
        </Button>
      </>)}
      <List className={styles.list}>
        {orders?.map(item => (
          <ListItem key={item.id} className={styles.item}>
            <Link to={{ pathname: item.id }} className={styles.link}>
              <Title
                type='h4'
                text={`№ ${item.number} от ${dateFormatter(new Date(item['created-at']))}`}
                className={styles.title}
              />
              <Text 
                text={`${item['first-name']} ${item['last-name']}`} 
                className={styles.text_optional} 
              />
              <Text 
                text={item.email} 
                className={cn(styles.text, styles.mail, styles.text_optional)} 
              />
              <Text 
                text={item['phone-number']} 
                className={cn(styles.text, styles.tel, styles.text_optional)} 
              />
              <Text 
                text={`Сумма заказа: ${String(priceFormat(item.amount))}`} 
                className={styles.text_optional} 
              />
            </Link>
            {(isAdmin || isSuperuser) ? (
              <div className={styles.status}>
                {
                  !item['not-editable'] &&
                  !item.cancelled && 
                  !listStatementId.some(el => el === item.id) &&
                  <Text text='Новый заказ' />}
                {item.cancelled ? (
                  <Text text='Заказ отменён' className={styles.color} />
                ) : (
                  <Checkbox
                    name={item.id}
                    label=''
                    onChange={onChangeChoose}
                    checked={item.id in statement ? statement[item.id] : false}
                  />
                )}
              </div>
            ) : (item.cancelled && <Text text='Заказ отменён' className={cn(styles.color, styles.status)} />)}
          </ListItem>
        ))}
        <li ref={loadMoreCallback}></li>
        {!ordersRequest && orders.length === 0 && (
          <li className={styles.empty}>
            <Text
              text={nameButton.date === 'Все заказы' ? 'На выбранную дату нет оформленных заказов': 'Нет оформленных заказов'}
            />
          </li>
        )}
      </List>
      {modalVisible && (
        <ModalDelete
          header={nameButton.allow}
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmChangeAllow}
          text={''}
        />
      )}
    </section>
  )
}
