import { useEffect, useState } from 'react';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import stylesButton from '../../components/questions/questions.module.css';
import { useDispatch, useSelector } from '../../services/hooks';
import Elements from '../../components/elements/elements';
import { useFormSeedling } from '../../utils/validation';
import Input from '../../ui/inputs/input';
import styles from './products.module.css';
import { TAPIObjectError, TAPISeedlingFull } from '../../services/types/api';
import postSeedlingThunk from '../../thunks/products/post-seedling';
import Select from '../../ui/inputs/select';
import { TArrSelect } from '../../services/types/data';
import SearchBar from '../../components/search-bar/search-bar';
import FormAdmin from '../../components/form-admin/form-admin';
import ReactQuill from 'react-quill-new';


export function ProductsPage() {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.main);
  const { seedlingSuccess, errorObject } = useSelector(state => state.apiAdmin);
  const [nameButton, setNameButton] = useState('Создать');
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState<string | null>(null);
  const [error, setError] = useState('');
  const { values, handleChange, resetForm } = useFormSeedling();
  const [seedlingId, setSeedlingId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchName, setSearchName] = useState('');

  const categoryId = (value: string) => (categories?.find(({ title }) => title === value)?.id);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (file) setError('');
  }, [file]);

  useEffect(() => {
    if (seedlingSuccess) {
      setVisible(false);
      resetForm();
      setFile(null);
    }
  }, [seedlingSuccess, resetForm]);

  useEffect(() => {
    const textError = errorObject?.detail?.[0] as TAPIObjectError;
    if (errorObject?.statusCode === 422 && textError.msg.includes('already exists')) {
      setError('Саженец с таким названием уже существует')
    }
  }, [errorObject]);

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (file && categoryId) {
      const dataPostSeedling: TAPISeedlingFull = {
        ...values,
        image: file,
        category: categoryId(values.category)!,
        'full-description': value,
      };
      dispatch(postSeedlingThunk(dataPostSeedling));
    } else {
      setError('*Необходимо выбрать изображение');
    }
  };

  const categoriesFilter: Array<TArrSelect> = [{
    id: '0dfg',
    title: 'Все категории',
  }];
  categories?.forEach(item => {
    (item.available === true) && categoriesFilter.push({
      id: item.id,
      title: item.title,
    })
  })

  const [filterValue, setFilterValue] = useState(categoriesFilter[0].title);

  const onSelectFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target
    setFilterValue(target.value);
  }

  const onClickToggle = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (visible) {
      setNameButton('Скрыть форму');
      setSeedlingId('');
      setValue('');
      setError('');
    } else setNameButton('Создать');
  }, [visible]);

  useEffect(() => {
    if (seedlingId) setVisible(false)
  }, [seedlingId]);

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const onClickClose = () => {
    setSearchValue('');
    setSearchName('');
  };
  const onClickSearch = () => {
    setSearchName(searchValue);
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'align'
  ];
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}], 
      [{ 'align': [] }]
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  const form = (
    <FormAdmin
      form='seedling'
      onSubmit={onClickSubmit}
      className={styles.form}
      handleChange={handleChange}
      values={values}
      handleChangeImage={(file: string) => {setFile(file)}}
      error={error}
      legend='Создание саженца'
      classFieldset={styles.fieldset}
    >
      <Input
        label='Краткое описание'
        name='short-description'
        onChange={handleChange}
        required={true}
        maxLength={70}
        value={values['short-description']}
        extraClass={styles.label}
      />
      <div className={styles.label}>
        <Text text='Подробное описание' />
        <ReactQuill
          theme='snow'
          value={value}
          onChange={setValue}
          formats={formats}
          modules={modules}
        />
      </div>
      <Select
        name='category'
        value={values.category}
        onChange={handleChange}
        values={categories}
        label='Выбрать категорию'
      />
      <Input 
        label='Розничная цена'
        name='retail-price'
        onChange={handleChange}
        type='number'
        required
        extraClass={styles.label}
        min={0}
      />
      <Input 
        label='Оптовая цена'
        name='wholesale-price'
        onChange={handleChange}
        type='number'
        required
        extraClass={styles.label}
        min={0}
      />
      <Input 
        label='Наличие'
        name='stock'
        onChange={handleChange}
        type='number'
        required
        extraClass={styles.label}
        min={0}
        max={999998}
      />
    </FormAdmin>
  );

  const filter = (
    <div className={styles.filter}>
      <SearchBar
        inputValue={searchValue}
        onChangeInput={onChangeInputValue}
        onClickClose={onClickClose} 
        onClickSearch={onClickSearch}
        classButton={styles.search}
        visible={true}
      />
      <Select
        name='category'
        onChange={onSelectFilter}
        value={filterValue}
        values={categoriesFilter}
        label='Выбрать категорию'
        className={styles.select}
      />
    </div>
  );

  return (
    <section className={stylesButton.content}>
      <Button
        className={stylesButton.button_admin}
        clickHandler={onClickToggle}
      >
        <Text text={nameButton} />
      </Button>
      {visible && seedlingId === '' && form}
      {!visible && filter}
      <Elements
        category={categoryId(filterValue)}
        search={searchName}
        seedlingId={seedlingId}
        setSeedlingId={setSeedlingId}
      />
    </section>
  )
}