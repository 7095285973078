import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPISeedlingFull } from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  patchSeedlingFailed,
  patchSeedlingRequest,
  patchSeedlingSuccess,
} from '../../store/api-admin-slice';
import { fetchPatchSeedling } from '../../services/api/api-products';

const updateSeedlingThunk: AppThunk = (
  data: TAPISeedlingFull,
  seedlingId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchSeedlingRequest());
    await fetchPatchSeedling(data, seedlingId, token);
    dispatch(patchSeedlingSuccess());
  } catch (error) {
    dispatch(patchSeedlingFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateSeedlingThunk;