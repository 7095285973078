import { FC } from 'react';
import styles from '../form-delivery-date/form-delivery-date.module.css';
import Text from '../../ui/text';
import { useDispatch, useSelector } from '../../services/hooks';
import FormDelivery from '../form-delivery/form-delivery';
import { TDeliveryPoint } from '../../services/types/data';
import Input from '../../ui/inputs/input';
import { filterEmptyStrings } from '../../utils/functions';
import Checkbox from '../../ui/inputs/checkbox';
import cn from 'classnames';
import Textarea from '../../ui/inputs/textarea';
import postDeliveryPointThunk from '../../thunks/delivery-points/post-delivery-point';
import updateDeliveryPointThunk from '../../thunks/delivery-points/update-delivery-point';
import Select from '../../ui/inputs/select';

interface IFormDeliveryPointProps {
  handleCancel: () => void;
  value: TDeliveryPoint;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  error: string;
}

const FormDeliveryPoint: FC<IFormDeliveryPointProps> = ({
  handleCancel,
  value,
  handleChange,
  error,
}) => {
  const dispatch = useDispatch();
  const { deliveryTypes } = useSelector(state => state.main);
  const types = deliveryTypes?.filter(type => type.show)

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.textContent?.includes('Создать')) {
      const dataPostDeliveryPoint: TDeliveryPoint = {
        ...value,
        'delivery-type-id': deliveryTypes?.find(type => type.type === value['delivery-type-id'])?.id || '',
      }
      const filteredData = filterEmptyStrings(dataPostDeliveryPoint);
      dispatch(postDeliveryPointThunk(filteredData));
    } else {
      const dataUpdateDate: TDeliveryPoint = {
        ...value,
        'delivery-type-id': deliveryTypes?.find(type => type.type === value['delivery-type-id'])?.id || '',
      };
      dispatch(updateDeliveryPointThunk(dataUpdateDate, value.id));
    }
  };

  return (
    <FormDelivery
      name='delivery-point'
      legend='Точка доставки'
      onClickSubmit={onClickSubmit}
      handleCancel={handleCancel}
      id={value.id}
    >
      <Input
        name='point'
        placeholder='Точка доставки'
        label='Название'
        onChange={handleChange}
        required={true}
        maxLength={75}
        value={value.point}
        extraClass={styles.label}
      />
      <Textarea
        name='location'
        placeholder='Местонахождение точки'
        label='Местонахождение'
        handleChange={handleChange}
        value={value.location}
        maxLength={200}
        required={true}
      />
      <Textarea
        name='comment'
        placeholder='Комментарий'
        label='Комментарий'
        maxLength={350}
        handleChange={handleChange}
        value={value.comment || ''}
      />
      <Select
        name='delivery-type-id'
        valuesDeliveryTypes={types}
        onChange={handleChange}
        value={value['delivery-type-id']}
        label='Тип доставки'
        className={styles.select}
      />
      <Checkbox
        name='show'
        label='Видимость'
        checked={value.show}
        onChange={handleChange}
      />
      {error && <Text
        text={error}
        className={cn(styles.text, styles.error)} 
      />}
    </FormDelivery>
  )
};

export default FormDeliveryPoint;
