import { AxiosRequestConfig } from 'axios';
import { TAPIDataDeliveryPoint } from '../types/api';
import { DELIVERY_POINTS_URL } from '../../utils/constants';
import { blogAPI, injectBearerToken } from '../helpers/api';
import { 
  IFetchDeleteDeliveryPoints,
  IFetchDeliveryPoints,
  IFetchPatchDeliveryPoints,
  IFetchPostDeliveryPoints,
} from './api.type';

export const fetchDeliveryPoints: IFetchDeliveryPoints = (
  visible?: boolean,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_POINTS_URL,
    params: { visible },
    method: 'get',
  }
  return blogAPI(requestConfig);
};

export const fetchPostDeliveryPoints: IFetchPostDeliveryPoints = (
  data: TAPIDataDeliveryPoint,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_POINTS_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchDeliveryPoints: IFetchPatchDeliveryPoints = (
  data: TAPIDataDeliveryPoint,
  pointId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_POINTS_URL}/${pointId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteDeliveryPoints: IFetchDeleteDeliveryPoints = (
  pointId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_POINTS_URL}/${pointId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
