import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../ui/buttons/button';
import Form from '../../ui/form/form';
import InputPassword from '../../ui/inputs/input-password';
import Input from '../../ui/inputs/input';
import Text from '../../ui/text';
import Title from '../../ui/title/title';
import { useFormWithValidation } from '../../utils/validation';
import styles from '../login/login.module.css';
import cn from 'classnames';
import { useDispatch, useSelector } from '../../services/hooks';
import signupThunk from '../../thunks/auth/signup';
import { messages } from '../../utils/messages';
import Modal from '../../components/modal/modal';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

export function SignupPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signupSuccess, errorObject } = useSelector(state => state.api);
  const { values, handleChange, isValid } = useFormWithValidation();
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errorObject?.statusCode === 409) setErrorText(messages.userEmailError);
  }, [errorObject])

  const signupSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorText('');
    dispatch(signupThunk(values));
  };

  useEffect(() => {
    if (signupSuccess) {
      setVisible(true);
    };
  }, [signupSuccess]);

  useEffect(() => {
    setVisible(false);
  }, []);

  const handleCloseModal = () => {
    setVisible(false);
    navigate('/');
  };

  const modal = (
    <Modal header='' onClose={handleCloseModal}>
      <Text 
        text={messages.userEmailSuccess} 
        className={cn(styles.text, styles.modal)} 
      />
    </Modal>
  )

  return (
    <HelmetProvider>
      <section className={styles.container}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <div className={styles.content}>
          <Title type='h3' text='Регистрация' />
          <Form 
            name='signup' 
            onSubmit={signupSubmit}
          >
            <Input
              type='email'
              placeholder='E-mail'
              onChange={handleChange}
              name='email'
              required
              extraClass={styles.input}
            />
            <Input
              type='text'
              placeholder='Имя пользователя'
              onChange={handleChange}
              name='first-name'
              maxLength={50}
              required
              extraClass={styles.input}
            />
            <Input
              type='text'
              placeholder='Фамилия пользователя'
              onChange={handleChange}
              name='last-name'
              maxLength={50}
              required
              extraClass={styles.input}
            />
            <InputPassword 
              handleChange={handleChange}
              min={6}
              max={30}
              className={styles.input}
            />
            <Button
              type='submit'
              disabled={!isValid}
              className={cn(styles.button, styles.save)}
            >
              Зарегистрироваться
            </Button>
          </Form>
          {errorText && <Text 
            text={errorText} 
            className={cn(styles.text, styles.error)} 
          />}
          <div className={styles.item}>
            <Text 
              text='Уже зарегистрированы?' 
              className={styles.text} 
            />
            <Link to='/login'>
              <Text
                text='Войти'
                className={styles.link}
              />
            </Link>
          </div>
        </div>
        {visible && modal}
      </section>
    </HelmetProvider>
  )
}