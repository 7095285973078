import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { fetchDeliveryEvents } from '../../services/api/api-delivery-events';
import { 
  getDeliveryEventsFailed,
  getDeliveryEventsRequest,
  getDeliveryEventsSuccess,
  setLastPage,
} from '../../store/api-admin-slice';
import { setSkip } from '../../store/api-admin-slice';
import { LIMIT_DELIVERY_EVENTS } from '../../utils/constants';
import { getDeliveryEvents } from '../../store/admin-slice';

const getDeliveryEventsThunk: AppThunk = (
  token: string,
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const events = getState().admin.deliveryEvents;
  try {
    dispatch(getDeliveryEventsRequest());
    dispatch(setSkip(params.skip ? params.skip + LIMIT_DELIVERY_EVENTS : LIMIT_DELIVERY_EVENTS));
    const data = (await fetchDeliveryEvents(token, params)).data;
    const newEvents = params.skip === 0 ? [...data] : [...events, ...data];
    dispatch(getDeliveryEvents(newEvents));
    dispatch(setLastPage(data.length < LIMIT_DELIVERY_EVENTS));
    dispatch(getDeliveryEventsSuccess());
  } catch (error) {
    dispatch(getDeliveryEventsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getDeliveryEventsThunk;