import { 
  FC, 
  useState, 
  useEffect, 
  useRef 
} from 'react';
import styles from './file-input.module.css';
import cn from 'classnames';
import Input from '../../ui/inputs/input';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import stylesButton from '../../components/questions/questions.module.css';

interface IFileInputProps {
  onChange: (file: string) => void;
  file?: string;
  className?: string;
  classImage?: string;
  image?: string;
}

const FileInput: FC<IFileInputProps> = ({ 
  onChange, 
  file = null, 
  className,
  image,
}) => {
  const [ currentFile, setCurrentFile ] = useState<string | null>(file);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  const onClickChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      getBase64(file);
    }
  };

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result as string
      setCurrentFile(result)
      onChange(result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
  };

  const handleClick = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <Input
        type='file'
        extraClass={styles.input}
        ref={fileInput}
        name='file'
        onChange={onClickChoose}
      />
      <Button
        clickHandler={handleClick}
        className={(image || currentFile) ? styles.button : stylesButton.button_admin}
      >
        {(image || currentFile) ? (
          <img src={currentFile ? currentFile : image} alt='Фото категории' className={styles.image} />
        ) : (
          <Text text='Выбрать изображение' />
        )}
      </Button>
    </div>
  );
};

export default FileInput;
