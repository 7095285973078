import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  loginSuccess,
  loginRequest,
  loginFailed,
  getUsersMeRequest,
  getUsersMeSuccess,
} from '../../store/api-slice';
import { 
  TAPILoginObject, 
  TAPIError 
} from '../../services/types/api';
import { client, setCookie } from '../../services/helpers/api';
import { 
  setUserData, 
  resetToken, 
  setToken 
} from '../../store/user-slice';
import { TIMER_ACCESS_TOKEN, TOKEN } from '../../utils/constants';
import { TLoginValues } from '../../services/types/data';
import setMyCartThunk from '../carts/set-my-cart';
import { login } from '../../services/api/api-auth';
import { fetchUsersMeData } from '../../services/api/api-users';

const loginThunk: AppThunk = (
  loginData: TLoginValues,
) => async (dispatch) => {
  try {
    const clientId = client.get(loginData.email.toLowerCase());
    const loginObject: TAPILoginObject = clientId ? {
      'client-id': clientId,
      email: loginData.email,
      password: loginData.password,
    } : loginData;
    dispatch(loginRequest());
    const responseLogin = await login(loginObject);
    if (!clientId) {
      client.set(loginData.email.toLowerCase(), responseLogin.data['client-id']);
    }
    dispatch(loginSuccess());
    const token = responseLogin.data['access-token']
    dispatch(setToken(token));
    const refreshToken = responseLogin.data['refresh-token'];
    setCookie(TOKEN, refreshToken);
    setTimeout(() => dispatch(resetToken()), TIMER_ACCESS_TOKEN);
    dispatch(getUsersMeRequest());
    const responseUser = await fetchUsersMeData(token);
    dispatch(setUserData(responseUser.data));
    dispatch(getUsersMeSuccess());

    dispatch(setMyCartThunk(token));

  } catch (error) {
    dispatch(loginFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default loginThunk;
