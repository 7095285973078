import axios, { 
  AxiosRequestConfig, 
  AxiosInstance,
} from 'axios';
import { TAPIParamsObject } from '../types/api';
import { 
  BASE_URL, 
  CART, 
  CLIENT, 
  TIMER_REFRESH_TOKEN
} from '../../utils/constants';
import { TDataCartElement } from '../types/data';

const defaultRequestConfig : AxiosRequestConfig = {
  baseURL: BASE_URL,
  headers: {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  },
};

export const makeParams = (
  id?: number,
  skip?: number,
  limit?: number,
  category?: string,
  search?: string,
  unanswered?: boolean,
  all?: boolean,
  visible?: boolean,
  user?: string,
  created?: string,
  read?: boolean,
  editable?: boolean,
  deliveryDate?: string,
) : TAPIParamsObject => {
  let res : TAPIParamsObject = {};
  if (id) {
    res = { ...res, id };
  }
  if (skip) {
    res = { ...res, skip };
  }
  if (limit) {
    res = { ...res, limit };
  }
  if (category) {
    res = { ...res, category };
  }
  if (search) {
    res = { ...res, search };
  }
  if (unanswered) {
    res = { ...res, unanswered };
  }
  if (all) {
    res = { ...res, all };
  }
  if (visible) {
    res = { ...res, visible };
  }
  if (user) {
    res = { ...res, user };
  }
  if (created) {
    res = { ...res, created };
  }
  if (!read) {
    res = { ...res, read };
  }
  if (editable) {
    res = { ...res, editable };
  }
  if (deliveryDate) {
    res = { ...res, 'delivery-date': deliveryDate };
  }
  return res;
};

export const client = {
  set: (name: string, value: string): void => {
    if (name && value) {
      localStorage.setItem(`${CLIENT}_${name}`, `${value}`);
    } else {
      localStorage.removeItem(`${CLIENT}_${name}`);
    }
  },
  get: (name: string): string => {
    const res = localStorage.getItem(`${CLIENT}_${name}`);
    return res || '';
  },
  remove: (name: string): void => localStorage.removeItem(`${CLIENT}_${name}`),
};

export const cart = {
  set: (value: Array<TDataCartElement<string>>): void => {
    if (value) {
      localStorage.setItem(CART, JSON.stringify(value));
    } else {
      localStorage.removeItem(CART);
    }
  },
  get: (): Array<TDataCartElement<string>> => {
    const item = localStorage.getItem(CART);
    return item ? JSON.parse(item) : [];
  },
  remove: (): void => localStorage.removeItem(CART),
};

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/[.$?*|{}()[\]\\/+^]/g, '\\$&') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export function setCookie(
  name: string, 
  value: string, 
  options: {
    [optionName: string]: any
  } = {}
) {
  options = {
    path: '/',
    'max-age': TIMER_REFRESH_TOKEN,
    samesite: 'lax',
    ...options
  };
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
};

export function deleteCookie(name: string) {
  setCookie(name, '', {
    'max-age': -1
  });
};

export const injectBearerToken = (
  requestConfig : AxiosRequestConfig,
  token: string,
) : AxiosRequestConfig => {
  if (token) {
    return { 
      ...requestConfig, 
      headers: { 
        ...defaultRequestConfig.headers, 
        authorization: `Bearer ${token}`, 
      }
    };
  }
  return requestConfig;
};

export const blogAPI: AxiosInstance = axios.create(defaultRequestConfig);
