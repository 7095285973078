import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  readNotificationsFailed, 
  readNotificationsSuccess, 
  readNotificationsRequest,
} from '../../store/api-slice';
import { fetchReadNotifications } from '../../services/api/api-notifications';

const readNotificationThunk: AppThunk = (
  token: string,
  notificationId: string,
) => async (dispatch) => {
  try {
    dispatch(readNotificationsRequest());
    await fetchReadNotifications(token, notificationId);
    dispatch(readNotificationsSuccess());
  } catch (error) {
    dispatch(readNotificationsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default readNotificationThunk;