import { 
  useCallback, 
  useEffect, 
  useRef 
} from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route,
} from 'react-router-dom';
import Header from '../header/header';
import ErrorBoundary from '../error-boundary/error-boundary';
import { MainPage } from '../../pages/main/main';
import { NotFound404 } from '../../pages/not-found/not-found';
import { useDispatch, useSelector } from '../../services/hooks';
import getSwiperThunk from '../../thunks/swiper/swiper';
import getCategoriesThunk from '../../thunks/products/categories';
import { SeedlingsPage } from '../../pages/seedlings/seedlings';
import Footer from '../footer/footer';
import { ContactsPage } from '../../pages/contacts/contacts';
import { LoginPage } from '../../pages/login/login';
import { PrivateRoutes } from '../private-routes/private-routes';
import { SignupPage } from '../../pages/signup/signup';
import { ForgotPasswordPage } from '../../pages/forgot-password/forgot-password';
import { cart, deleteCookie, getCookie } from '../../services/helpers/api';
import { TOKEN } from '../../utils/constants';
import usersMeThunk from '../../thunks/users/users-me';
import { ProfilePage } from '../../pages/profile/profile';
import ProfileLayout from '../profile-layout/profile-layout';
import { SetPasswordPage } from '../../pages/set-password/set-password';
import { OrdersPage } from '../../pages/orders/orders';
import { AboutPage } from '../../pages/about/about';
import { CartPage } from '../../pages/cart/cart';
import { setDataCart } from '../../store/cart-slice';
import { NotificationsPage } from '../../pages/notifications/notifications';
import { QuestionsPage } from '../../pages/questions/questions';
import { ProductsPage } from '../../pages/products/products';
import { CategoriesPage } from '../../pages/categories/categories';
import { SwiperPage } from '../../pages/swiper/swiper';
import { UsersPage } from '../../pages/users/users';
import { DeliveryPage } from '../../pages/delivery/delivery';
import getDeliveryTypesThunk from '../../thunks/delivery-types/delivery-types';
import getDeliveryPointsThunk from '../../thunks/delivery-points/delivery-points';
import DeliveryLayout from '../delivery-layout/delivery-layout';
import { DeliveryTypePage } from '../../pages/delivery-type/delivery-type';
import { DeliveryDatePage } from '../../pages/delivery-date/delivery-date';
import { DeliveryPointPage } from '../../pages/delivery-point/delivery-point';
import getDeliveryDatesThunk from '../../thunks/delivery-dates/delivery-dates';
import OrderPage from '../../pages/order/order';
import getOrdersAllowedThunk from '../../thunks/orders/orders-allowed';
import updateTokenThunk from '../../thunks/auth/update-token';
import { ActivationPage } from '../../pages/activation/activation';
import { connectionFinish, connectionStart } from '../../store/ws-slice';

function App() {
  const dispatch = useDispatch();
  const refreshToken = getCookie(TOKEN);
  const dataCartLocal = cart.get();
  const { errorObject, usersMeSuccess } = useSelector(state => state.api);
  const { 
    сategorySuccess, 
    swiperSuccess, 
    deliveryTypesSuccess,
    deliveryDatesSuccess,
    deliveryPointsSuccess,
    deliveryEventsSuccess,
  } = useSelector(state => state.apiAdmin);
  const { token, email } = useSelector(state => state.user);
  const didMountRef = useRef(false);

  const initUser = useCallback(() => {
    if (refreshToken) {
      dispatch(usersMeThunk(refreshToken));
    } else {
      dispatch(setDataCart(dataCartLocal));
    }
  }, [refreshToken, dispatch, dataCartLocal]);

  useEffect(() => {
    if (usersMeSuccess) {
      dispatch(connectionStart());
    }

    return () => {
      dispatch(connectionFinish());
    }
  }, [usersMeSuccess, dispatch, email]);

  useEffect(() => {
    if (!didMountRef.current) {
      dispatch(getSwiperThunk());
      dispatch(getCategoriesThunk());
      dispatch(getDeliveryTypesThunk());
      dispatch(getDeliveryDatesThunk());
      dispatch(getDeliveryPointsThunk());
      dispatch(getOrdersAllowedThunk());
      initUser()

      didMountRef.current = true;
    }
  }, [dispatch, initUser]);

  useEffect(() => {
    if (email && !token) dispatch(updateTokenThunk())
  }, [token, email, dispatch]);

  useEffect(() => {
    if (errorObject?.statusCode === 400) cart.remove();
    // if (errorObject?.statusCode === 403) deleteCookie(TOKEN);
    if (errorObject?.statusCode === 406) deleteCookie(TOKEN);
  }, [errorObject])

  useEffect(() => {
    if (сategorySuccess) {
      dispatch(getCategoriesThunk());
    }
    if (swiperSuccess) {
      dispatch(getSwiperThunk());
    }
    if (deliveryTypesSuccess) {
      dispatch(getDeliveryTypesThunk());
    }
    if (deliveryDatesSuccess || deliveryEventsSuccess) {
      dispatch(getDeliveryDatesThunk());
    }
    if (deliveryPointsSuccess || deliveryEventsSuccess) {
      dispatch(getDeliveryPointsThunk());
    }
  }, [
    сategorySuccess,
    swiperSuccess,
    deliveryTypesSuccess,
    deliveryDatesSuccess,
    deliveryPointsSuccess,
    deliveryEventsSuccess,
    dispatch,
  ]);

  return (
    <ErrorBoundary>
      <div>
        <Router>
          <Header />
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='seedlings' element={<SeedlingsPage />} />
            <Route path='seedlings/category/:id' element={<SeedlingsPage />} />
            <Route path='seedlings/:id' element={<SeedlingsPage />} />
            <Route path='seedlings/search/:name' element={<SeedlingsPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='signup' element={<SignupPage />} />
            <Route path='auth/activation' element={<ActivationPage />} />
            <Route path='forgot-password' element={<ForgotPasswordPage />} />
            <Route path='profile' element={<PrivateRoutes />}>
              <Route path='' element={<ProfileLayout />}>
                <Route index element={<ProfilePage />} />
                <Route path='orders' element={<OrdersPage />} />
                <Route path='orders/:id' element={<OrderPage />} />
                <Route path='set-password' element={<SetPasswordPage />} />
                <Route path='notifications' element={<NotificationsPage />} />
              </Route>
            </Route>
            <Route path='admin-panel' element={<PrivateRoutes />}>
              <Route path='' element={<ProfileLayout />}>
                <Route path='orders' element={<OrdersPage />} />
                <Route path='orders/:id' element={<OrderPage />} />
                <Route path='notifications' element={<NotificationsPage />} />
                <Route path='delivery' element={<DeliveryLayout />}>
                  <Route index element={<DeliveryPage />} />
                  <Route path='type' element={<DeliveryTypePage />} />
                  <Route path='date' element={<DeliveryDatePage />} />
                  <Route path='point' element={<DeliveryPointPage />} />
                </Route>
                <Route path='questions' element={<QuestionsPage />} />
                <Route path='categories' element={<CategoriesPage />} />
                <Route path='seedlings' element={<ProductsPage />} />
                <Route path='swiper' element={<SwiperPage />} />
                <Route path='users' element={<UsersPage />} />
                <Route path='profile' element={<ProfilePage />} />
                <Route path='set-password' element={<SetPasswordPage />} />
              </Route>
            </Route>
            <Route path='about' element={<AboutPage />} />
            <Route path='contacts' element={<ContactsPage />} />
            <Route path='cart' element={<CartPage />} />
            <Route path='*' element={<NotFound404 />}/>
          </Routes>
          <Footer />
        </Router>
      </div>
    </ErrorBoundary>
  );
}

export default App;