import { FC } from 'react';
import Form from '../../ui/form/form';
import Input from '../../ui/inputs/input';
import FileInput from '../file-input/file-input';
import Checkbox from '../../ui/inputs/checkbox';
import Text from '../../ui/text';
import styles from './form-admin.module.css';
import Button from '../../ui/buttons/button';
import stylesButton from '../../components/questions/questions.module.css';
import { 
  TCategory, 
  TFormSeedling, 
  TSlide 
} from '../../services/types/data';

interface IFormAdminProps {
  form: string;
  onSubmit: (e: React.FormEvent) => void;
  className: string;
  classFieldset?: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  values: TSlide | TCategory | TFormSeedling;
  handleChangeImage: (file: string) => void;
  error: string;
  handleCancel?: () => void;
  id?: string;
  children?: React.ReactNode;
  legend?: string;
}

const FormAdmin: FC<IFormAdminProps> = ({
  form,
  onSubmit,
  className,
  handleChange,
  values,
  handleChangeImage,
  error,
  handleCancel,
  id,
  children,
  legend,
  classFieldset,
}) => (
  <Form
    name={form}
    onSubmit={onSubmit}
    className={className}
    legend={legend}
    classFieldset={classFieldset}
  >
    <Input
      name='title'
      onChange={handleChange}
      required={true}
      value={values.title}
      minLength={3}
      maxLength={70}
      label='Название'
      extraClass={styles.label}
    />
    <FileInput
      onChange={handleChangeImage}
      image={values.image}
    />
    {children}
    <Input 
      placeholder='Порядок отображения'
      name='display-order'
      onChange={handleChange}
      type='number'
      value={values['display-order']}
      label='Порядок отображения'
      extraClass={styles.label}
      min={0}
      max={99}
      required
    />
    <Checkbox
      name='available'
      label='Видимость'
      checked={values.available}
      onChange={handleChange}
    />
    {error && <Text text={error} className={styles.error} />}
    <div className={styles.box}>
      <Button
        className={stylesButton.button_admin}
        type='submit'
      >
        <Text text={id ? 'Изменить' : 'Создать'} />
      </Button>
      {id && (
        <Button
          className={stylesButton.button_admin}
          clickHandler={handleCancel}
        >
          <Text text='Отменить' />
        </Button>
      )}
    </div>
  </Form>
);

export default FormAdmin;