import { FC, CSSProperties } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import closeIcon from '../../images/close.svg';
import deleteIcon from '../../images/delete.svg';
import { TSize } from '../../services/types/data';

interface ICloseButtonProps {
  extraClass?: string;
  onClose: () => void;
  size: TSize;
  style?: CSSProperties;
  del?: boolean;
  disabled?: boolean;
}

const CloseButton: FC<ICloseButtonProps> = ({ 
  extraClass, 
  onClose, 
  size, 
  style,
  del = false,
  disabled = false,
}) => (
  <button 
    type='button' 
    className={cn(extraClass, styles.outline, disabled && styles.disabled)} 
    onClick={onClose}
    style={style}
    disabled={disabled}
  >
    <img 
      src={del ? deleteIcon : closeIcon} 
      alt={del ? 'Удалить' : 'Закрыть'} 
      className={cn(
        styles.image,
        size === 'large' && styles.image_large,
        size === 'small' && styles.image_small,
      )} 
    />
  </button>
);

export default CloseButton;
