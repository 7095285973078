import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  TDeliveryDate, 
  TDeliveryEvent, 
  TQuestion, 
  TUser 
} from '../services/types/data';

type TAdminState = {
  readonly questions: Array<TQuestion>;
  readonly users: Array<TUser>;
  readonly deliveryEvents: Array<TDeliveryEvent>;
  readonly deliveryDatesAll: Array<TDeliveryDate>;
};

const initialState: TAdminState = {
  questions: [],
  users: [],
  deliveryEvents: [],
  deliveryDatesAll: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    getQuestions: (state, action: PayloadAction<Array<TQuestion>>) => ({
      ...state, questions: action.payload,
    }),
    getUsers: (state, action: PayloadAction<Array<TUser>>) => ({
      ...state, users: action.payload,
    }),
    getDeliveryEvents: (state, action: PayloadAction<Array<TDeliveryEvent>>) => ({
      ...state, deliveryEvents: action.payload,
    }),
    getDeliveryDatesAll: (state, action: PayloadAction<Array<TDeliveryDate>>) => ({
      ...state, deliveryDatesAll: action.payload,
    }),
  }
});

const adminReducer = adminSlice.reducer;

export const { 
  getQuestions,
  getUsers,
  getDeliveryEvents,
  getDeliveryDatesAll,
} = adminSlice.actions;

export default adminReducer;