import { AppThunk } from '../../services/types/store';
import { cart } from '../../services/helpers/api';
import { TDataCartElement, TUpdateCartElement } from '../../services/types/data';
import { updateDataCart } from '../../store/cart-slice';
import { TAPIError } from '../../services/types/api';
import { updateMyCartFailed } from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import updateMyCartThunk from './update-my-cart';

const updateDataCartThunk: AppThunk = (
  element: TDataCartElement<string>,
  isActive: boolean,
) => async (dispatch, getState) => {
  const dataCart = getState().cart.dataCart;
  const token = getState().user.token;
  try {
    const newDataCart = [...dataCart];
    let index: number = -1;
    const el = dataCart.find(el => el.seedling === element.seedling);
    if (el) {
      index = dataCart.indexOf(el);
    };

    newDataCart[index] = element;

    dispatch(updateDataCart(newDataCart));
    if (!isActive) {
      cart.set(newDataCart);
    } else {
      const dataUpdate: Array<TUpdateCartElement> = newDataCart.map(el => ({
        'seedling-id': el.seedling,
        count: el.count,
      }));
      token && dispatch(updateMyCartThunk(dataUpdate, token));
    }
  } catch (error) {
    dispatch(updateMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateDataCartThunk;