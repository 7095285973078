import { 
  useCallback, 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import { LIMIT_NOTIFICATIONS } from '../../utils/constants';
import getNotificationsThunk from '../../thunks/notifications/notifications';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import styles from './notifications.module.css';
import readNotificationThunk from '../../thunks/notifications/read-notification';
import cn from 'classnames';
import Input from '../../ui/inputs/input';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import Form from '../../ui/form/form';
import deleteNotificationsThunk from '../../thunks/notifications/delete-notifications';
import Modal from '../../components/modal/modal';
import { Link } from 'react-router-dom';

export function NotificationsPage() {
  const dispatch = useDispatch();
  const { 
    skip, 
    lastPage,
    notificationsRequest,
    readNotificationsSuccess,
  } = useSelector(state => state.api);
  const { 
    token,
    'is-admin': isAdmin,
    'is-superuser': isSuperuser 
  } = useSelector(state => state.user);
  const { notifications } = useSelector(state => state.main);
  const { deleteNotificationsSuccess } = useSelector(state => state.apiAdmin);
  const [nameButton, setNameButton] = useState({
    new: 'Новые уведомления',
    delete: 'Удалить старые уведомления',
  });
  const [readId, setReadId] = useState<Array<string>>([]);
  const [clickId, setClickId] = useState('');
  const [visible, setVisible] = useState({
    delete: false,
    modal: false,
  });
  const [inputValue, setInputValue] = useState('');
  const date = new Date()
  const oldDate = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());

  useEffect(() => {
    if (deleteNotificationsSuccess) setVisible(prevVisible => ({
      ...prevVisible,
      modal: true,
    }));
  }, [deleteNotificationsSuccess]);

  useEffect(() => {
    setVisible({
      delete: false,
      modal: false,
    });
  }, []);

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const getNotifications = useCallback((currentSkip: number) => {
    dispatch(getNotificationsThunk(token, {
      skip: currentSkip,
      limit: LIMIT_NOTIFICATIONS,
      read: nameButton.new === 'Новые уведомления' ? true : false,
    }));
  }, [dispatch, token, nameButton]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getNotifications(skip)
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, getNotifications, lastPage]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (notificationsRequest) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, notificationsRequest]
  );

  useEffect(() => {
    if (notifications) {
      const listId = notifications.filter(item => item['was-read']).map(item => item.id);
      setReadId(listId);
    }
  }, [notifications]);

  useEffect(() => {
    if (readNotificationsSuccess && clickId) {
      setReadId(prevReadId => {
        if (!prevReadId.includes(clickId)) {
          return [...prevReadId, clickId];
        }
        return prevReadId;
      });
    }
  }, [readNotificationsSuccess, clickId]);

  useEffect(() => {
    token && getNotifications(0)
  }, [token, getNotifications]);

  const clickHandlerRead = (
    id: string,
  ) => {
    setClickId(id);
    dispatch(readNotificationThunk(token, id));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    const value = target.value;
    setInputValue(value);
  };

  const onClickDelete = () => {
    setVisible({
      delete: !visible.delete,
      modal: false,
    });
    setNameButton({
      ...nameButton,
      delete: nameButton.delete === 'Удалить старые уведомления'
        ? 'Отменить удаление' 
        : 'Удалить старые уведомления',
    })
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(deleteNotificationsThunk(token, inputValue));
  };

  const handleCloseModal = () => {
    setVisible({
      delete: false,
      modal: false,
    });
    setInputValue('');
    setNameButton({
      ...nameButton,
      delete: 'Удалить старые уведомления',
    });
  };

  const onClickFilter = () => {
    setNameButton({
      ...nameButton,
      new: nameButton.new === 'Все уведомления' ? 'Новые уведомления' : 'Все уведомления',
    })
  };

  const modal = (
    <Modal header='Удаление старых уведомлений' onClose={handleCloseModal}>
      <Text text={`Уведомления с датой ранее, чем ${inputValue}, удалены`} />
    </Modal>
  );

  return (
    <section className={styles.content}>
      <Button
        clickHandler={onClickFilter}
        className={styles.button}
      >
        <Text text={nameButton.new} />
      </Button>
      {(isAdmin || isSuperuser) && <Button
        className={styles.button}
        clickHandler={onClickDelete}
      >
        <Text text={nameButton.delete} />
      </Button>}
      {visible.delete && (
        <Form
          name='notifications'
          onSubmit={onClickSubmit}
          className={styles.form}
          classFieldset={styles.box}
        >
          <Input
            name='date'
            type='date'
            label='Дата'
            onChange={handleChange}
            required={true}
            value={inputValue}
            max={oldDate.toISOString().split('T')[0]}
          />
          <Button
            className={styles.button}
            type='submit'
          >
            <Text text='Удалить' />
          </Button>
        </Form>
      )}
      <List className={styles.list}>
        {notifications?.map(item => {
          const index = item.message.indexOf('Ссылка на страницу саженца: http')
          const answer = index > 1 ? item.message.slice(0, index) : item.message
          const link = index > 1 && item.message.slice(index).split(' ').slice(-1)[0]
          return(
            <ListItem 
              key={item.id} 
              className={cn(
                styles.item,
                readId.some(el => el === item.id) && styles.read
              )}
              onClick={() => clickHandlerRead(item.id)}
            >
              <Title
                type='h4'
                text={item.type.title}
                className={cn(
                  styles.name,
                  readId.some(el => el === item.id) && styles.read
                )}
              />
              <Text
                text={item.created} 
                className={styles.date}
              />
              <Text className={styles.text}>
                {answer}
                {link && (
                  <Link
                    to={link}
                    className={styles.link}
                    onClick={() => clickHandlerRead(item.id)}
                  >
                    Посмотреть
                  </Link>
                )}
              </Text>
            </ListItem>
          )
        })}
        <li ref={loadMoreCallback}></li>
      </List>
      {visible.modal && modal}
    </section>
  )
}
