import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteCategoryFailed,
  deleteCategoryRequest,
  deleteCategorySuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteCategory } from '../../services/api/api-products';

const deleteCategoryThunk: AppThunk = (
  categoryId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteCategoryRequest());
    await fetchDeleteCategory(categoryId, token);
    dispatch(deleteCategorySuccess());
  } catch (error) {
    dispatch(deleteCategoryFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteCategoryThunk;