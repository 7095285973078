import { 
  useState, 
  useEffect 
} from 'react';
import Form from '../../ui/form/form';
import Input from '../../ui/inputs/input';
import { useDispatch, useSelector } from '../../services/hooks';
import Modal from '../../components/modal/modal';
import Text from '../../ui/text';
import styles from './profile.module.css';
import cn from 'classnames';
import BoxButtons from '../../components/box-buttons/box-buttons';
import updateUserThunk from '../../thunks/users/update-user';

export function ProfilePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { 
    id,
    discount,
    'first-name': firstName,
    'last-name': lastName,
    email,
    'phone-number': phoneNumber,
    'is-wholesaler': isWholesaler,
  } = user;

  const [visible, setVisible] = useState(false);
  const { updateUserRequest, updateUserSuccess } = useSelector(state => state.api);

  const formDataInit = {
    'first-name': firstName || '',
    'last-name': lastName || '',
    'phone-number': phoneNumber || '',
  }

  const [formData, setFormData] = useState(formDataInit);
  const [isValid, setIsValid] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'phone-number' ? 
        value.replace(/\D/g, '').replace(/^7|8/, '+7').slice(0, 12) :
        value
    }));
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^(\+7|8|7)[\d]{10}$/
    return phoneRegex.test(phone);
  };

  useEffect(() => {
    const validateForm = () => {
      const { 
        'first-name': firstName,
        'last-name': lastName,
        'phone-number': phoneNumber,
      } = formData;
      if (firstName && lastName && (!phoneNumber || validatePhone(phoneNumber))) {
        setIsValid(true);
        setPhoneError('');
      } else {
        setIsValid(false);
        if (!validatePhone(phoneNumber)) {
          setPhoneError('Поле "Телефон" должно быть заполнено корректно');
        }
      }
    };
    validateForm();
  }, [formData]);

  useEffect(() => {
    if (updateUserSuccess) setVisible(true);
  }, [updateUserSuccess]);

  useEffect(() => {
    setVisible(false);
  }, []);

  const updateUserSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dataUpdateUser = {
      'first-name': formData['first-name'],
      'last-name': formData['last-name'],
      'phone-number': formData['phone-number'],
    };
    dispatch(updateUserThunk(dataUpdateUser, id));
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const onCliclCancel = () => {
    setFormData(formDataInit)
  };

  const modal = (
    <Modal header='Редактирование профиля' onClose={handleCloseModal}>
      <Text text='Изменения сохранены' />
    </Modal>
  );

  return (
    <section className={styles.container}>
      <Form
        name='profile'
        onSubmit={updateUserSubmit}
        classFieldset={styles.form}
      >
        <Input
          onChange={handleChange}
          placeholder='Имя'
          value={formData['first-name']}
          name='first-name'
          maxLength={50}
          extraClass={cn(styles.input, styles.edit, styles.label)}
          label='Имя'
        />
        <Input
          onChange={handleChange}
          placeholder='Фамилия'
          value={formData['last-name'] || ''}
          name='last-name'
          maxLength={50}
          extraClass={cn(styles.input, styles.edit, styles.label)}
          label='Фамилия'
        />
        <Input
          name='email'
          placeholder='E-mail'
          value={email || ''}
          extraClass={cn(styles.input, styles.label)}
          readOnly={true}
          label='E-mail'
        />
        <Input
          placeholder='Телефон'
          onChange={handleChange}
          value={formData['phone-number'] || ''}
          name='phone-number'
          extraClass={cn(styles.input, styles.edit, styles.label)}
          label='Телефон'
        />
        {isWholesaler && <Text 
          text='Установлен оптовый уровень цен'
          className={styles.option}
        />}
        {discount > 0 && <Text 
          text={`Индивидуальная скидка - ${discount} %`} 
          className={styles.option}
        />}
        {phoneError && <Text text={phoneError} className={styles.error} />}
        <BoxButtons
          loading={updateUserRequest}
          disabled={!isValid}
          handleClickCancel={onCliclCancel}
        />
      </Form>
      {visible && modal}
    </section>
  )
}