import { FC } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import searchIcon from '../../images/search.svg';

interface ISearchButtonProps {
  extraClass?: string;
  onClickHandler: () => void;
}

const SearchButton: FC<ISearchButtonProps> = ({ extraClass, onClickHandler }) => (
  <button 
    type='button' 
    className={cn(styles.outline, extraClass)} 
    onClick={onClickHandler}
  >
    <img 
      src={searchIcon} 
      alt='Лупа' 
      className={cn(styles.image, styles.image_small)} 
    />
  </button>
);

export default SearchButton;