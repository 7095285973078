import { FC } from 'react';
import styles from './box-buttons.module.css';
import Button from '../../ui/buttons/button';
import cn from 'classnames';
import { Loader } from '../../ui/loader/loader';

interface IBoxButtonsProps {
  handleClickCancel: () => void;
  loading: boolean;
  disabled: boolean;
}

const BoxButtons: FC<IBoxButtonsProps> = ({
  handleClickCancel,
  loading,
  disabled,
}) => {
  return (
    <div className={styles.box}>
      <Button
        type='submit'
        className={cn(styles.button, styles.save)}
        disabled={disabled}
      >
        {loading ? <Loader size='small' /> : 'Сохранить'}
      </Button>
      <Button 
        type='button' 
        className={cn(styles.button, styles.cancel)}
        disabled={disabled}
        clickHandler={handleClickCancel}
      >
        Отмена
      </Button>
    </div>
  )
};

export default BoxButtons;