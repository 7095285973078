import { useState } from 'react';
import styles from './styles.module.css';

function ScrollButton() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    } else setVisible(false)
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button 
      type='button'
      onClick={scrollToTop}
      className={styles.scroll}
      style={{ 
        display: visible ? 'inline' : 'none',
      }}
    >
    </button>
  )
};

export default ScrollButton;