import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteDeliveryDatesFailed,
  deleteDeliveryDatesRequest,
  deleteDeliveryDatesSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteDeliveryDates } from '../../services/api/api-delivery-dates';

const deleteDeliveryDateThunk: AppThunk = (
  dateId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteDeliveryDatesRequest());
    await fetchDeleteDeliveryDates(dateId, token);
    dispatch(deleteDeliveryDatesSuccess());
  } catch (error) {
    dispatch(deleteDeliveryDatesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteDeliveryDateThunk;