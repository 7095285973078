import React, { FC } from 'react';
import styles from './input.module.css';
import cn from 'classnames';
import { 
  TArrSelect, 
  TCategory, 
  TDeliveryDate, 
  TDeliveryPoint, 
  TDeliveryType 
} from '../../services/types/data';

interface ISelectProps {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  values?: ReadonlyArray<TCategory | TArrSelect> | null;
  valuesDeliveryTypes?: ReadonlyArray<TDeliveryType> | null;
  label: string;
  valuesDeliveryDates?: ReadonlyArray<TDeliveryDate> | null;
  valuesDeliveryPoints?: ReadonlyArray<TDeliveryPoint> | null;
  disabled?: boolean;
  className?: string;
}

const Select: FC<ISelectProps> = ({
  name,
  onChange,
  value,
  values,
  valuesDeliveryTypes,
  label,
  valuesDeliveryDates,
  valuesDeliveryPoints,
  disabled,
  className,
}) => (
  <label className={cn(styles.label, styles.text, className)}>
    {label}
    <select
      name={name}
      value={value}
      onChange={onChange}
      required
      className={cn(styles.text, styles.select)}
      disabled={disabled}
    >
      {values?.map(item => (
        <option 
          value={item.title} 
          key={item.id}
        >
          {item.title}
        </option>
      ))}
      {valuesDeliveryTypes?.map(item => (
        <option 
          value={item.type} 
          key={item.id}
        >
          {item.type}
        </option>
      ))}
      {valuesDeliveryDates?.map(item => (
        <option 
          value={item.date} 
          key={item.id}
        >
          {item.date}
        </option>
      ))}
      {valuesDeliveryPoints?.map(item => (
        <option 
          value={item.point} 
          key={item.id}
        >
          {item.point}
        </option>
      ))}

    </select>
  </label>
);

export default Select;