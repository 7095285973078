import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TDataCartElement, TSeedlingBase } from '../services/types/data'
import { TAPICart } from '../services/types/api';

type TCartState = {
  readonly dataCart: Array<TDataCartElement<string>>;
  readonly amount: number;
  readonly cartItems: Array<TDataCartElement<TSeedlingBase>>;
};

const initialState: TCartState = {
  dataCart: [],
  amount: 0,
  cartItems: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setDataCart: (state, action: PayloadAction<Array<TDataCartElement<string>>>) => ({
      ...state, dataCart: action.payload,
    }),
    setCartItems: (state, action:PayloadAction<TAPICart>) => ({
      ...state,
      amount: action.payload.amount,
      cartItems: action.payload.items,
    }),
    clearDataCart: () => ({
      dataCart: [],
      cartItems: [],
      amount: 0,
    }),
    updateDataCart: (state, action: PayloadAction<Array<TDataCartElement<string>>>) => ({
      ...state, dataCart: action.payload,
    }),
    updateCartItems: (state, action:PayloadAction<TAPICart>) => ({
      ...state,
      amount: action.payload.amount,
      cartItems: action.payload.items,
    }),
  }
});

const orderReducer = cartSlice.reducer;

export const {
  setDataCart,
  clearDataCart,
  updateDataCart,
  setCartItems,
  updateCartItems,
} = cartSlice.actions;

export default orderReducer;