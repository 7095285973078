import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getUsersMeRequest,
  getUsersMeSuccess,
  updateTokenRequest,
  updateTokenSuccess,
  getUsersMeFailed,
} from '../../store/api-slice';
import { TAPIError, TAPIObjectError } from '../../services/types/api';
import { deleteCookie, setCookie } from '../../services/helpers/api';
import { 
  setUserData, 
  resetToken, 
  updateToken 
} from '../../store/user-slice';
import setMyCartThunk from '../carts/set-my-cart';
import { fetchUpdateToken } from '../../services/api/api-auth';
import { fetchUsersMeData } from '../../services/api/api-users';
import { TIMER_ACCESS_TOKEN, TOKEN } from '../../utils/constants';

const usersMeThunk: AppThunk = (
  token: string,
) => async (dispatch, getState) => {
  try {
    dispatch(updateTokenRequest());
    const responseToken = await fetchUpdateToken(token);
    dispatch(updateTokenSuccess());
    const accessToken = responseToken.data['access-token'];
    dispatch(updateToken(accessToken));
    const refreshToken = responseToken.data['refresh-token'];
    setCookie(TOKEN, refreshToken);
    setTimeout(() => dispatch(resetToken()), TIMER_ACCESS_TOKEN);
    dispatch(getUsersMeRequest());
    const responseUser = await fetchUsersMeData(accessToken);
    dispatch(setUserData(responseUser.data));
    dispatch(getUsersMeSuccess());
    dispatch(setMyCartThunk(accessToken));
  } catch (error) {
    dispatch(getUsersMeFailed(makeErrorObject(error as AxiosError<TAPIError>)));
    const textError = getState().api.errorObject?.detail?.[0] as TAPIObjectError;
    if (textError?.msg === 'Not acceptable refresh-token') {
      deleteCookie(TOKEN);
    }
  }
};

export default usersMeThunk;
