import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { LIMIT_ORDERS } from '../../utils/constants';
import { 
  getOrdersFailed, 
  getOrdersRequest, 
  getOrdersSuccess, 
  setLastPage, 
  setSkip 
} from '../../store/api-slice';
import { getOrders } from '../../store/main-slice';
import { fetchOrders, fetchOrdersMy } from '../../services/api/api-orders';

const getOrdersThunk: AppThunk = (
  token: string,
  isAdmin: boolean,
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const orders = getState().main.orders;
  try {
    dispatch(getOrdersRequest());
    dispatch(setSkip(params.skip ? params.skip + LIMIT_ORDERS : LIMIT_ORDERS));
    const data = isAdmin ? (await fetchOrders(token, params)).data : (await fetchOrdersMy(token, params)).data;
    const newOrders = params.skip === 0 ? [...data] : [...orders, ...data];
    dispatch(getOrders(newOrders));
    dispatch(setLastPage(data.length < LIMIT_ORDERS));
    dispatch(getOrdersSuccess());
  } catch (error) {
    dispatch(getOrdersFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getOrdersThunk;