export const wsUrl = 'wss://pnzsad.ru/wsapp/';
export const BASE_URL = 'https://pnzsad.ru/api/v1/';

export const SWIPER_URL = 'swiper';
export const CATEGORIES_URL = 'products/categories';
export const SEEDLINGS_URL = 'products/seedlings';
export const AUTH_URL = 'auth';
export const USERS_URL = 'users';
export const CARTS_URL = 'carts';
export const QUESTIONS_URL = 'questions';
export const DELIVERY_TYPES_URL = 'orders/delivery-types';
export const DELIVERY_DATES_URL = 'orders/delivery-dates';
export const DELIVERY_POINTS_URL = 'orders/delivery-points';
export const DELIVERY_EVENTS_URL = 'orders/delivery-events';
export const SCORES_URL = 'products/scores';
export const ORDERS_URL = 'orders';
export const NOTIFICATIONS_URL = 'notifications';

export const JWT = 'JWT-RT-BLG';
export const CLIENT = 'client';
export const TOKEN = 'refreshToken';
export const CART = 'cart';

export const MIN_SWIPE_REQUIRED = 40;

export const DELAY = 10000;

export const LIMIT_SEEDLINGS = 15;
export const LIMIT_QUESTIONS = 8;
export const LIMIT_USERS = 10;
export const LIMIT_DELIVERY_EVENTS = 20;
export const LIMIT_DELIVERY_DATES = 20;
export const LIMIT_NOTIFICATIONS = 10;
export const LIMIT_ORDERS = 10;

export const VIDEO_URL = 'https://www.youtube.com/embed/';

// Срок жизни ACCESS_TOKEN - 20 минут
export const TIMER_ACCESS_TOKEN = 1200000;
// Срок жизни REFRESH_TOKEN - 1 месяц
export const TIMER_REFRESH_TOKEN = 2629743;
