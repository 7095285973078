import { useEffect, useMemo, useState } from 'react';
import Modal from '../../components/modal/modal';
import Form from '../../ui/form/form';
import InputPassword from '../../ui/inputs/input-password';
import Text from '../../ui/text';
import styles from './set-password.module.css';
import cn from 'classnames';
import BoxButtons from '../../components/box-buttons/box-buttons';
import { useFormWithValidation } from '../../utils/validation';
import { useDispatch, useSelector } from '../../services/hooks';
import setPasswordThunk from '../../thunks/auth/set-password';
import { messages } from '../../utils/messages';

export function SetPasswordPage() {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.user);
  const { 
    errorObject, 
    changePasswordRequest, 
    changePasswordSuccess 
  } = useSelector(state => state.api);
  const { values, handleChange, resetForm } = useFormWithValidation();
  const [visible, setVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isToken, setIsToken] = useState(true);

  useEffect(() => {
    if (errorObject?.statusCode === 400) {
      setErrorText(messages.passwordError);
    }
  }, [errorObject]);

  useEffect(() => {
    if (changePasswordSuccess) setVisible(true);
  }, [changePasswordSuccess]);

  useEffect(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    const lastPassword = values['last-password']
    const newPassword = values['new-password']
    if (lastPassword && newPassword) {
      setIsValid(true);
      if (newPassword.length < 6) {
        setErrorText('Длина пароля должна быть не менее 6 символов')
      } else setErrorText('');
    }
  }, [values]);

  const dataChangePassword = useMemo(() => ({
    'last-password': values['last-password'],
    'new-password': values['new-password'],
  }), [values]);

  useEffect(() => {
    if (!isToken && token) {
      dispatch(setPasswordThunk(dataChangePassword, token));
      setIsToken(true);
    }
  }, [isToken, dispatch, token, dataChangePassword]);

  const changeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorText('');
    if (token) dispatch(setPasswordThunk(dataChangePassword, token));
    else setIsToken(false);

  };

  const onClickCancel = () => {
    resetForm();
  };

  const handleCloseModal = () => {
    setVisible(false);
    resetForm();
  };

  const modal = (
    <Modal header='Смена пароля' onClose={handleCloseModal}>
      <Text 
        text='Пароль изменён' 
        className={cn(styles.text, styles.modal)}
      />
    </Modal>
  );

  return (
    <section className={styles.container}>
      <Form
        name='set-password'
        onSubmit={changeSubmit}
      >
        <InputPassword
            handleChange={handleChange} 
            name='last-password'
            value={values['last-password']}
            placeholder='Текущий пароль'
            label='Текущий пароль'
            className={styles.label}
          />
          <InputPassword 
            handleChange={handleChange} 
            name='new-password'
            value={values['new-password']}
            placeholder='Новый пароль'
            label='Новый пароль'
            className={styles.label}
            min={6}
            max={30}
          />
          <BoxButtons
            loading={changePasswordRequest}
            disabled={!isValid}
            handleClickCancel={onClickCancel}
          />
          {errorText && <Text 
            text={errorText}
            className={cn(styles.text, styles.error)}
          />}
      </Form>
      {visible && modal}
    </section>
  )
}
