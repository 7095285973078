import React, { FC, useState } from 'react';
import Input from './input';
import styles from './input.module.css';
import EyesButton from '../buttons/eyes-button';

interface IInputPasswordProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name?: string;
  value?: string;
  className?: string;
  label?: string;
  min?: number;
  max?: number;
}

const InputPassword: FC<IInputPasswordProps> = ({
  handleChange,
  placeholder = 'Пароль',
  inputRef,
  name = 'password',
  value, 
  className,
  label,
  min,
  max,
}) => {
  const [visible, setVisible] = useState(false);

  const onClickVisible = () => {
    setVisible(!visible);
  };

  return (
    <div className={styles.container}>
      <Input
        type={visible ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        required
        ref={inputRef}
        value={value}
        extraClass={className}
        minLength={min}
        maxLength={max}
        label={label}
      />
      <EyesButton 
        extraClass={styles.icon} 
        onClickHandler={onClickVisible}
        visible={visible}
      />
    </div>
  )
}

export default InputPassword;