import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getMyCartRequest,
  getMyCartFailed,
  getMyCartSuccess,
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { cart } from '../../services/helpers/api';
import { setDataCart } from '../../store/cart-slice';
import { TDataCartElement, TUpdateCartElement } from '../../services/types/data';
import updateMyCartThunk from './update-my-cart';
import { fetchMyCart } from '../../services/api/api-cart';

const setMyCartThunk: AppThunk = (
  token: string,
) => async ( dispatch ) => {
  const dataCart = cart.get();
  try {
    dispatch(getMyCartRequest());
    const { items } = (await fetchMyCart(token)).data;

    dispatch(getMyCartSuccess());
    const combinedCart: { [key: string]: number } = {};
    dataCart.forEach(({ seedling, count }) => {
      if (combinedCart[seedling]) {
        combinedCart[seedling] = combinedCart[seedling] > count ? combinedCart[seedling] : count;
      } else {
        combinedCart[seedling] = count;
      }
    });

    items.forEach(({ 'seedling-id': seedlingId, count }) => {
      if (combinedCart[seedlingId]) {
        combinedCart[seedlingId] = combinedCart[seedlingId] > count ? combinedCart[seedlingId] : count;
      } else {
        combinedCart[seedlingId] = count;
      }
    });

    const newDataCart: Array<TDataCartElement<string>> = Object.keys(combinedCart).map(seedling => ({
      seedling: seedling,
      count: combinedCart[seedling]
    }));

    const dataUpdate: Array<TUpdateCartElement> = newDataCart.map(el => ({
      'seedling-id': el.seedling,
      count: el.count,
    }));
    dispatch(setDataCart(newDataCart));
    dispatch(updateMyCartThunk(dataUpdate, token));
    cart.remove()

  } catch (error) {
    dispatch(getMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default setMyCartThunk;