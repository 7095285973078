import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  getOrdersAllowedFailed, 
  getOrdersAllowedRequest, 
  getOrdersAllowedSuccess, 
} from '../../store/api-slice';
import { fetchOrdersAllowed } from '../../services/api/api-orders';
import { getOrdersAllowed } from '../../store/main-slice';

const getOrdersAllowedThunk: AppThunk = (
) => async (dispatch) => {
  try {
    dispatch(getOrdersAllowedRequest());
    const response = await fetchOrdersAllowed();
    dispatch(getOrdersAllowed(response.data));
    dispatch(getOrdersAllowedSuccess());
  } catch (error) {
    dispatch(getOrdersAllowedFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getOrdersAllowedThunk;