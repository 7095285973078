import { AppThunk } from '../../services/types/store';
import {
  TAPIError, 
  TAPISeedlingFull
} from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  postSeedlingFailed, 
  postSeedlingRequest,
  postSeedlingSuccess
} from '../../store/api-admin-slice';
import { fetchPostSeedling } from '../../services/api/api-products';

const postSeedlingThunk: AppThunk = (
  data: TAPISeedlingFull,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postSeedlingRequest());
    await fetchPostSeedling(data, token);
    dispatch(postSeedlingSuccess());
  } catch (error) {
    dispatch(postSeedlingFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postSeedlingThunk;