import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  getDeliveryTypesFailed, 
  getDeliveryTypesRequest, 
  getDeliveryTypesSuccess 
} from '../../store/api-slice';
import { getDeliveryTypes } from '../../store/main-slice';
import { fetchDeliveryTypes } from '../../services/api/api-delivery-types';

const getDeliveryTypesThunk: AppThunk = (
  visible?: boolean,
) => async (dispatch) => {
  try {
    dispatch(getDeliveryTypesRequest());
    const response = await fetchDeliveryTypes(visible);
    dispatch(getDeliveryTypes(response.data));
    dispatch(getDeliveryTypesSuccess());
  } catch (error) {
    dispatch(getDeliveryTypesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getDeliveryTypesThunk;