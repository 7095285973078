import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteDeliveryPointsFailed,
  deleteDeliveryPointsRequest,
  deleteDeliveryPointsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteDeliveryPoints } from '../../services/api/api-delivery-points';

const deleteDeliveryPointThunk: AppThunk = (
  pointId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteDeliveryPointsRequest());
    await fetchDeleteDeliveryPoints(pointId, token);
    dispatch(deleteDeliveryPointsSuccess());
  } catch (error) {
    dispatch(deleteDeliveryPointsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteDeliveryPointThunk;