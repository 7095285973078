import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Text from '../../ui/text';
import styles from '../delivery/delivery.module.css';
import Button from '../../ui/buttons/button';
import stylesButton from '../../components/questions/questions.module.css';
import cn from 'classnames';
import { TDeliveryPoint } from '../../services/types/data';
import { useFormDeliveryPoint } from '../../utils/validation';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import Modal from '../../components/modal/modal';
import ModalDelete from '../../components/modal-delete/modal-delete';
import { messages } from '../../utils/messages';
import { TAPIObjectError } from '../../services/types/api';
import deleteDeliveryPointThunk from '../../thunks/delivery-points/delete-delivery-point';
import FormDeliveryPoint from '../../components/form-delivery-point/form-delivery-point';

export function DeliveryPointPage() {
  const dispatch = useDispatch();
  const { 
    deliveryPoints, 
    deliveryTypes 
  } = useSelector(state => state.main);
  const { 
    deliveryPointsSuccess,
    deliveryPointsFailed,
    errorObject,
  } = useSelector(state => state.apiAdmin);

  const [visible, setVisible] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [nameButton, setNameButton] = useState('Создать');
  const [errorText, setErrorText] = useState('');

  const deliveryPointInit = useMemo(() => ({
    id: '',
    point: '',
    location: '',
    comment: '',
    show: true,
    'delivery-type-id': deliveryTypes?.length ? deliveryTypes[0].type : '',
    'already-use': false,
    'dates-required': false,
  }), [deliveryTypes]);

  const [deliveryPoint, setDeliveryPoint] = useState<TDeliveryPoint>(deliveryPointInit);
  const { handleChange } = useFormDeliveryPoint(deliveryPoint, setDeliveryPoint, setErrorText);

  useEffect(() => {
    if (deliveryPointsSuccess) {
      setVisible(prevVisible => ({
        ...prevVisible,
        add: false,
        edit: false,
      }));
      setDeliveryPoint(deliveryPointInit);
    }
  }, [deliveryPointsSuccess, deliveryPointInit]);

  useEffect(() => {
    if (deliveryPointsFailed && errorObject?.statusCode === 422) {
      const textError = errorObject?.detail[0] as TAPIObjectError;
      if (textError.loc[1] === 'point') setErrorText(messages.pointError);
      if (textError.loc[1] === 'location') setErrorText(messages.locationError);
    }
  }, [deliveryPointsFailed, errorObject]);

  useEffect(() => {
    setNameButton(visible.add ? 'Скрыть форму' : 'Создать');
    if (visible.add) {
      setDeliveryPoint(deliveryPointInit);
      setErrorText('');
    }
  }, [visible, deliveryPointInit]);

  const onClickToggle = () => {
    if (nameButton === 'Создать') {
      setVisible({
        ...visible,
        add: true,
      });
    }
    else setVisible({
      ...visible,
      add: false,
    });
  };

  const handleCloseModal = () => {
    setVisible({
      add: false,
      edit: false,
      delete: false,
    });
    setDeliveryPoint(deliveryPointInit);
    setErrorText('');
  };

  const onClickEdit = (point: TDeliveryPoint) => {
    setDeliveryPoint({
      ...point,
      'delivery-type-id': deliveryTypes?.find(type => type.id === point['delivery-type-id'])?.type || ''
    })
    setVisible({
      ...visible,
      add: false,
      edit: true,
    });
    setErrorText('');
  };

  const onClickDelete = (date: TDeliveryPoint) => {
    setDeliveryPoint(date)
    setVisible({
      ...visible,
      add: false,
      delete: true,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteDeliveryPointThunk(deliveryPoint.id));
    setVisible({
      ...visible,
      delete: false,
    });
  };

  const modalEdit = (
    <Modal
      header='Редактирование точки доставки'
      onClose={handleCloseModal}
      extraClass={styles.modal}
      classTitle={styles.modal_title}
    >
      <FormDeliveryPoint
        handleCancel={handleCloseModal}
        value={deliveryPoint}
        handleChange={handleChange}
        error={errorText}
      />
    </Modal>
  );

  return (
    <article className={styles.content}>
      <Button
        className={cn(stylesButton.button_admin, styles.button)}
        clickHandler={onClickToggle}
      >
        <Text text={nameButton} />
      </Button>
      {visible.add && (
        <FormDeliveryPoint
          handleCancel={handleCloseModal}
          value={deliveryPoint}
          handleChange={handleChange}
          error={errorText}
        />
      )}
      <List className={styles.list}>
        {deliveryPoints?.map(item => (
          <ListItem className={styles.item} key={item.id}>
            <Title
              type='h4'
              text={item.point}
              className={cn(styles.title, styles.grid_item)}
            />
            <Text text={item.location} className={cn(styles.text, styles.grid_item)} />
            <Text text={item.comment} className={cn(styles.text, styles.grid_item)} />
            <Text
              text={deliveryTypes?.find(type => type.id === item['delivery-type-id'])?.type}
              className={cn(styles.text, styles.grid_item, styles.span)}
            />
            <Text 
              text={item.show ? 'Отображается' : 'Не отображается'}
              className={cn(styles.text, styles.grid_item)}
            />
            <EditButton onClickHandler={() => onClickEdit(item)} />
            <CloseButton
              onClose={() => onClickDelete(item)}
              size='small'
              del={!item['already-use'] ? true : false}
              disabled={item['already-use'] && true}
            />
          </ListItem>
        ))}
      </List>
      {visible.edit && modalEdit}
      {visible.delete && (
        <ModalDelete
          header='Подтвердите удаление точки доставки:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={deliveryPoint.point}
        />
      )}
    </article>
  )
}