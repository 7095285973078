import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIDataDeliveryType } from '../../services/types/api';
import { 
  patchDeliveryTypesFailed,
  patchDeliveryTypesRequest,
  patchDeliveryTypesSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPatchDeliveryTypes } from '../../services/api/api-delivery-types';

const updateDeliveryTypeThunk: AppThunk = (
  data: TAPIDataDeliveryType,
  typeId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchDeliveryTypesRequest());
    await fetchPatchDeliveryTypes(data, typeId, token);
    dispatch(patchDeliveryTypesSuccess());
  } catch (error) {
    dispatch(patchDeliveryTypesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateDeliveryTypeThunk;