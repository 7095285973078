import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  activationRequest,
  activationSuccess,
  activationFailed,
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { activation } from '../../services/api/api-auth';

const activationThunk: AppThunk = (
  userId: string,
  codeActivation: string,
) => async (dispatch) => {
  try {
    dispatch(activationRequest());
    await activation(userId, codeActivation);
    dispatch(activationSuccess());
  } catch (error) {
    dispatch(activationFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default activationThunk;
