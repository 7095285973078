import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteSeedlingFailed,
  deleteSeedlingRequest,
  deleteSeedlingSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteSeedling } from '../../services/api/api-products';

const deleteSeedlingThunk: AppThunk = (
  seedlingId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteSeedlingRequest());
    await fetchDeleteSeedling(seedlingId, token);
    dispatch(deleteSeedlingSuccess());
  } catch (error) {
    dispatch(deleteSeedlingFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteSeedlingThunk;