import { configureStore } from '@reduxjs/toolkit';
import mainReducer from './main-slice';
import apiReducer from './api-slice';
import cartReducer from './cart-slice';
import userReducer from './user-slice';
import adminReducer from './admin-slice';
import apiAdminReducer from './api-admin-slice';
import { socketMiddleware } from '../services/middleware/socket-middleware';
import { wsUrl } from '../utils/constants';
import wsReducer from './ws-slice';

export const store = configureStore({
  reducer: {
    main: mainReducer,
    api: apiReducer,
    cart: cartReducer,
    user: userReducer,
    admin: adminReducer,
    apiAdmin: apiAdminReducer,
    ws: wsReducer,
  },
  middleware: (
    getDefaultMiddleware
  ) => getDefaultMiddleware().concat(socketMiddleware(wsUrl)),
});

