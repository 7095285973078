import { FC } from 'react';
import cn from 'classnames';
import Button from '../../ui/buttons/button';
import styles from './button-menu.module.css';

interface IButtonMenuProps {
  onClickMenu: () => void;
  open: boolean;
}

const ButtonMenu: FC<IButtonMenuProps> = ({ 
  onClickMenu, 
  open,
}) => {
  const styleSpan = cn(
    styles.line,
    open ? styles.line_open : styles.line_close
  );

  const isExpanded = open ? true : false;

  return (
    <Button 
      className={styles.button} 
      clickHandler={onClickMenu} 
      aria-label='Toggle menu' 
      aria-expanded={isExpanded}
    >
      <span className={styleSpan} />
      <span className={styleSpan} />
      <span className={styleSpan} />
    </Button>
  )
};

export default ButtonMenu;
