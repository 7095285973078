import React, { FC } from 'react';
import styles from './input.module.css';
import cn from 'classnames';

interface ICheckboxProps {
  extraClass?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  checked?: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({
  extraClass,
  onChange,
  label,
  name,
  checked,
}) => {

  const className = cn(styles.input, extraClass);

  return (
    <label className={styles.label}>
      {label}
      <input
        name={name}
        type='checkbox'
        className={className}
        onChange={onChange}
        checked={checked}
      />
    </label>
  );
};

export default Checkbox;
