import React, { FC } from 'react';
import styles from './input.module.css';

interface ITextareaProps {
  name: string;
  placeholder?: string;
  cols?: number;
  rows?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  value: string;
  required?: boolean;
  maxLength?: number;
  label?: string;
}

const Textarea: FC<ITextareaProps> = ({
  name,
  placeholder,
  cols,
  rows=5,
  handleChange,
  value,
  required,
  maxLength,
  label,
}) => {
  const textarea = (
    <textarea
      name={name}
      placeholder={placeholder}
      cols={cols}
      rows={rows}
      maxLength={maxLength}
      onChange={handleChange}
      value={value}
      className={styles.textarea}
      required={required}
    />
  )
  if (label) return (
    <label className={styles.textarea_label}>
      {label}
      {textarea}
    </label>
  )

  return (textarea);
}

export default Textarea;