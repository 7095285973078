import { 
  Link, 
  useLocation, 
  useNavigate 
} from 'react-router-dom';
import Button from '../../ui/buttons/button';
import Form from '../../ui/form/form';
import Input from '../../ui/inputs/input';
import Text from '../../ui/text';
import Title from '../../ui/title/title';
import { useFormWithValidation } from '../../utils/validation';
import styles from '../login/login.module.css';
import cn from 'classnames';
import { useDispatch, useSelector } from '../../services/hooks';
import forgotPasswordThunk from '../../thunks/auth/forgot-password';
import { filterEmptyStrings } from '../../utils/functions';
import { useEffect, useState } from 'react';
import Modal from '../../components/modal/modal';
import { messages } from '../../utils/messages';
import InputPassword from '../../ui/inputs/input-password';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

export function ForgotPasswordPage() {

  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.search.slice(4);
  const { forgotPasswordSuccess, errorObject } = useSelector(state => state.api);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (forgotPasswordSuccess && id) navigate('/login', { replace: true })
    if (errorObject?.statusCode === 307) setVisible(true)
    if (errorObject?.statusCode === 400 && id) setErrorText(messages.passwordForgotErrorCode)
    else if (errorObject?.statusCode === 400 && !id) setErrorText(messages.passwordForgotErrorEmail);
  }, [forgotPasswordSuccess, errorObject, id, navigate]);

  useEffect(() => {
    setVisible(false);
    setErrorText('');
  }, []);

  const { values, handleChange, isValid } = useFormWithValidation();

  const forgotPasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorText('');
    const filteredData = filterEmptyStrings(values);
    dispatch(forgotPasswordThunk(filteredData, id));
  };

  const handleCloseModal = () => {
    setVisible(false);
    navigate('/');
  };

  const modal = (
    <Modal header='' onClose={handleCloseModal}>
      <Text 
        text={messages.passwordForgotSuccess} 
        className={cn(styles.text, styles.modal)} 
      />
    </Modal>
  );

  return (
    <HelmetProvider>
      <section className={styles.container}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <div className={styles.content}>
          <Title type='h3' text='Восстановление пароля' />
          <Form 
            name='forgot-password' 
            onSubmit={forgotPasswordSubmit}
          >
            {id ? (<>
              <Input
                placeholder='Код активации'
                onChange={handleChange}
                name='activation-code'
                maxLength={30}
                required
              />
              <InputPassword
                handleChange={handleChange} 
                name='new-password'
                value={values['new-password']}
                placeholder='Новый пароль'
                min={6}
                max={30}
              />
            </>) : (
              <Input
                type='email'
                placeholder='E-mail'
                onChange={handleChange}
                name='email'
                required
              />
            )}
            <Button
              type='submit'
              disabled={!isValid}
              className={cn(styles.button, styles.save)}
            >
              Восстановить
            </Button>
          </Form>
          {errorText && <Text
            text={errorText} 
            className={cn(styles.text, styles.error)} 
          />}
          <div className={styles.item}>
            <Text 
              text='Вспомнили пароль?' 
              className={styles.text} 
            />
            <Link to='/login'>
              <Text
                text='Войти'
                className={styles.link}
              />
            </Link>
          </div>
        </div>
        {visible && modal}
      </section>
    </HelmetProvider>
  )
}