import Address from '../../components/address/address';
import { linksNav } from '../../utils/data';
import mainStyles from '../main//main.module.css';
import styles from './contacts.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export function ContactsPage() {
  return (
    <HelmetProvider>
      <main className={mainStyles.content}>
        <Helmet>
          <title>{`${linksNav.contacts} | ${linksNav.title}`}</title>
        </Helmet>
        <section className={styles.contacts}>
        <iframe
          className={styles.iframe}
          title='Карта'
          src='https://yandex.ru/map-widget/v1/?um=constructor%3A7cbbe7b6a2e43566de24760322b08d74318fe0609dbb58c3f32be87f078e6a99&amp;source=constructor'>
        </iframe>
        <Address isFooter={false} />
        </section>
      </main>
    </HelmetProvider>
  )
}