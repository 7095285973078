import { FC } from 'react';
import cn from 'classnames';
import styles from './title.module.css';
import { TTitle } from '../../services/types/data';

interface ITitleProps {
  text: string;
  className?: string;
  type: TTitle;
  clickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Title: FC<ITitleProps> = ({ text, className, type, clickHandler }) => {
  switch (type) {
    case 'h1': {
      return (
        <h1 
          className={cn(
            styles.title, 
            styles.title_main, 
            className
          )}>
          {text}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2 
          className={cn(
            styles.title, 
            styles.title_second, 
            className
          )}
        >
          {text}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3 
          className={cn(
            styles.title, 
            styles.title_third, 
            className
          )}
          onClick={clickHandler}
        >
          {text}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4 
          className={cn(
            styles.title, 
            styles.title_fourth, 
            className
          )}
        >
          {text}
        </h4>
      );
    }
  }
};

export default Title;
