import { FC } from 'react';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import stylesButton from '../questions/questions.module.css';
import styles from './modal-delete.module.css';
import Modal from '../modal/modal';
import cn from 'classnames';

interface IModalDeleteProps {
  header: string;
  handleCloseModal: () => void;
  confirmDelete: () => void;
  text: string;
  error?: boolean;
  
}

const ModalDelete: FC<IModalDeleteProps> = ({ 
  handleCloseModal,
  confirmDelete,
  text,
  header,
  error,
}) => (
  <Modal header={header} onClose={handleCloseModal}>
    <Text text={text} className={cn(styles.text, error && styles.error)} />
    <div className={styles.modal}>
      <Button
        className={stylesButton.button_admin}
        clickHandler={confirmDelete}
        disabled={error}
      >
        <Text text='Подтвердить' />
      </Button>
      <Button
        className={stylesButton.button_admin}
        clickHandler={handleCloseModal}
      >
        <Text text='Отменить' />
      </Button>
    </div>
  </Modal>
);

export default ModalDelete;