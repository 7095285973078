import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  signupFailed,
  signupRequest,
  signupSuccess,
} from '../../store/api-slice';
import { TAPIError, TAPIRegisterObject } from '../../services/types/api';
import { deleteCookie } from '../../services/helpers/api';
import { TOKEN } from '../../utils/constants';
import { signup } from '../../services/api/api-auth';

const signupThunk: AppThunk = (
  registerData: TAPIRegisterObject,
) => async (dispatch) => {
  try {
    dispatch(signupRequest());
    await signup(registerData);
    dispatch(signupSuccess());
    deleteCookie(TOKEN);
  } catch (error) {
    dispatch(signupFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default signupThunk;
