import { AppThunk } from '../../services/types/store';
import {
  TAPIError, 
  TAPISwiper
} from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  patchSwiperFailed,
  patchSwiperRequest,
  patchSwiperSuccess,
} from '../../store/api-admin-slice';
import { fetchPatchSwiper } from '../../services/api/api-swiper';

const updateSwiperThunk: AppThunk = (
  data: TAPISwiper,
  slideId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchSwiperRequest());
    await fetchPatchSwiper(data, slideId, token);
    dispatch(patchSwiperSuccess());
  } catch (error) {
    dispatch(patchSwiperFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateSwiperThunk;