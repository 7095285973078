import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link} from 'react-router-dom';
import cn from 'classnames';
import { pathNames, linksNav } from '../../utils/data';
import styles from './menu.module.css';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import { ModalOverlay } from '../../ui/modal-overlay/modal-overlay';

const modalRoot = document.getElementById('react-menu')!;

interface IMenuProps {
  open: boolean;
  id: string;
  onClose: () => void;
}

const Menu: FC<IMenuProps> = ({ open, id, onClose }) => {

  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  useEffect(() => {
    const handleEscClose = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscClose);

    return () => {
      document.removeEventListener('keydown', handleEscClose);
    }
  }, [onClose])


  return ReactDOM.createPortal (
    <nav
      className={styles.container}
      aria-hidden={!isHidden}
      id={id}
    >
      <List className={styles.list}
        style={{
          transform: open ? 'translateX(0)' : 'translateX(-100%)'
        }}
      >
        <ListItem className={styles.hide} onClick={onClose}>
          <Link to={pathNames.main} className={cn(styles.link, styles.title)} tabIndex={tabIndex}>
            {linksNav.title}
          </Link>
        </ListItem>
        <ListItem className={styles.hide} onClick={onClose}>
          <Link to={pathNames.seedlings} className={styles.link} tabIndex={tabIndex}>
            {linksNav.seedlings}
          </Link>
        </ListItem>
        <ListItem className={styles.hide} onClick={onClose}>
          <Link to={pathNames.about} className={styles.link} tabIndex={tabIndex}>
            {linksNav.about}
          </Link>
        </ListItem>
        <ListItem className={styles.hide} onClick={onClose}>
          <Link to={pathNames.contacts} className={styles.link} tabIndex={tabIndex}>
            {linksNav.contacts}
          </Link>
        </ListItem>
      </List>
      {open && <ModalOverlay onClose={onClose} />}
    </nav>,
    modalRoot
  )
};

export default Menu;
