import { AxiosRequestConfig } from 'axios';
import { 
  TAPIForgotPassword, 
  TAPILoginObject, 
  TAPIPassword, 
  TAPIRegisterObject, 
} from '../types/api';
import { AUTH_URL, USERS_URL } from '../../utils/constants';
import { blogAPI, injectBearerToken } from '../helpers/api';
import { 
  IActivation,
  IFetchChangePassword, 
  IFetchForgotPassword, 
  IFetchUpdateToken, 
  ILogin, 
  ILogout, 
  ISignup, 
} from './api.type';

export const signup: ISignup = (
  registerData: TAPIRegisterObject,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${AUTH_URL}/signup`,
    method: 'post',
    data: registerData,
  };
  return blogAPI(requestConfig);
};

export const login: ILogin = (
  loginData: TAPILoginObject,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${AUTH_URL}/login`,
    method: 'post',
    data: loginData,
  };
  return blogAPI(requestConfig);
};

export const logout: ILogout = (
  token: string,
  id?: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${AUTH_URL}/logout`,
    params: { id },
    method: 'get',
  }
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchUpdateToken: IFetchUpdateToken = (
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${AUTH_URL}/token`,
    method: 'POST',
    data: {
      'refresh-token': token,
    },
  };
  return blogAPI(requestConfig);
};

export const fetchChangePassword: IFetchChangePassword = (
  data: TAPIPassword,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${USERS_URL}/password-change`,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchForgotPassword: IFetchForgotPassword = (
  data: TAPIForgotPassword,
  id?: string,
) => {
  const userId = id ? `?id=${id}` : ''
  const requestConfig: AxiosRequestConfig = {
    url: `${USERS_URL}/forgot-password${userId}`,
    method: 'POST',
    data,
  };
  return blogAPI(requestConfig);
};

export const activation: IActivation = (
  id: string,
  code: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${AUTH_URL}/activation`,
    params: { id, code },
    method: 'get',
  }
  return blogAPI(requestConfig);
};