import { FC } from 'react';
import cn from 'classnames';
import Text from '../../ui/text';
import { contacts } from '../../utils/data';
import styles from './address.module.css';
import Title from '../../ui/title/title';

interface IAddressProps {
  isFooter: boolean;
}

const Address: FC<IAddressProps> = ({ isFooter }) => {
  return (
    <address className={isFooter ? styles.address : styles.address_column}>
      {!isFooter && (
        <Title text='Телефон' type='h3' className={styles.title} />
      )}
      <a href={`tel:${contacts.phone}`} className={cn(styles.link, styles.tel, styles.text)}>
        {contacts.phone}
      </a>
      {!isFooter && (
        <Title text='e-mail' type='h3' className={styles.title} />
      )}
      <a href={`mailto:${contacts.email}`} className={cn(styles.link, styles.text, styles.mail)}>
        {contacts.email}
      </a>
      {!isFooter && (
        <Title text='Адрес' type='h3' className={styles.title} />
      )}
      <Text 
        text={contacts.address}
        className={cn(styles.link, styles.map, styles.text)}
      />
    </address>
  )
};

export default Address;