import { 
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  useStateRef,
  useSelector
} from '../../services/hooks';
import { getRefValue, getTouchEventData } from '../../utils/functions';
import { DELAY, MIN_SWIPE_REQUIRED } from '../../utils/constants';
import SwiperItem from '../swiper-item/swiper-item';
import styles from './swiper.module.css';
import cn from 'classnames';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Button from '../../ui/buttons/button';

function Swiper() {
  const { slides } = useSelector(state => state.main);
  const containerRef = useRef<HTMLUListElement>(null);
  const containerWidthRef = useRef(0);
  const minOffsetXRef = useRef(0);
  const currentOffsetXRef = useRef(0);
  const startXRef = useRef(0);
  const [, setOffsetX, offsetXRef] = useStateRef(0);
  const [currentIdx, setCurrentIdx] = useState(0);

  const slidesData = slides
    ?.filter(item => item.available === true)
    .sort((a, b) => a['display-order'] - b['display-order']);

  const length = slidesData ? slidesData.length : 0;

  const onTouchMove = (e: TouchEvent | MouseEvent) => {
    const currentX = getTouchEventData(e).clientX;
    const diff = getRefValue(startXRef) - currentX;
    let newOffsetX = getRefValue(currentOffsetXRef) - diff;
    const maxOffsetX = 0;
    const minOffsetX = getRefValue(minOffsetXRef);
    if (newOffsetX > maxOffsetX) {
      newOffsetX = maxOffsetX;
    }
    if (newOffsetX < minOffsetX) {
      newOffsetX = minOffsetX;
    }
    setOffsetX(newOffsetX);
  };

  const onTouchEnd = () => {
    const currentOffsetX = getRefValue(currentOffsetXRef);
    const containerWidth = getRefValue(containerWidthRef);
    let newOffsetX = getRefValue(offsetXRef);
    const diff = currentOffsetX - newOffsetX;
    if (Math.abs(diff) > MIN_SWIPE_REQUIRED) {
      if (diff > 0) {
        newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth;
      } else {
        newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth;
      }
    } else {
      newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth;
    }
    setOffsetX(newOffsetX);
    setCurrentIdx(Math.abs(newOffsetX / containerWidth));
    window.removeEventListener('touchend', onTouchEnd);
    window.removeEventListener('touchmove', onTouchMove);
    window.removeEventListener('mouseup', onTouchEnd);
    window.removeEventListener('mousemove', onTouchMove);
  };

  const onTouchStart = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    currentOffsetXRef.current = getRefValue(offsetXRef);
    startXRef.current = getTouchEventData(e).clientX;
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth;
    containerWidthRef.current = containerWidth;
    minOffsetXRef.current = containerWidth - containerEl.scrollWidth;
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('mousemove', onTouchMove);
    window.addEventListener('mouseup', onTouchEnd);
    window.addEventListener('onClick', (e) => {e.stopPropagation(); return false});
  };

  const indicatorOnClick = (idx: number) => {
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth;
    setCurrentIdx(idx);
    setOffsetX(-(containerWidth * idx));
  };

  const nextItem = () => {
    if (currentIdx < (length - 1)) {
      setCurrentIdx(prevState => prevState + 1);
    } else setCurrentIdx(0);
  };

  const previousItem = () => {
    if (currentIdx > 0) {
      setCurrentIdx(prevState => prevState - 1);
    } else setCurrentIdx(length - 1);
  };

  useEffect(() => {
    if (length > 1) {
      const interval = setInterval(() => {
        setCurrentIdx((current) => {
            const res = current === length - 1 ? 0 : current + 1;
            indicatorOnClick(res);
            return res
        })
      }, DELAY)
      return () => clearInterval(interval)
    }
  }, [currentIdx, length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className={styles.container}>
      {(length > 0) && <Button
        clickHandler={previousItem}
        className={styles.button__left}
      >
        <span className={styles.left} />
      </Button>}
      <div
        className={styles.content}
        onTouchStart={onTouchStart}
        onMouseDown={onTouchStart}
      >
        <List
          ref={containerRef}
          className={styles.list}
          style={{ 
            transform: `translateX(-${currentIdx * 100}%)`
          }}
        >
          {slidesData?.map((item, idx) => (
            <SwiperItem key={idx} slide={item} />
          ))}
        </List>
      </div>
      {(length > 0) && <Button
        clickHandler={nextItem}
        className={styles.button__right}
      >
        <span className={styles.right} />
      </Button>}
      <List className={styles.indicator}>
        {slidesData?.map((_item, index) => (
          <ListItem
            key={index}
            className={cn(
              styles.indicator__item,
              currentIdx === index ? styles.active : '',
              (index - currentIdx > 1 || currentIdx - index > 1) ? styles.far : ''
            )}
            onClick={() => indicatorOnClick(index)}
          />
        ))}
      </List>
    </section>
  )
}

export default Swiper;