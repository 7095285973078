import { FC } from 'react';
import styles from './form-delivery-date.module.css';
import Text from '../../ui/text';
import { useDispatch } from '../../services/hooks';
import FormDelivery from '../form-delivery/form-delivery';
import { TDeliveryDate } from '../../services/types/data';
import Input from '../../ui/inputs/input';
import { dateFormatter, filterEmptyStrings } from '../../utils/functions';
import Checkbox from '../../ui/inputs/checkbox';
import postDeliveryDateThunk from '../../thunks/delivery-dates/post-delivery-date';
import { TAPIUpdateDeliveryDate } from '../../services/types/api';
import updateDeliveryDateThunk from '../../thunks/delivery-dates/update-delivery-dates';
import cn from 'classnames';
import Textarea from '../../ui/inputs/textarea';

interface IFormDeliveryDateProps {
  handleCancel: () => void;
  value: TDeliveryDate;
  isAdd: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  error: string;
}

const FormDeliveryDate: FC<IFormDeliveryDateProps> = ({
  handleCancel,
  value,
  isAdd,
  handleChange,
  error,
}) => {
  const dispatch = useDispatch();

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.textContent?.includes('Создать')) {
      const filteredData = filterEmptyStrings(value);
      dispatch(postDeliveryDateThunk(filteredData));
    } else {
      const dataUpdateDate: TAPIUpdateDeliveryDate = {
        comment: value.comment,
        show: value.show,
      }
      dispatch(updateDeliveryDateThunk(dataUpdateDate, value.id))
    }
  };

  return (
    <FormDelivery
      name='delivery-date'
      legend='Дата доставки'
      onClickSubmit={onClickSubmit}
      handleCancel={handleCancel}
      id={value.id}
    >
      {isAdd ? (
        <Input
          name='date'
          type='date'
          label='Дата доставки'
          onChange={handleChange}
          required={true}
          value={value.date}
        />
      ) : (
        <Text text='Дата доставки: ' className={styles.date} >
          <span className={styles.span}>
            {value.date && dateFormatter(new Date(value.date))}
          </span>
        </Text>
      )}
      <Textarea
        name='comment'
        placeholder='Комментарий'
        label='Комментарий'
        maxLength={250}
        handleChange={handleChange}
        value={value.comment || ''}
      />
      <Checkbox
        name='show'
        label='Видимость'
        checked={value.show}
        onChange={handleChange}
      />
      {error && <Text
        text={error}
        className={cn(styles.text, styles.error)} 
      />}
    </FormDelivery>
  )
};

export default FormDeliveryDate;
