import { 
  FC,
  useEffect,
  useState,
} from 'react';
import { 
  TButtonState, 
  TDataCartElement, 
  TSeedling,
} from '../../services/types/data';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './card.module.css';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import Button from '../../ui/buttons/button';
import { useDispatch, useSelector } from '../../services/hooks';
import addDataCartThunk from '../../thunks/carts/add-data-cart';
import { priceFormat } from '../../utils/functions';
import ListItem from '../../ui/list-item';

type TCardProps = {
  card: TSeedling;
}

const Card: FC<TCardProps> = (({ card }) => {
  const {
    id,
    title,
    image,
    'short-description': shortDescription,
    stock,
    'retail-price': retailPrice,
    'wholesale-price': wholesalePrice,
    score,
  } = card;
  const dispatch = useDispatch();
  const { dataCart } = useSelector(state => state.cart);
  const { 
    'is-wholesaler': isWholesaler,
    token,
  } = useSelector(state => state.user);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: '',
    class: '',
    disabled: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (stock === 0) {
      setButtonState(prevState => ({
        ...prevState,
        text: 'В корзину',
        disabled: true,
      }));
    }
  }, [stock]);

  useEffect(() => {
    if (stock !== 0) {
      if(dataCart.some((el) => el.seedling === id)) {
        setButtonState(prevState => ({
          ...prevState,
          text: 'Оформить',
          class: styles.button_active,
        }))
      } else setButtonState(prevState => ({
        ...prevState,
        text: 'В корзину',
      }))
    }
  }, [dataCart, stock, id]);

  const onClickButtonCart = () => {
    if (buttonState.text === 'В корзину') {
      const element: TDataCartElement<string> = {
        seedling: card.id,
        count: 1,
      };
      dispatch(addDataCartThunk(element, token));
    } 
    if (buttonState.text === 'Оформить') {
      navigate('/cart')
    }
  };

  const onClickTitle = () => {
    navigate(`/seedlings/${id}`);
  }

  return (
    <ListItem className={styles.content} style={{ backgroundImage: `URL(${image})` }}>
      <Link
        to={{ pathname: `/seedlings/${id}` }}
        className={styles.image}
        style={{ backgroundImage: `URL(${image})` }}
      />
      <div className={styles.description}>
        <Title type='h3' text={title} className={styles.title} clickHandler={onClickTitle} />
        <Text text={shortDescription} className={cn(styles.caption, styles.title)} />
        <Text 
          text={stock > 0 ? `Всего ${stock} шт.` : 'Нет в наличии'} 
          className={cn(styles.text, styles.stock)} 
        />
        <Text 
          text={score ? `★ ${score.toFixed(1)}` : '☆ Нет оценок'}
          className={cn(styles.caption, styles.score)}
        />
        <Text className={cn(styles.text, styles.price)}>
          {isWholesaler ? priceFormat(wholesalePrice) : priceFormat(retailPrice)}
        </Text>
        <Button 
          className={cn(styles.button, buttonState.class)} 
          clickHandler={onClickButtonCart}
          disabled={buttonState.disabled}
        >
          <Text text={buttonState.text} />
        </Button>
      </div>
    </ListItem>
  )
});

export default Card;
