import { AppThunk } from '../../services/types/store';
import { cart } from '../../services/helpers/api';
import { TDataCartElement, TUpdateCartElement } from '../../services/types/data';
import { updateDataCart } from '../../store/cart-slice';
import { TAPIError } from '../../services/types/api';
import { updateMyCartFailed } from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import updateMyCartThunk from './update-my-cart';

const addDataCartThunk: AppThunk = (
  element: TDataCartElement<string>,
  token: string,
) => async (dispatch, getState) => {
  const dataCart = getState().cart.dataCart;
  try {
    const newDataCart = [...dataCart, element];
    dispatch(updateDataCart(newDataCart));
    if (!token) {
      cart.set(newDataCart);
    } else {
      const dataUpdate: Array<TUpdateCartElement> = newDataCart.map(el => ({
        'seedling-id': el.seedling,
        count: el.count,
      }));
      dispatch(updateMyCartThunk(dataUpdate, token));
    }
  } catch (error) {
    dispatch(updateMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default addDataCartThunk;