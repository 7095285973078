import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteNotificationsFailed,
  deleteNotificationsRequest,
  deleteNotificationsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteNotifications } from '../../services/api/api-notifications';

const deleteNotificationsThunk: AppThunk = (
  token: string,
  created: string,
) => async (dispatch) => {
  try {
    dispatch(deleteNotificationsRequest());
    await fetchDeleteNotifications(token, { created });
    dispatch(deleteNotificationsSuccess());
  } catch (error) {
    dispatch(deleteNotificationsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteNotificationsThunk;