import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIUpdateDeliveryEvent } from '../../services/types/api';
import { 
  patchDeliveryEventsFailed,
  patchDeliveryEventsRequest,
  patchDeliveryEventsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPatchDeliveryEvents } from '../../services/api/api-delivery-events';

const updateDeliveryEventThunk: AppThunk = (
  data: TAPIUpdateDeliveryEvent,
  eventId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchDeliveryEventsRequest());
    await fetchPatchDeliveryEvents(data, eventId, token);
    dispatch(patchDeliveryEventsSuccess());
  } catch (error) {
    dispatch(patchDeliveryEventsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateDeliveryEventThunk;