import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getSeedlingsFailed, 
  getSeedlingsRequest, 
  getSeedlingsSuccess,
  setLastPage,
  setSkip,
} from '../../store/api-slice';
import { getSeedlings } from '../../store/main-slice';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { LIMIT_SEEDLINGS } from '../../utils/constants';
import { fetchSeedlings } from '../../services/api/api-products';

const getSeedlingsThunk: AppThunk = (
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const seedlings = getState().main.seedlings;
  try {
    dispatch(getSeedlingsRequest())
    dispatch(setSkip(params.skip ? params.skip + LIMIT_SEEDLINGS : LIMIT_SEEDLINGS));
    const data = (await fetchSeedlings(params)).data;
    const newSeedlings = params.skip === 0 ? [...data] : [...seedlings, ...data];
    dispatch(getSeedlings(newSeedlings));
    dispatch(setLastPage(data.length < LIMIT_SEEDLINGS));
    dispatch(getSeedlingsSuccess());
  } catch (error) {
    dispatch(getSeedlingsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getSeedlingsThunk;
