import { AppThunk } from '../../services/types/store';
import { updateCartItems } from '../../store/cart-slice';
import { TDataCartElement, TSeedlingBase } from '../../services/types/data';

const updateCartElementsThunk: AppThunk = () => async (
  dispatch,
  getState,
) => {
  const dataCart = getState().cart.dataCart;
  const cartItems = getState().cart.cartItems;
  const discount = (100 - (getState().user.discount)) * 0.01;
  const isWholesaler = getState().user['is-wholesaler'];

  const updatedCartItems = cartItems
    .filter(item => dataCart.some(dataCartItem => dataCartItem.seedling === item.seedling.id))
    .map(item => {
      const cartItem = dataCart.find(dataCartItem => dataCartItem.seedling === item.seedling.id);
      return cartItem ? { ...item, count: cartItem.count } : item;
    });

  const amount = Math.trunc(updatedCartItems.reduce((
    acc: number,
    item: TDataCartElement<TSeedlingBase>,
  ) => {
    const price = isWholesaler ? item.seedling['wholesale-price'] : item.seedling['retail-price']
    return acc + item.count * price * discount;
  }, 0));

  dispatch(updateCartItems({
    items: updatedCartItems,
    amount,
  }));
};

export default updateCartElementsThunk;