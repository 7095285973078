import { CSSProperties, FC } from 'react';

interface ITextProps {
  text?: string;
  className?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
}

const Text: FC<ITextProps> = ({ text, className, children, style }) => (
  <p className={className} style={style}>
    {text}
    {children}
  </p>
);

export default Text;