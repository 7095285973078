import { 
  useTransition, 
  useState, 
  useCallback, 
  useEffect,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { pathNames, linksNav } from '../../utils/data';
import styles from './navigation.module.css';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import LinkCart from '../../ui/links/link-cart';
import LinkAccount from '../../ui/links/link-account';
import SearchBar from '../search-bar/search-bar';
import Text from '../../ui/text';
import { useSelector } from '../../services/hooks';
import LinkNotification from '../../ui/links/link-notification';
import titleIcon from '../../images/favicon.png';

function Navigation() {
  const { dataCart } = useSelector(state => state.cart);
  const { countNotification } = useSelector(state => state.ws);
  const {
    'is-admin': isAdmin,
    'is-superuser': isSuperUser,
    'is-active': isActive,
  } = useSelector(state => state.user);
  const [inputValue, setInputValue] = useState('');
  const [, startTransition] = useTransition();
  const [visible, setVisible] = useState(false);
  const [classButton, setClassButton] = useState(styles.hide);
  const [borderColor, setBorderColor] = useState('var(--color-background)');
  const navigate = useNavigate();

  const onClickSearchElements = useCallback(
    () => {
      inputValue && navigate(`/seedlings/search/${inputValue}`);
    },
    [navigate, inputValue]
  );

  const onClickSearch = () => {
    if (!visible) {
      setClassButton(styles.button);
      startTransition(() => {
        setBorderColor('var(--color-border)');
        setVisible(true);
      })
    } else {
      onClickSearchElements();
    }
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const onClickClose = () => {
    setBorderColor('var(--color-background)');
    setTimeout(() => {
      setClassButton(styles.button_hide);
      setInputValue('');
      setVisible(false);
    }, 300)
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onClickSearchElements();
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [inputValue, onClickSearchElements]);

  return (
    <nav className={styles.nav}>
      <Link to={pathNames.main} className={cn(styles.link)}>
        <Text text={linksNav.title} className={cn(styles.title, visible ? styles.hide : '')}/>
        <img src={titleIcon} alt='Иконка' className={cn(styles.image, visible ? styles.image_hide : '')} />
      </Link>
      <List className={styles.list}>
        <ListItem className={styles.hide}>
          <Link to={pathNames.seedlings} className={visible ? styles.link_hide : styles.link}>
            {linksNav.seedlings}
          </Link>
        </ListItem>
        <ListItem className={styles.hide}>
          <Link to={pathNames.about} className={visible ? styles.link_hide : styles.link}>
            {linksNav.about}
          </Link>
        </ListItem>
        <ListItem className={styles.hide}>
          <Link to={pathNames.contacts} className={visible ? styles.link_hide : styles.link}>
            {linksNav.contacts}
          </Link>
        </ListItem>
        <ListItem>
          <SearchBar 
            inputValue={inputValue}
            onChangeInput={onChangeInputValue}
            onClickClose={onClickClose} 
            onClickSearch={onClickSearch}
            visible={visible}
            style={{
              borderColor: borderColor
            }}
            classButton={classButton}
          />
        </ListItem>
        <ListItem>
          <Link to={pathNames.cart} className={styles.link}>
            <LinkCart count={dataCart.length} />
          </Link>
        </ListItem>
        {isActive && <ListItem>
          <Link
            to={isAdmin || isSuperUser ? pathNames.adminNotification : pathNames.profileNotification}
            className={styles.link}
          >
            <LinkNotification count={countNotification} />
          </Link>
        </ListItem>}
        <ListItem>
          <Link to={pathNames.profileOrders} className={visible ? styles.hide : styles.link}>
            <LinkAccount auth={isActive} />
          </Link>
        </ListItem>
      </List>
    </nav>
  )
};

export default Navigation;