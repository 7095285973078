import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteDeliveryEventsFailed,
  deleteDeliveryEventsRequest,
  deleteDeliveryEventsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteDeliveryEvents } from '../../services/api/api-delivery-events';

const deleteDeliveryEventThunk: AppThunk = (
  eventId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteDeliveryEventsRequest());
    await fetchDeleteDeliveryEvents(eventId, token);
    dispatch(deleteDeliveryEventsSuccess());
  } catch (error) {
    dispatch(deleteDeliveryEventsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteDeliveryEventThunk;