import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { 
  getDeliveryDatesFailed,
  getDeliveryDatesRequest,
  getDeliveryDatesSuccess,
} from '../../store/api-slice';
import { getDeliveryDates } from '../../store/main-slice';
import { fetchDeliveryDates } from '../../services/api/api-delivery-dates';
import { setLastPage, setSkip } from '../../store/api-admin-slice';
import { LIMIT_DELIVERY_DATES } from '../../utils/constants';
import { getDeliveryDatesAll } from '../../store/admin-slice';

const getDeliveryDatesThunk: AppThunk = (
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const dates = getState().admin.deliveryDatesAll;
  try {
    dispatch(getDeliveryDatesRequest());
    if (params) {
      dispatch(setSkip(params.skip ? params.skip + LIMIT_DELIVERY_DATES : LIMIT_DELIVERY_DATES));
      const data = (await fetchDeliveryDates(params)).data;
      const newDates = params.skip === 0 ? [...data] : [...dates, ...data];
      dispatch(getDeliveryDatesAll(newDates));
      dispatch(setLastPage(data.length < LIMIT_DELIVERY_DATES));

    } else {
      const data = (await fetchDeliveryDates()).data;
      dispatch(getDeliveryDates(data));
    }
    dispatch(getDeliveryDatesSuccess());
  } catch (error) {
    dispatch(getDeliveryDatesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getDeliveryDatesThunk;