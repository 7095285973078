import {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Button from '../../ui/buttons/button';
import List from '../../ui/list';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import { Loader } from '../../ui/loader/loader';
import CartItem from '../../components/cart-item/cart-item';
import mainStyles from '../main/main.module.css';
import styles from './cart.module.css';
import setCartElementsThunk from '../../thunks/carts/set-cart-elements';
import clearCartThunk from '../../thunks/carts/clear-cart';
import {
  dateFormatter,
  filterEmptyStrings,
  priceFormat
} from '../../utils/functions';
import cn from 'classnames';
import {
  TDeliveryPoint,
  TDeliveryType,
  TFormOrder
} from '../../services/types/data';
import { useFormOrder } from '../../utils/validation';
import postOrderThunk from '../../thunks/orders/post-order';
import Modal from '../../components/modal/modal';
import { Link, useNavigate } from 'react-router-dom';
import FormOrder from '../../components/form-order/form-order';
import getDeliveryChooseDateThunk from '../../thunks/delivery-events/delivery-events-date';
import { TAPIObjectError } from '../../services/types/api';
import getOrdersAllowedThunk from '../../thunks/orders/orders-allowed';
import updateCartElementsThunk from '../../thunks/carts/update-cart-elements';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav, pathNames } from '../../utils/data';

export function CartPage() {
  const { cartItems, dataCart, amount } = useSelector(state => state.cart);
  const {
    deliveryTypes,
    deliveryPoints,
    deliveryChooseDates,
    order,
    ordersAllowed,
  } = useSelector(state => state.main);
  const {
    myCartSuccess,
    postOrderSuccess,
    postOrderRequest,
    errorObject,
  } = useSelector(state => state.api);
  const user = useSelector(state => state.user);
  const {
    'first-name': firstName,
    'last-name': lastName,
    email,
    'phone-number': phoneNumber,
    'is-wholesaler': isWholesaler,
    'is-active': isActive,
    discount,
  } = user;
  const deliveryTypesShow: Array<TDeliveryType> | null = deliveryTypes?.filter(type => type.show) || null;
  const deliveryPointsShow: Array<TDeliveryPoint> | null = deliveryPoints?.filter(point => point.show) || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deliveryPointsFilter, setDeliveryPointsFilter] = useState(deliveryPointsShow)
  const [visible, setVisible] = useState({
    form: false,
    modal: false,
  });
  const dataOrderInit: TFormOrder = {
    id: '',
    items: dataCart.map(el => ({
      'seedling-id': el.seedling,
      count: el.count,
    })),
    email: email || '',
    'first-name': firstName || '',
    'last-name': lastName || '',
    'phone-number': phoneNumber || '',
    'delivery-type': deliveryTypesShow?.length ? deliveryTypesShow[0].type : '',
    'delivery-point': '',
    'delivery-date': null,
    'delivery-comment': '',
  };

  const [modalText, setModalText] = useState('');

  const [dataOrder, setDataOrder] = useState<TFormOrder>(dataOrderInit);
  const { handleChange, isValid } = useFormOrder(dataOrder, setDataOrder,
    dataOrder.email &&
    dataOrder['first-name'] &&
    dataOrder['last-name'] &&
    dataOrder['phone-number'] ?
    true : false
  );

  useEffect(() => {
    if (postOrderSuccess) {
      const nonBreakingSpace = '\u00A0';
      const text = `Номер заказа: №${nonBreakingSpace}${order?.number}\nот${nonBreakingSpace}${
        order && dateFormatter(new Date(order['created-at']))
      }`;
      setModalText(text);
      setVisible({
        form: false,
        modal: true,
      });
    }
    if (!ordersAllowed) {
      setVisible(prevVisible => ({
        ...prevVisible,
        form: false,
      }))
    }
  }, [postOrderSuccess, ordersAllowed, order]);

  useEffect(() => {
    const textError = errorObject?.detail?.[0] as TAPIObjectError;
    if (errorObject?.statusCode === 409 && textError.msg === 'The creation of new orders is prohibited') {
      dispatch(getOrdersAllowedThunk());
    }
    if (errorObject?.statusCode === 422 && textError.msg === 'The delivery date is required') {
      setModalText('Нет доступных дат для выбранной точки доставки. Выберите другую точку доставки');
      setVisible(prevVisible => ({
        ...prevVisible,
        modal: true,
      }));
    }
    if (errorObject?.statusCode === 422 && textError.msg === 'Phone number invalid') {
      setModalText('Введите корректный номер телефона');
      setVisible(prevVisible => ({
        ...prevVisible,
        modal: true,
      }));
    }
    if (errorObject?.statusCode === 422 && textError.msg === 'value is not a valid email address') {
      setModalText('Укажите корректную почту');
      setVisible(prevVisible => ({
        ...prevVisible,
        modal: true,
      }));
    }
  }, [dispatch, errorObject]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setVisible({
      form: false,
      modal: false,
    });
  }, []);

  const [inizialized, setInizialized] = useState(false);

  useEffect(() => {
    !inizialized && dispatch(setCartElementsThunk());
    dataCart.length && setInizialized(true);
  }, [dataCart, dispatch, inizialized]);

  useEffect(() => {
    dispatch(updateCartElementsThunk());
  }, [dispatch, dataCart]);

  const dataOrderType = dataOrder['delivery-type'];

  const deliveryTypeId = deliveryPointsShow && deliveryTypesShow?.find(type => type.type === dataOrderType)?.id;
  const currentPointsFilter = useMemo(
    () => deliveryPointsShow?.filter(point => point['delivery-type-id'] === deliveryTypeId),
    [deliveryPointsShow, deliveryTypeId]
  );
  const pointShow = currentPointsFilter?.length ? currentPointsFilter[0].point : '';

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    deliveryTypeId && setToggle(true);
  }, [deliveryTypeId]);

  useEffect(() => {
    if (deliveryTypeId && toggle) {
      currentPointsFilter && setDeliveryPointsFilter(currentPointsFilter);
      setDataOrder(prevData => ({
        ...prevData,
        'delivery-point': pointShow,
      }));
      setToggle(false);
    }
  }, [dataOrderType, deliveryTypeId, pointShow, toggle, currentPointsFilter]);

  const dataOrderPoint = dataOrder['delivery-point'];
  const deliveryPointId = deliveryPointsShow?.find(point => point.point === dataOrderPoint)?.id;

  useEffect(() => {
    if (deliveryPointId) {
      dispatch(getDeliveryChooseDateThunk(deliveryPointId));
    }
  }, [dataOrderPoint, dispatch, deliveryPointId]);

  useEffect(() => {
    if (deliveryChooseDates) {
      setDataOrder(prevData => ({
        ...prevData,
        'delivery-date': deliveryChooseDates.length > 0 ? deliveryChooseDates[0].date : null,
      }));
    }
  }, [deliveryChooseDates]);

  const onClear = () => {
    dispatch(clearCartThunk());
  };

  const onClickOrder = () => {
    setVisible({
      ...visible,
      form: !visible.form,
    });
    setDataOrder({
      id: '',
      items: dataCart.map(el => ({
        'seedling-id': el.seedling,
        count: el.count,
      })),
      email: email || '',
      'first-name': firstName || '',
      'last-name': lastName || '',
      'phone-number': phoneNumber || '',
      'delivery-type': deliveryTypesShow?.length ? deliveryTypesShow[0].type : '',
      'delivery-point': pointShow,
      'delivery-date': deliveryChooseDates?.find(date => date.date === dataOrder['delivery-date'])?.date || null,
      'delivery-comment': '',
    })
  };

  const handleCancel = () => {
    setVisible({
      ...visible,
      form: false,
    });
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dataPostOrder: TFormOrder = ({
      ...dataOrder,
      'delivery-type': deliveryTypesShow?.find(type => type.type === dataOrder['delivery-type'])?.id || '',
      'delivery-point': deliveryPointsShow?.find(point => point.point === dataOrder['delivery-point'])?.id || '',
      'delivery-date': deliveryChooseDates?.find(date => date.date === dataOrder['delivery-date'])?.id || null,
    })
    const filteredDataOrder = filterEmptyStrings(dataPostOrder);
    dispatch(postOrderThunk(filteredDataOrder));
  };

  const handleCloseModal = () => {
    setVisible({
      ...visible,
      modal: false,
    });
    if (postOrderSuccess) {
      dispatch(clearCartThunk());
      navigate('/');
    }
  };

  const modal = (
    <Modal header={postOrderSuccess ? 'Заказ оформлен' : ''} onClose={handleCloseModal}>
      <Text
        text={modalText}
        className={styles.text_order}
      />
    </Modal>
  );

  const content = useMemo(() => {
    return ((
      isActive
    ) || (
      !isActive && dataCart.length === cartItems.length
    )) && cartItems.map(el => (
      <CartItem
        key={el.seedling.id}
        item={el.seedling}
        count={el.count}
        price={isWholesaler ? el.seedling['wholesale-price'] : el.seedling['retail-price']}
        isActive={isActive}
      />
    ))
  }, [cartItems, dataCart.length, isActive, isWholesaler]);

  if (cartItems.length === 0) {
    return (
      <HelmetProvider>
        <main className={mainStyles.content}>
          <Helmet>
            <title>{`${linksNav.cart} | ${linksNav.title}`}</title>
          </Helmet>
          <Text
            className={styles.text}
            text='Ваша корзина пуста'
          />
          <Link to={pathNames.seedlings} className={cn(styles.text, styles.link)}>Перейти в каталог</Link>
        </main>
      </HelmetProvider>
    )
  }

  if (!myCartSuccess && isActive) {
    return (
      <Loader size='large' />
    )
  }

  return (
    <HelmetProvider>
      <main className={mainStyles.content}>
        <Helmet>
          <title>{`${linksNav.cart} | ${linksNav.title}`}</title>
        </Helmet>
        <section className={styles.container}>
          <Title type='h3' text={linksNav.cart} className={styles.title} />
            <Button clickHandler={onClear} className={cn(styles.button, styles.clear)}>
              Очистить корзину
            </Button>
            <List className={styles.list}>
              {content}
            </List>
            <Text
              text={`Итого к оплате: ${priceFormat(amount)}`}
              className={styles.amount}
            />
            {discount !== 0 && <Text text={`(Скидка ${discount}% применена)`} className={styles.discount} />}
            {ordersAllowed ? (
              <Button clickHandler={onClickOrder} className={cn(styles.button, styles.order)}>
                {visible.form ? 'Отменить оформление' : 'Оформить заказ'}
              </Button>
            ) : (
              <Text
                text='Оформление заказов временно приостановлено'
                className={cn(styles.amount, styles.clear)}
              />
            )}
            {visible.form && (
              <FormOrder
                value={dataOrder}
                handleClickSubmit={onClickSubmit}
                handleChange={handleChange}
                pointsFilter={deliveryPointsFilter}
                handleCancel={handleCancel}
                request={postOrderRequest}
                isValid={isValid}
              />
            )}
        </section>
        {visible.modal && modal}
      </main>
    </HelmetProvider>
  )
}