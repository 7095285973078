import { AxiosRequestConfig } from 'axios';
import { TAPIDataQuestion, TAPIParamsObject } from '../types/api';
import { QUESTIONS_URL } from '../../utils/constants';
import { 
  makeParams,
  blogAPI,
  injectBearerToken,
} from '../helpers/api';
import { 
  IFetchDeleteQuestion, 
  IFetchAnswer, 
  IFetchPostQuestion, 
  IFetchQuestions, 
} from './api.type';

export const fetchPostQuestion: IFetchPostQuestion = (
  data: TAPIDataQuestion,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: QUESTIONS_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchQuestions: IFetchQuestions = (
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: QUESTIONS_URL,
    params: makeParams(id, skip, limit, category, search, unanswered),
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchPostAnswer: IFetchAnswer = (
  questionId: string,
  answer: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${QUESTIONS_URL}/${questionId}/answer`,
    method: 'POST',
    data: {
      answer,
    }
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchAnswer: IFetchAnswer = (
  questionId: string,
  answer: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${QUESTIONS_URL}/${questionId}`,
    method: 'PATCH',
    data: {
      answer,
    }
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteQuestion: IFetchDeleteQuestion = (
  questionId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${QUESTIONS_URL}/${questionId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};



