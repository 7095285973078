import { AppThunk } from '../../services/types/store';
import { 
  TAPIDataDeliveryEvent,
  TAPIError 
} from '../../services/types/api';
import { 
  postDeliveryEventsFailed,
  postDeliveryEventsRequest,
  postDeliveryEventsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPostDeliveryEvents } from '../../services/api/api-delivery-events';

const postDeliveryEventThunk: AppThunk = (
  data: TAPIDataDeliveryEvent,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postDeliveryEventsRequest());
    await fetchPostDeliveryEvents(data, token);
    dispatch(postDeliveryEventsSuccess());
  } catch (error) {
    dispatch(postDeliveryEventsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postDeliveryEventThunk;