import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAPIError } from '../services/types/api';

type TAPIState = {
  readonly errorObject: TAPIError | null,
  readonly swiperRequest: boolean;
  readonly swiperFailed: boolean;
  readonly categoriesRequest: boolean;
  readonly categoriesFailed: boolean;
  readonly seedlingsRequest: boolean;
  readonly seedlingsSuccess: boolean;
  readonly seedlingsFailed: boolean;
  readonly seedlingRequest: boolean;
  readonly seedlingSuccess: boolean;
  readonly seedlingFailed: boolean;
  readonly skip: number;
  readonly lastPage: boolean;
  readonly signupRequest: boolean;
  readonly signupSuccess: boolean;
  readonly signupFailed: boolean;
  readonly loginRequest: boolean;
  readonly loginFailed: boolean;
  readonly usersMeRequest: boolean;
  readonly usersMeSuccess: boolean;
  readonly usersMeFailed: boolean;
  readonly tokenRequest: boolean;
  readonly tokenFailed: boolean;
  readonly logoutRequest: boolean;
  readonly logoutFailed: boolean;
  readonly changePasswordRequest: boolean;
  readonly changePasswordSuccess: boolean;
  readonly changePasswordFailed: boolean;
  readonly updateUserRequest: boolean;
  readonly updateUserSuccess: boolean;
  readonly updateUserFailed: boolean;
  readonly myCartRequest: boolean;
  readonly myCartSuccess: boolean;
  readonly myCartFailed: boolean;
  readonly updateMyCartRequest: boolean;
  readonly updateMyCartSuccess: boolean;
  readonly updateMyCartFailed: boolean;
  readonly postQuestionRequest: boolean;
  readonly postQuestionSuccess: boolean;
  readonly postQuestionFailed: boolean;
  readonly forgotPasswordRequest: boolean;
  readonly forgotPasswordSuccess: boolean;
  readonly forgotPasswordFailed: boolean;
  readonly deliveryTypesRequest: boolean;
  readonly deliveryTypesFailed: boolean;
  readonly deliveryDatesRequest: boolean;
  readonly deliveryDatesFailed: boolean;
  readonly deliveryPointsRequest: boolean;
  readonly deliveryPointsFailed: boolean;
  readonly postScoreRequest: boolean;
  readonly postScoreSuccess: boolean;
  readonly postScoreFailed: boolean;
  readonly postOrderRequest: boolean;
  readonly postOrderSuccess: boolean;
  readonly postOrderFailed: boolean;
  readonly notificationsRequest: boolean;
  readonly notificationsFailed: boolean;
  readonly ordersRequest: boolean;
  readonly ordersFailed: boolean;
  readonly orderRequest: boolean;
  readonly orderSuccess: boolean;
  readonly orderFailed: boolean;
  readonly patchOrderRequest: boolean;
  readonly patchOrderSuccess: boolean;
  readonly patchOrderFailed: boolean;
  readonly deliveryChooseDatesRequest: boolean;
  readonly deliveryChooseDatesFailed: boolean;
  readonly cancelOrderRequest: boolean;
  readonly cancelOrderSuccess: boolean;
  readonly cancelOrderFailed: boolean;
  readonly createStatementRequest: boolean;
  readonly createStatementSuccess: boolean;
  readonly createStatementFailed: boolean;
  readonly readNotificationsRequest: boolean;
  readonly readNotificationsSuccess: boolean;  
  readonly readNotificationsFailed: boolean;
  readonly getOrdersAllowedRequest: boolean;
  readonly getOrdersAllowedFailed: boolean;
  readonly getOrdersChangeAllowedRequest: boolean;
  readonly getOrdersChangeAllowedSuccess: boolean;
  readonly getOrdersChangeAllowedFailed: boolean;
  readonly activationRequest: boolean;
  readonly activationSuccess: boolean;
  readonly activationFailed: boolean;
};

const initialState: TAPIState = {
  errorObject: null,
  swiperRequest: false,
  swiperFailed: false,
  categoriesRequest: false,
  categoriesFailed: false,
  seedlingsRequest: false,
  seedlingsSuccess: false,
  seedlingsFailed: false,
  seedlingRequest: false,
  seedlingSuccess: false,
  seedlingFailed: false,
  skip: 0,
  lastPage: true,
  signupRequest: false,
  signupSuccess: false,
  signupFailed: false,
  loginRequest: false,
  loginFailed: false,
  usersMeRequest: false,
  usersMeSuccess: false,
  usersMeFailed: false,
  tokenRequest: false,
  tokenFailed: false,
  logoutRequest: false,
  logoutFailed: false,
  changePasswordRequest: false,
  changePasswordSuccess: false,
  changePasswordFailed: false,
  updateUserRequest: false,
  updateUserSuccess: false,
  updateUserFailed: false,
  myCartRequest: false,
  myCartSuccess: false,
  myCartFailed: false,
  updateMyCartRequest: false,
  updateMyCartSuccess: false,
  updateMyCartFailed: false,
  postQuestionRequest: false,
  postQuestionSuccess: false,
  postQuestionFailed: false,
  forgotPasswordRequest: false,
  forgotPasswordSuccess: false,
  forgotPasswordFailed: false,
  deliveryTypesRequest: false,
  deliveryTypesFailed: false,
  deliveryDatesRequest: false,
  deliveryDatesFailed: false,
  deliveryPointsRequest: false,
  deliveryPointsFailed: false,
  postScoreRequest: false,
  postScoreSuccess: false,
  postScoreFailed: false,
  postOrderRequest: false,
  postOrderSuccess: false,
  postOrderFailed: false,
  notificationsRequest: false,
  notificationsFailed: false,
  ordersRequest: false,
  ordersFailed: false,
  orderRequest: false,
  orderSuccess: false,
  orderFailed: false,
  patchOrderRequest: false,
  patchOrderSuccess: false,
  patchOrderFailed: false,
  deliveryChooseDatesRequest: false,
  deliveryChooseDatesFailed: false,
  cancelOrderRequest: false,
  cancelOrderSuccess: false,
  cancelOrderFailed: false,
  createStatementRequest: false,
  createStatementSuccess: false,
  createStatementFailed: false,
  readNotificationsRequest: false,
  readNotificationsSuccess: false,
  readNotificationsFailed: false,
  getOrdersAllowedRequest: false,
  getOrdersAllowedFailed: false,
  getOrdersChangeAllowedRequest: false,
  getOrdersChangeAllowedSuccess: false,
  getOrdersChangeAllowedFailed: false,
  activationRequest: false,
  activationSuccess: false,
  activationFailed: false,
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    getSwiperRequest: state => ({
      ...state, swiperRequest: true,
    }),
    getSwiperSuccess: state => ({
      ...state, 
      swiperRequest: false,
      swiperFailed: false,
    }),
    getSwiperFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      swiperFailed: true,
      swiperRequest: false,
      errorObject: action.payload,
    }),
    getCategoriesRequest: state => ({
      ...state, categoriesRequest: true,
    }),
    getCategoriesSuccess: state => ({
      ...state, 
      categoriesRequest: false,
      categoriesFailed: false,
    }),
    getCategoriesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      categoriesFailed: true,
      categoriesRequest: false,
      errorObject: action.payload,
    }),
    getSeedlingsRequest: state => ({
      ...state, 
      seedlingsRequest: true,
      seedlingsSuccess: false,
    }),
    getSeedlingsSuccess: state => ({
      ...state, 
      seedlingsRequest: false,
      seedlingsFailed: false,
      seedlingsSuccess: true,
    }),
    getSeedlingsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      seedlingsFailed: true,
      seedlingsRequest: false,
      seedlingsSuccess: false,
      errorObject: action.payload,
    }),
    getSeedlingRequest: state => ({
      ...state, 
      seedlingRequest: true,
      seedlingSuccess: false,
      postQuestionSuccess: false,
      postScoreSuccess: false,
    }),
    getSeedlingSuccess: state => ({
      ...state, 
      seedlingRequest: false,
      seedlingSuccess: true,
      seedlingFailed: false,
    }),
    getSeedlingFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      seedlingFailed: true,
      seedlingRequest: false,
      seedlingSuccess: false,
      errorObject: action.payload,
    }),
    setSkip: (state, action: PayloadAction<number>) => ({
      ...state,
      skip: action.payload,
    }),
    setLastPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      lastPage: action.payload,
    }),
    signupRequest: state => ({
      ...state,
      signupRequest: true,
      signupSuccess: false,
    }),
    signupSuccess: state => ({
      ...state,
      signupRequest: false,
      registerFailed: false,
      signupSuccess: true,
    }),
    signupFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      signupFailed: true,
      signupRequest: false,
      signupSuccess: false,
      errorObject: action.payload,
    }),
    loginRequest: state => ({
      ...state,
      loginRequest: true,
    }),
    loginSuccess: state => ({
      ...state,
      loginRequest: false,
      loginFailed: false,
    }),
    loginFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      loginFailed: true,
      loginRequest: false,
      errorObject: action.payload,
    }),
    getUsersMeRequest: state => ({
      ...state,
      usersMeRequest: true,
    }),
    getUsersMeSuccess: state => ({
      ...state,
      usersMeRequest: false,
      usersMeFailed: false,
      usersMeSuccess: true,
    }),
    getUsersMeFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      usersMeFailed: true,
      usersMeRequest: false,
      usersMeSuccess: false,
      errorObject: action.payload,
    }),
    updateTokenRequest: state => ({
      ...state,
      tokenRequest: true,
    }),
    updateTokenSuccess: state => ({
      ...state,
      tokenRequest: false,
      tokenFailed: false,
    }),
    updateTokenFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      tokenFailed: true,
      tokenRequest: false,
      errorObject: action.payload,
    }),
    logoutRequest: state => ({
      ...state,
      logoutRequest: true,
    }),
    logoutSuccess: state => ({
      ...state,
      logoutRequest: false,
      logoutFailed: false,
    }),
    logoutFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      logoutFailed: true,
      logoutRequest: false,
      errorObject: action.payload,
    }),
    changePasswordRequest: state => ({
      ...state,
      changePasswordRequest: true,
      changePasswordSuccess: false,
    }),
    changePasswordSuccess: state => ({
      ...state,
      changePasswordRequest: false,
      changePasswordSuccess: true,
      changePasswordFailed: false,
    }),
    changePasswordFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      changePasswordRequest: false,
      changePasswordSuccess: false,
      changePasswordFailed: true,
      errorObject: action.payload,
    }),
    updateUserRequest: state => ({
      ...state,
      updateUserRequest: true,
      updateUserSuccess: false,
    }),
    updateUserSuccess: state => ({
      ...state,
      updateUserRequest: false,
      updateUserSuccess: true,
      updateUserFailed: false,
    }),
    updateUserFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      updateUserRequest: false,
      updateUserSuccess: false,
      updateUserFailed: true,
      errorObject: action.payload,
    }),
    getMyCartRequest: state => ({
      ...state,
      myCartRequest: true,
    }),
    getMyCartSuccess: state => ({
      ...state,
      myCartRequest: false,
      myCartSuccess: true,
      myCartFailed: false,
    }),
    getMyCartFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      myCartFailed: true,
      myCartRequest: false,
      myCartSuccess: false,
      errorObject: action.payload,
    }),
    updateMyCartRequest: state => ({
      ...state,
      updateMyCartRequest: true,
      updateMyCartSuccess: false,
    }),
    updateMyCartSuccess: state => ({
      ...state,
      updateMyCartRequest: false,
      updateMyCartSuccess: true,
      updateMyCartFailed: false,
    }),
    updateMyCartFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      updateMyCartFailed: true,
      updateMyCartRequest: false,
      updateMyCartSuccess: false,
      errorObject: action.payload,
    }),
    postQuestionRequest: state => ({
      ...state,
      postQuestionRequest: true,
      postQuestionSuccess: false,
    }),
    postQuestionSuccess: state => ({
      ...state,
      postQuestionRequest: false,
      postQuestionSuccess: true,
      postQuestionFailed: false,
    }),
    postQuestionFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      postQuestionRequest: false,
      postQuestionSuccess: false,
      postQuestionFailed: true,
      errorObject: action.payload,
    }),
    forgotPasswordRequest: state => ({
      ...state,
      forgotPasswordRequest: true,
      forgotPasswordSuccess: false,
    }),
    forgotPasswordSuccess: state => ({
      ...state,
      forgotPasswordRequest: false,
      forgotPasswordSuccess: true,
      forgotPasswordFailed: false,
    }),
    forgotPasswordFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      forgotPasswordRequest: false,
      forgotPasswordSuccess: false,
      forgotPasswordFailed: true,
      errorObject: action.payload,
    }),
    getDeliveryTypesRequest: state => ({
      ...state,
      deliveryTypesRequest: true,
    }),
    getDeliveryTypesSuccess: state => ({
      ...state, 
      deliveryTypesRequest: false,
      deliveryTypesFailed: false,
    }),
    getDeliveryTypesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryTypesFailed: true,
      deliveryTypesRequest: false,
      errorObject: action.payload,
    }),
    getDeliveryDatesRequest: state => ({
      ...state,
      deliveryDatesRequest: true,
    }),
    getDeliveryDatesSuccess: state => ({
      ...state, 
      deliveryDatesRequest: false,
      deliveryDatesFailed: false,
    }),
    getDeliveryDatesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryDatesFailed: true,
      deliveryDatesRequest: false,
      errorObject: action.payload,
    }),
    getDeliveryPointsRequest: state => ({
      ...state,
      deliveryPointsRequest: true,
    }),
    getDeliveryPointsSuccess: state => ({
      ...state, 
      deliveryPointsRequest: false,
      deliveryPointsFailed: false,
    }),
    getDeliveryPointsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryPointsFailed: true,
      deliveryPointsRequest: false,
      errorObject: action.payload,
    }),
    postScoreRequest: state => ({
      ...state,
      postScoreRequest: true,
      postScoreSuccess: false,
    }),
    postScoreSuccess: state => ({
      ...state,
      postScoreRequest: false,
      postScoreSuccess: true,
      postScoreFailed: false,
    }),
    postScoreFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      postScoreRequest: false,
      postScoreSuccess: false,
      postScoreFailed: true,
      errorObject: action.payload,
    }),
    postOrderRequest: state => ({
      ...state,
      postOrderRequest: true,
      postOrderSuccess: false,
    }),
    postOrderSuccess: state => ({
      ...state,
      postOrderRequest: false,
      postOrderSuccess: true,
      postOrderFailed: false,
    }),
    postOrderFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      postOrderRequest: false,
      postOrderSuccess: false,
      postOrderFailed: true,
      errorObject: action.payload,
    }),
    getNotificationsRequest: state => ({
      ...state, notificationsRequest: true,
    }),
    getNotificationsSuccess: state => ({
      ...state, 
      notificationsRequest: false,
      notificationsFailed: false,
    }),
    getNotificationsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      notificationsFailed: true,
      notificationsRequest: false,
      errorObject: action.payload,
    }),
    getOrdersRequest: state => ({
      ...state, ordersRequest: true,
    }),
    getOrdersSuccess: state => ({
      ...state, 
      ordersRequest: false,
      ordersFailed: false,
    }),
    getOrdersFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      ordersFailed: true,
      ordersRequest: false,
      errorObject: action.payload,
    }),
    getOrderRequest: state => ({
      ...state, 
      orderRequest: true,
      orderSuccess: false,
    }),
    getOrderSuccess: state => ({
      ...state, 
      orderRequest: false,
      orderSuccess: true,
      orderFailed: false,
    }),
    getOrderFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      orderFailed: true,
      orderRequest: false,
      orderSuccess: false,
      errorObject: action.payload,
    }),
    patchOrderRequest: state => ({
      ...state,
      patchOrderRequest: true,
      patchOrderSuccess: false,
      cancelOrderSuccess: false,
    }),
    patchOrderSuccess: state => ({
      ...state,
      patchOrderRequest: false,
      patchOrderSuccess: true,
      patchOrderFailed: false,
    }),
    patchOrderFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      patchOrderRequest: false,
      patchOrderSuccess: false,
      patchOrderFailed: true,
      errorObject: action.payload,
    }),
    getDeliveryChooseDatesRequest: state => ({
      ...state,
      deliveryChooseDatesRequest: true,
    }),
    getDeliveryChooseDatesSuccess: state => ({
      ...state, 
      deliveryChooseDatesRequest: false,
      deliveryChooseDatesFailed: false,
    }),
    getDeliveryChooseDatesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryChooseDatesFailed: true,
      deliveryChooseDatesRequest: false,
      errorObject: action.payload,
    }),
    cancelOrderRequest: state => ({
      ...state, 
      cancelOrderRequest: true,
      cancelOrderSuccess: false,
      patchOrderSuccess: false,
    }),
    cancelOrderSuccess: state => ({
      ...state, 
      cancelOrderRequest: false,
      cancelOrderSuccess: true,
      cancelOrderFailed: false,
    }),
    cancelOrderFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      cancelOrderFailed: true,
      cancelOrderRequest: false,
      cancelOrderSuccess: false,
      errorObject: action.payload,
    }),
    createStatementRequest: state => ({
      ...state, 
      createStatementRequest: true,
      createStatementSuccess: false,
    }),
    createStatementSuccess: state => ({
      ...state, 
      createStatementRequest: false,
      createStatementSuccess: true,
      createStatementFailed: false,
    }),
    createStatementFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      createStatementFailed: true,
      createStatementSuccess: false,
      createStatementRequest: false,
      errorObject: action.payload,
    }),
    readNotificationsRequest: state => ({
      ...state, 
      readNotificationsRequest: true,
    }),
    readNotificationsSuccess: state => ({
      ...state, 
      readNotificationsRequest: false,
      readNotificationsSuccess: true,
      readNotificationsFailed: false,
    }),
    readNotificationsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      readNotificationsFailed: true,
      readNotificationsSuccess: false,
      readNotificationsRequest: false,
      errorObject: action.payload,
    }),
    getOrdersAllowedRequest: state => ({
      ...state, 
      getOrdersAllowedRequest: true,
    }),
    getOrdersAllowedSuccess: state => ({
      ...state, 
      getOrdersAllowedRequest: false,
      getOrdersAllowedFailed: false,
    }),
    getOrdersAllowedFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      getOrdersAllowedRequest: false,
      getOrdersAllowedFailed: true,
      errorObject: action.payload,
    }),
    getOrdersChangeAllowedRequest: state => ({
      ...state, 
      getOrdersChangeAllowedRequest: true,
      getOrdersChangeAllowedSuccess: false,
    }),
    getOrdersChangeAllowedSuccess: state => ({
      ...state, 
      getOrdersChangeAllowedRequest: false,
      getOrdersChangeAllowedSuccess: true,
      getOrdersChangeAllowedFailed: false,
    }),
    getOrdersChangeAllowedFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      getOrdersChangeAllowedRequest: false,
      getOrdersChangeAllowedSuccess: false,
      getOrdersChangeAllowedFailed: true,
      errorObject: action.payload,
    }),
    activationRequest: state => ({
      ...state,
      activationRequest: true,
      activationSuccess: false,
    }),
    activationSuccess: state => ({
      ...state,
      activationRequest: false,
      activationSuccess: true,
      activationFailed: false,
    }),
    activationFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      activationFailed: true,
      activationSuccess: false,
      activationRequest: false,
      errorObject: action.payload,
    }),
  }
});

const apiReducer = apiSlice.reducer;

export const {
  getSwiperRequest,
  getSwiperSuccess,
  getSwiperFailed,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailed,
  getSeedlingsRequest,
  getSeedlingsSuccess,
  getSeedlingsFailed,
  getSeedlingRequest,
  getSeedlingSuccess,
  getSeedlingFailed,
  setSkip,
  setLastPage,
  signupRequest,
  signupSuccess,
  signupFailed,
  loginRequest,
  loginSuccess,
  loginFailed,
  getUsersMeRequest,
  getUsersMeSuccess,
  getUsersMeFailed,
  updateTokenRequest,
  updateTokenSuccess,
  updateTokenFailed,
  logoutRequest,
  logoutSuccess,
  logoutFailed,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailed,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailed,
  getMyCartRequest,
  getMyCartSuccess,
  getMyCartFailed,
  updateMyCartRequest,
  updateMyCartSuccess,
  updateMyCartFailed,
  postQuestionRequest,
  postQuestionSuccess,
  postQuestionFailed,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  getDeliveryTypesRequest,
  getDeliveryTypesSuccess,
  getDeliveryTypesFailed,
  getDeliveryDatesRequest,
  getDeliveryDatesSuccess,
  getDeliveryDatesFailed,
  getDeliveryPointsRequest,
  getDeliveryPointsSuccess,
  getDeliveryPointsFailed,
  postScoreRequest,
  postScoreSuccess,
  postScoreFailed,
  postOrderRequest,
  postOrderSuccess,
  postOrderFailed,
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailed,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailed,
  getOrderRequest,
  getOrderSuccess,
  getOrderFailed,
  patchOrderRequest,
  patchOrderSuccess,
  patchOrderFailed,
  getDeliveryChooseDatesRequest,
  getDeliveryChooseDatesSuccess,
  getDeliveryChooseDatesFailed,
  cancelOrderRequest,
  cancelOrderSuccess,
  cancelOrderFailed,
  createStatementRequest,
  createStatementSuccess,
  createStatementFailed,
  readNotificationsRequest,
  readNotificationsSuccess,
  readNotificationsFailed,
  getOrdersAllowedRequest,
  getOrdersAllowedSuccess,
  getOrdersAllowedFailed,
  getOrdersChangeAllowedRequest,
  getOrdersChangeAllowedSuccess,
  getOrdersChangeAllowedFailed,
  activationRequest,
  activationSuccess,
  activationFailed,
} = apiSlice.actions;

export default apiReducer;
