import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  createStatementFailed,
  createStatementRequest,
  createStatementSuccess,
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { fetchCreateStatement } from '../../services/api/api-orders';

const createStatementThunk: AppThunk = (
  listId: Array<string>,
  token: string,
) => async (dispatch) => {
  try {
    dispatch(createStatementRequest());
    const response = await fetchCreateStatement(listId, token);
    const dispositionHeader = response.headers['content-disposition'];
    const fileName = dispositionHeader.split('filename=').at(-1);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    dispatch(createStatementSuccess());
  } catch (error) {
    dispatch(createStatementFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default createStatementThunk;