import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  logoutRequest,
  logoutSuccess,
  logoutFailed,
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { client, deleteCookie } from '../../services/helpers/api';
import { clearUser } from '../../store/user-slice';
import { TOKEN } from '../../utils/constants';
import { logout } from '../../services/api/api-auth';
import { clearDataCart } from '../../store/cart-slice';

const logoutThunk: AppThunk = (
  token: string,
) => async (dispatch, getState) => {
  try {
    const email = getState().user.email;
    const clientId = email ? client.get(email.toLowerCase()) : undefined;
    dispatch(logoutRequest());
    await logout(token, clientId);
    dispatch(logoutSuccess());
    dispatch(clearUser());
    dispatch(clearDataCart());
    deleteCookie(TOKEN);
  } catch (error) {
    dispatch(logoutFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default logoutThunk;
