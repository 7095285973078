import { AppThunk } from '../../services/types/store';
import { TAPIDataDeliveryDate, TAPIError } from '../../services/types/api';
import { 
  postDeliveryDatesFailed,
  postDeliveryDatesRequest,
  postDeliveryDatesSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPostDeliveryDates } from '../../services/api/api-delivery-dates';

const postDeliveryDateThunk: AppThunk = (
  data: TAPIDataDeliveryDate,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postDeliveryDatesRequest());
    await fetchPostDeliveryDates(data, token);
    dispatch(postDeliveryDatesSuccess());
  } catch (error) {
    dispatch(postDeliveryDatesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postDeliveryDateThunk;