import { AppThunk } from '../../services/types/store';
import { fetchPostQuestion } from '../../services/api/api-questions';
import { TAPIDataQuestion, TAPIError } from '../../services/types/api';
import { 
  postQuestionFailed, 
  postQuestionRequest, 
  postQuestionSuccess 
} from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';

const postQuestionThunk: AppThunk = (
  data: TAPIDataQuestion,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postQuestionRequest());
    await fetchPostQuestion(data, token);
    dispatch(postQuestionSuccess());
  } catch (error) {
    dispatch(postQuestionFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postQuestionThunk;