import { AxiosRequestConfig } from 'axios';
import { 
  TAPICategory,
  TAPIParamsObject,
  TAPISeedlingFull, 
} from '../types/api';
import { CATEGORIES_URL, SEEDLINGS_URL } from '../../utils/constants';
import { 
  makeParams,
  blogAPI,
  injectBearerToken,
} from '../helpers/api';
import { 
  IFetchCategories, 
  IFetchSeedling, 
  IFetchSeedlings, 
  IFetchPostCategory,
  IFetchDeleteCategory,
  IFetchPatchCategory,
  IFetchPostSeedling,
  IFetchDeleteSeedling,
  IFetchPatchSeedling
} from './api.type';

export const fetchCategories: IFetchCategories = (
  queryParams?: TAPIParamsObject,
) => {
  const { id } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: CATEGORIES_URL,
    params: makeParams(id),
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchSeedlings: IFetchSeedlings = (
  queryParams?: TAPIParamsObject,
) => {
  const {
    skip, limit, category, search, id
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: SEEDLINGS_URL,
    params: makeParams(id, skip, limit, category, search),
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchSeedling: IFetchSeedling = (
  id: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${SEEDLINGS_URL}/${id}`,
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchPostCategory: IFetchPostCategory = (
  data: TAPICategory,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: CATEGORIES_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteCategory: IFetchDeleteCategory = (
  categoryId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${CATEGORIES_URL}/${categoryId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchCategory: IFetchPatchCategory = (
  data: TAPICategory,
  categoryId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${CATEGORIES_URL}/${categoryId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPostSeedling: IFetchPostSeedling = (
  data: TAPISeedlingFull,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: SEEDLINGS_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteSeedling: IFetchDeleteSeedling = (
  seedlingId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${SEEDLINGS_URL}/${seedlingId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchSeedling: IFetchPatchSeedling = (
  data: TAPISeedlingFull,
  seedlingId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${SEEDLINGS_URL}/${seedlingId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
