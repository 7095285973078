import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getUsersFailed,
  getUsersRequest,
  getUsersSuccess,
  setLastPage,
  setSkip,
} from '../../store/api-admin-slice';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { LIMIT_USERS } from '../../utils/constants';
import { getUsers } from '../../store/admin-slice';
import { fetchUsers } from '../../services/api/api-users';

const getUsersThunk: AppThunk = (
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const users = getState().admin.users;
  const token = getState().user.token || '';
  try {
    dispatch(getUsersRequest());
    dispatch(setSkip(params.skip ? params.skip + LIMIT_USERS : LIMIT_USERS));
    const data = (await fetchUsers(token, params)).data;
    const newUsers = params.skip === 0 ? [...data] : [...users, ...data];
    dispatch(getUsers(newUsers));
    dispatch(setLastPage(data.length < LIMIT_USERS));
    dispatch(getUsersSuccess());
  } catch (error) {
    dispatch(getUsersFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getUsersThunk;