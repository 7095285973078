import React, { forwardRef } from 'react';
import styles from './input.module.css';
import cn from 'classnames';
import { TRefInput } from '../../services/types/data';

interface IInputProps {
  type?: string;
  placeholder?: string;
  inputWithBtn?: string;
  extraClass?: string,
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  label?: string;
  classLabel?: string;
  max?: number | string;
  min?: number;
}

const Input =  forwardRef<TRefInput, IInputProps>(({
  type = 'text',
  placeholder,
  inputWithBtn,
  extraClass,
  value,
  onChange,
  minLength,
  maxLength,
  readOnly,
  autoFocus,
  label,
  classLabel,
  max,
  name,
  min,
  ...props
}, ref) => {
  const className = cn(styles.input, extraClass, inputWithBtn && styles.input_withBtn);

  if (readOnly && !label) return (
    <input
      type={type}
      className={className}
      placeholder={placeholder}
      ref={ref}
      defaultValue={value}
      readOnly
      {...props}
    />
  );

  if (classLabel) return (
    <div className={styles.box}>
      <label className={classLabel}>{label}</label>
      <input
        id={name}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        autoFocus={autoFocus}
        {...props}
      />
    </div>
  )

  if (label) return (
    <label className={cn(styles.label, styles.text, extraClass)}>
      {label}
      <input
        type={type}
        className={className}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        autoFocus={autoFocus}
        name={name}
        max={max}
        min={min}
        readOnly={readOnly}
        {...props}
      />
    </label>
  )

  return (
    <input
      type={type}
      name={name}
      className={className}
      placeholder={placeholder}
      ref={ref}
      value={value}
      onChange={onChange}
      minLength={minLength}
      maxLength={maxLength}
      autoFocus={autoFocus}
      {...props}
    />
  );
});

export default Input;
