import React from 'react';
import { 
  TFormUser,
  TFormSeedling,
  TSlide,
  TCategory,
  TDeliveryType,
  TDeliveryDate,
  TDeliveryPoint,
  TFormDeliveryEvent,
  TFormOrder,
} from '../services/types/data';

const initStateUser: TFormUser = {
  email: '',
  'first-name': '',
  'last-name': '',
  'phone-number': '',
  'postal-code': '',
  region: '',
  city: '',
  location: '',
  password: '',
  'last-password': '',
  'new-password': '',
};

const initStateSeedling: TFormSeedling = {
  title: '',
  'short-description': '',
  'full-description': '',
  'retail-price': 0,
  'wholesale-price': 0,
  'stock': 0,
  'display-order': 0,
  available: true,
  category: 'Яблони',
}

export function useFormWithValidation(dataForm: TFormUser = initStateUser) {
  const [values, setValues] = React.useState<TFormUser>(dataForm);
  const [errors, setErrors] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === 'email') {
      setValues({...values, [name]: value.toLowerCase()});
    }
    else setValues({...values, [name]: value});
    setErrors({...errors, [name]: target.validationMessage });
    setIsValid(target?.closest('form')!.checkValidity());
  };

  const resetForm = React.useCallback(
    (newValues = dataForm, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid, dataForm],
  );

  return { values, handleChange, errors, isValid, resetForm };
};

export function useFormSeedling(dataForm: TFormSeedling = initStateSeedling) {
  const [values, setValues] = React.useState<TFormSeedling>(dataForm);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setValues({...values, [name]: value});
  };

  const resetForm = React.useCallback(
    (newValues = dataForm, newIsValid = false) => {
      setValues(newValues);
    },
    [setValues, dataForm]
  );

  return { values, handleChange, resetForm };
};

export function useFormSwiper(
  dataForm: TSlide,
  setDataForm: React.Dispatch<React.SetStateAction<TSlide>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
  };

  return { handleChange };
};

export function useFormCategory(
  dataForm: TCategory,
  setDataForm: React.Dispatch<React.SetStateAction<TCategory>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
  };

  return { handleChange };
};

export function useFormDeliveryType(
  dataForm: TDeliveryType,
  setDataForm: React.Dispatch<React.SetStateAction<TDeliveryType>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
    setError('');
  };

  return { handleChange };
};

export function useFormDeliveryDate(
  dataForm: TDeliveryDate,
  setDataForm: React.Dispatch<React.SetStateAction<TDeliveryDate>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
    setError('');
  };

  return { handleChange };
};

export function useFormDeliveryPoint(
  dataForm: TDeliveryPoint,
  setDataForm: React.Dispatch<React.SetStateAction<TDeliveryPoint>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
    setError('');
  };

  return { handleChange };
};

export function useFormDeliveryEvent(
  dataForm: TFormDeliveryEvent,
  setDataForm: React.Dispatch<React.SetStateAction<TFormDeliveryEvent>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setDataForm({...dataForm, [name]: value});
    setError('');
  };

  return { handleChange };
};

export function useFormOrder(
  dataForm: TFormOrder,
  setDataForm: React.Dispatch<React.SetStateAction<TFormOrder>>,
  isValidBegin?: boolean,
) {
  const [isValid, setIsValid] = React.useState(isValidBegin || false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (name === 'email' && typeof(value) === 'string') {
      setDataForm({...dataForm, [name]: value.toLowerCase()});
    } else if (name === 'phone-number' && typeof(value) === 'string') {
      setDataForm({...dataForm, [name]: value.replace(/\D/g, '').replace(/^7|8/, '+7').slice(0, 12)})
    } else setDataForm({...dataForm, [name]: value});
    setIsValid(target?.closest('form')!.checkValidity());
  };

  return { handleChange, isValid };
};

