import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useSelector
} from '../../services/hooks';
import List from '../../ui/list';
import CategoriesItem from '../categories-item/categories-item';
import styles from './selection.module.css';
import { TArrSelect } from '../../services/types/data';
import Dropdown from '../dropdown/dropdown';

function Selection() {
  const { categories, seedling } = useSelector(state => state.main);
  const { pathname } = useLocation();
  const [active, setActive] = useState<string | undefined>();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id && pathname.includes('category')) setActive(id);
    else if (id && !pathname.includes('category')) {
      setActive(seedling?.category.id);
    }
    else setActive('0dfg');
  }, [pathname, seedling, id]);
  
  const categoriesData: Array<TArrSelect> = [{
    id: '0dfg',
    title: 'Все категории',
  }];

  categories?.forEach(item => {
    (item.available === true) && categoriesData.push({
      id: item.id,
      title: item.title,
    })
  });

  return (
    <article className={styles.container}>
      <Dropdown active={active} categories={categoriesData} />
      <List className={styles.list}>
        {categoriesData?.map(item => (
          <CategoriesItem 
            key={item.id} 
            title={item.title} 
            isSelect={true} 
            id={item.id}
            active={active}
          />
        ))}
      </List>
    </article>
  )
};

export default Selection;
