import { 
  FC, useEffect, useState,
} from 'react';
import { 
  TSeedling,
} from '../../services/types/data';
import cn from 'classnames';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import { useDispatch, useSelector } from '../../services/hooks';
import ListItem from '../../ui/list-item';
import styles from './card-admin.module.css';
import { priceFormat } from '../../utils/functions';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import ModalDelete from '../modal-delete/modal-delete';
import deleteSeedlingThunk from '../../thunks/products/delete-seedling';
import FormSeedling from '../form-seedling/form-seedling';
import { TAPIObjectError } from '../../services/types/api';

type TCardProps = {
  card: TSeedling;
  seedlingId?: string;
  setSeedlingId?: React.Dispatch<React.SetStateAction<string>>;
}

const CardAdmin: FC<TCardProps> = (({ 
  card,
  seedlingId,
  setSeedlingId,
}) => {
  const {
    id,
    title,
  } = card;
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.main);
  const { deleteSeedlingSuccess, updateSeedlingSuccess, errorObject } = useSelector(state => state.apiAdmin);
  const [visible, setVisible] = useState({
    edit: false,
    delete: false,
  });
  const [file, setFile] = useState<string | null>(null);
  const [itemState, setItemState] = useState({
    opacity: false,
    disable: false,
  });
  const [clickEditId, setClickEditId] = useState('');
  const [clickDeleteId, setClickDeleteId] = useState('');
  const [error, setError] = useState('');
  const [seedlingValue, setSeedlingValue] = useState({
    ...card,
    category: card.category.title
  });

  useEffect(() => {
    if (updateSeedlingSuccess) {
      setVisible({
        edit: false,
        delete: false,
      });
      setError('');
      setItemState(prevState => ({
        ...prevState,
        opacity: true,
      }));
    }
  }, [updateSeedlingSuccess]);

  useEffect(() => {
    if (updateSeedlingSuccess && file) {
      setSeedlingValue(prevValue => ({
        ...prevValue,
        image: file,
      }));
    }
  }, [updateSeedlingSuccess, file]);

  useEffect(() => {
    if (deleteSeedlingSuccess) {
      setVisible({
        edit: false,
        delete: false,
      });
      setError('')
        setItemState({
          opacity: true,
          disable: true,
        });
    }
  }, [deleteSeedlingSuccess]);

  useEffect(() => {
    const textError = errorObject?.detail?.[0] as TAPIObjectError;
    if (errorObject?.statusCode === 422 && textError.msg.includes('already exists')) {
      setError('Саженец с таким названием уже существует');
    }
    if (errorObject?.statusCode === 409 && textError.msg === 'Deleting a used seedling is prohibited') {
      setError('Данный саженец есть в заказах');
    }
  }, [errorObject]);

  useEffect(() => {
    setError('');
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
      setSeedlingValue({...seedlingValue, [name]: value})
  }

  const onClickDelete = () => {
    setError('')
    setVisible({
      ...visible,
      delete: true,
    });
  };

  const onClickEdit = () => {
    setVisible({
      ...visible,
      edit: true,
    });

    setSeedlingId && setSeedlingId(id);
  };

  const confirmDelete = () => {
    dispatch(deleteSeedlingThunk(id));
    setClickDeleteId(id)
  };

  const handleCloseModal = () => {
    setVisible({
      edit: false,
      delete: false,
    });
    setSeedlingValue({
      ...card,
      category: card.category.title
    });
    setError('');

    setClickDeleteId('')
    setClickEditId('')

    if (!itemState.opacity) {
      setClickEditId('');
    }
  };

  const handleChangeImage = (file: string) => {
    setFile(file);
  };

  const classNameContent = itemState.opacity && (clickEditId === id || clickDeleteId === id) ? 
    cn(styles.content, styles.opacity) : 
    styles.content;

  const form = (
    <FormSeedling
      handleChange={handleChange}
      values={seedlingValue}
      categories={categories}
      handleCancel={handleCloseModal}
      id={card.id}
      setClickId={setClickEditId}
      handleChangeImage={handleChangeImage}
      file={file}
      error={error}
    />
  );

  return (
    <ListItem 
      className={visible.edit && seedlingId === id ? styles.form : classNameContent}
    >
      {visible.edit && seedlingId === id ? form : (
        <>
          <img src={seedlingValue.image} alt={title} className={styles.image} />
          <Title type='h4' text={seedlingValue.title} className={styles.title} />
          <Text text={seedlingValue.category} className={styles.category} />
          <Text text={`Розничная цена: ${priceFormat(seedlingValue['retail-price'])}`} />
          <Text text={`Оптовая цена: ${priceFormat(seedlingValue['wholesale-price'])}`} />
          <Text text={`Наличие: ${seedlingValue.stock} шт.`} className={styles.stock} />
          <Text text={`Порядок отображения: ${seedlingValue['display-order']}`} />
          <Text text={seedlingValue.available ? 'Отображается' : 'Не отображается'} />
          {!(itemState.disable && clickDeleteId === id) && (
            <div className={styles.box}>
              <EditButton onClickHandler={onClickEdit} />
              <CloseButton 
                onClose={onClickDelete} 
                size='small'
                del={true}
              />
            </div>
          )}
        </>
      )}
      {visible.delete && (
        <ModalDelete
          header='Подтвердите удаление саженца:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={error ? error : title}
          error={!!error}
        />
      )}
    </ListItem>
  )
});

export default CardAdmin;
