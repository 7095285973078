import { AxiosRequestConfig } from 'axios';
import { TAPIDataCart } from '../types/api';
import { CARTS_URL } from '../../utils/constants';
import { blogAPI, injectBearerToken } from '../helpers/api';
import { IFetchMyCart, IFetchUpdateMyCart } from './api.type';

export const fetchMyCart: IFetchMyCart = (
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${CARTS_URL}/my-cart`,
    method: 'GET',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchUpdateMyCart: IFetchUpdateMyCart = (
  data: TAPIDataCart,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${CARTS_URL}/my-cart`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
