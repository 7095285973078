import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { LIMIT_NOTIFICATIONS } from '../../utils/constants';
import { 
  getNotificationsFailed, 
  getNotificationsRequest, 
  getNotificationsSuccess, 
  setLastPage, 
  setSkip 
} from '../../store/api-slice';
import { fetchNotifications } from '../../services/api/api-notifications';
import { getNotifications } from '../../store/main-slice';

const getNotificationsThunk: AppThunk = (
  token: string,
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const notifications = getState().main.notifications;
  try {
    dispatch(getNotificationsRequest());
    dispatch(setSkip(params.skip ? params.skip + LIMIT_NOTIFICATIONS : LIMIT_NOTIFICATIONS));
    const data = (await fetchNotifications(token, params)).data;
    const newNotifications = params.skip === 0 ? [...data] : [...notifications, ...data];
    dispatch(getNotifications(newNotifications));
    dispatch(setLastPage(data.length < LIMIT_NOTIFICATIONS));
    dispatch(getNotificationsSuccess());

  } catch (error) {
    dispatch(getNotificationsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getNotificationsThunk;