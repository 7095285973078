import { AppThunk } from '../../services/types/store';
import {
  TAPIError, 
  TAPISwiper
} from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  postSwipergFailed, 
  postSwipergRequest,
  postSwipergSuccess
} from '../../store/api-admin-slice';
import { fetchPostSwiper } from '../../services/api/api-swiper';

const postSwiperThunk: AppThunk = (
  data: TAPISwiper,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postSwipergRequest());
    await fetchPostSwiper(data, token);
    dispatch(postSwipergSuccess());
  } catch (error) {
    dispatch(postSwipergFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postSwiperThunk;