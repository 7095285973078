import { AxiosRequestConfig } from 'axios';
import { 
  TAPIDataDeliveryEvent, 
  TAPIParamsObject, 
  TAPIUpdateDeliveryEvent,
} from '../types/api';
import { DELIVERY_EVENTS_URL } from '../../utils/constants';
import { 
  blogAPI,
  injectBearerToken,
  makeParams,
} from '../helpers/api';
import { 
  IFetchDeleteDeliveryEvents,
  IFetchDeliveryChooseDates,
  IFetchDeliveryEvents,
  IFetchPatchDeliveryEvents,
  IFetchPostDeliveryEvents,
} from './api.type';

export const fetchDeliveryEvents: IFetchDeliveryEvents = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_EVENTS_URL,
    params: makeParams(id, skip, limit, category, search, unanswered, all),
    method: 'get',
  }
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPostDeliveryEvents: IFetchPostDeliveryEvents = (
  data: TAPIDataDeliveryEvent,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_EVENTS_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteDeliveryEvents: IFetchDeleteDeliveryEvents = (
  eventId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_EVENTS_URL}/${eventId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchDeliveryEvents: IFetchPatchDeliveryEvents = (
  data: TAPIUpdateDeliveryEvent,
  eventId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_EVENTS_URL}/${eventId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeliveryChooseDates: IFetchDeliveryChooseDates = (
  pointId: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_EVENTS_URL}/choose-date/${pointId}`,
    method: 'get',
  }
  return blogAPI(requestConfig);
};
