import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getCategoriesRequest, 
  getCategoriesSuccess, 
  getSwiperFailed, 
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { getCategories } from '../../store/main-slice';
import { fetchCategories } from '../../services/api/api-products';

const getCategoriesThunk: AppThunk = () => async (dispatch) => {
  try {
    dispatch(getCategoriesRequest());
    const response = await fetchCategories();
    dispatch(getCategories(response.data));
    dispatch(getCategoriesSuccess());
  } catch (error) {
    dispatch(getSwiperFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getCategoriesThunk;
