import { CSSProperties, FC } from 'react';

interface IListItemProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  index?: number;
}

const ListItem: FC<IListItemProps> = ({ 
  children, 
  className, 
  style, 
  onClick,
  index,
}) => (
  <li 
    className={className} 
    style={style}
    onClick={onClick}
    tabIndex={index}
  >
    {children}
  </li>
)

export default ListItem;