import { AxiosRequestConfig } from 'axios';
import { TAPIDataDeliveryType } from '../types/api';
import { DELIVERY_TYPES_URL } from '../../utils/constants';
import { blogAPI,injectBearerToken } from '../helpers/api';
import { 
  IFetchDeleteDeliveryTypes,
  IFetchDeliveryTypes,
  IFetchPatchDeliveryTypes,
  IFetchPostDeliveryTypes,
} from './api.type';

export const fetchDeliveryTypes: IFetchDeliveryTypes = (
  visible?: boolean,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_TYPES_URL,
    params: { visible },
    method: 'get',
  }
  return blogAPI(requestConfig);
};

export const fetchPostDeliveryTypes: IFetchPostDeliveryTypes = (
  data: TAPIDataDeliveryType,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_TYPES_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchDeliveryTypes: IFetchPatchDeliveryTypes = (
  data: TAPIDataDeliveryType,
  typeId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_TYPES_URL}/${typeId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteDeliveryTypes: IFetchDeleteDeliveryTypes = (
  typeId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_TYPES_URL}/${typeId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
