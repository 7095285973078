import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { TAPIDataUser, TAPIError } from '../../services/types/api';
import { AppThunk } from '../../services/types/store';
import { 
  updateUserFailed, 
  updateUserRequest, 
  updateUserSuccess 
} from '../../store/api-slice';
import { setUserData } from '../../store/user-slice';
import { fetchUpdateUser } from '../../services/api/api-users';

const updateUserThunk: AppThunk = (
  data: TAPIDataUser,
  id: string,
  isAdmin?: boolean,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    if (isAdmin) dispatch(updateUserRequest())
    else dispatch(updateUserRequest());
    const response = await fetchUpdateUser(data, token, id);
    if (!isAdmin) dispatch(setUserData(response.data));
    if (isAdmin) dispatch(updateUserSuccess());
    else dispatch(updateUserSuccess());
  } catch (error) {
    dispatch(updateUserFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateUserThunk;
