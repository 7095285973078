import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { TAPIError, TAPIForgotPassword } from '../../services/types/api';
import { AppThunk } from '../../services/types/store';
import { 
  forgotPasswordFailed, 
  forgotPasswordRequest,
  forgotPasswordSuccess
} from '../../store/api-slice';
import { fetchForgotPassword } from '../../services/api/api-auth';

const forgotPasswordThunk: AppThunk = (
  data: TAPIForgotPassword,
  id?: string,
) => async (dispatch) => {
  try {
    dispatch(forgotPasswordRequest());
    await fetchForgotPassword(data, id);
    dispatch(forgotPasswordSuccess());
  } catch (error) {
    dispatch(forgotPasswordFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default forgotPasswordThunk;
