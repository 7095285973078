import { TDeliveryDate, TDeliveryType } from "../services/types/data";

export const pathNames = {
  main: '/',
  seedlings: '/seedlings',
  about: '/about',
  contacts: '/contacts',
  cart: '/cart',
  profile: '/profile',
  profileOrders: '/profile/orders',
  profileNotification: '/profile/notifications',
  adminNotification: '/admin-panel/notifications',
};

export const linksNav = {
  title: 'Питомник Елизарова',
  seedlings: 'Саженцы',
  about: 'О питомнике',
  contacts: 'Контакты',
  cart: 'Корзина',
};

export const title = 'Саженцы плодово-ягодных и декоративных растений на выбор';

export const contacts = {
  logo: 'Питомник Елизарова',
  phone: '+7 (962) 474-30-70',
  email: 'sajenci58@gmail.com',
  address: '442710, Пензенская обл., р.п.Исса',
};

export const deliveryTypeInit: TDeliveryType = {
  id: '',
  type: '',
  description: '',
  show: true,
  'already-use': false,
};

export const deliveryDateInit: TDeliveryDate = {
  id: '',
  date: '',
  comment: '',
  show: true,
  'already-use': false,
};

export const categoryInit = {
  id: '',
  title: '',
  image: '',
  'display-order': 0,
  available: true,
};

export const visibleDeliveryInit = {
  add: false,
  edit: false,
  delete: false,
  date: false,
  point: false,
};

export const slideInit = {
  id: '',
  title: '',
  text: '',
  image: '',
  'display-order': 0,
  available: true,
};

export const textAbout: Array<string> = [
  `Мы занимаемся выращиванием и продажей 
  саженцев уже более 10 лет. Начинали с нескольких сотен штук, а теперь это 
  количество достигает 100 000 деревьев и кустарников. Так же мы самостоятельно 
  выращиваем подвой (дичек, на который прививаются сортовые саженцы), что 
  обеспечивает отличную зимостойкость для нашей полосы. Стремимся разнообразить 
  ассортимент, ежегодно добавляем новые сорта, благодаря этому у нас имеются 
  сорта крупноплодной и малоосыпающейся жимолости хороших вкусовых качеств. 
  С 2022 года есть крупноплодная летняя и ремонтантная малина. Появилось 
  несколько сортов голубики (выращивается только в кислом грунте). Бесшипая 
  ежевика всегда радует обильным урожаем. Все яблони прививаем на подвой 
  54-118 (полукарлик), поэтому наши яблоньки растут невысокие, около 3 м, 
  вступают в плодоношение на второй-третий год, а так же не боятся грунтовых 
  вод, так как корни достигают 1 м.`,
  `Наш питомник находится в Иссе, недалеко от заправки Роснефть. Осенью 
  продаем с питомника, а весной со склада , который находится не далеко 
  от Лукойла, за бывшим постом ГАИ.`,
];

export const linksVideo: Array<string> = [
  'jCspYdbEkAI',
  'CobZyCyjyqE',
  'hTzOUB030Nw',
  'bUajfLI0QIs',
];




