import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAPIError } from '../services/types/api';

type TAPIState = {
  readonly errorObject: TAPIError | null,
  readonly skip: number;
  readonly lastPage: boolean;
  readonly questionsRequest: boolean;
  readonly questionsFailed: boolean;
  readonly answerRequest: boolean;
  readonly answerSuccess: boolean;
  readonly answerFailed: boolean;
  readonly deleteQuestionRequest: boolean;
  readonly deleteQuestionSuccess: boolean;
  readonly deleteQuestionFailed: boolean;
  readonly сategoryRequest: boolean;
  readonly сategorySuccess: boolean;
  readonly сategoryFailed: boolean;
  readonly seedlingRequest: boolean;
  readonly seedlingSuccess: boolean;
  readonly seedlingFailed: boolean;
  readonly deleteSeedlingRequest: boolean;
  readonly deleteSeedlingSuccess: boolean;
  readonly deleteSeedlingFailed: boolean;
  readonly updateSeedlingRequest: boolean;
  readonly updateSeedlingSuccess: boolean;
  readonly updateSeedlingFailed: boolean;
  readonly swiperRequest: boolean;
  readonly swiperSuccess: boolean;
  readonly swiperFailed: boolean;
  readonly usersRequest: boolean;
  readonly usersSuccess: boolean;
  readonly usersFailed: boolean;
  readonly deliveryTypesRequest: boolean;
  readonly deliveryTypesSuccess: boolean;
  readonly deliveryTypesFailed: boolean;
  readonly deliveryDatesRequest: boolean;
  readonly deliveryDatesSuccess: boolean;
  readonly deliveryDatesFailed: boolean;
  readonly deliveryPointsRequest: boolean;
  readonly deliveryPointsSuccess: boolean;
  readonly deliveryPointsFailed: boolean;
  readonly deliveryEventsRequest: boolean;
  readonly deliveryEventsSuccess: boolean;
  readonly deliveryEventsFailed: boolean;
  readonly deleteNotificationsRequest: boolean;
  readonly deleteNotificationsSuccess: boolean;
  readonly deleteNotificationsFailed: boolean;
};

const initialState: TAPIState = {
  errorObject: null,
  skip: 0,
  lastPage: true,
  questionsRequest: false,
  questionsFailed: false,
  answerRequest: false,
  answerSuccess: false,
  answerFailed: false,
  deleteQuestionRequest: false,
  deleteQuestionSuccess: false,
  deleteQuestionFailed: false,
  сategoryRequest: false,
  сategorySuccess: false,
  сategoryFailed: false,
  seedlingRequest: false,
  seedlingSuccess: false,
  seedlingFailed: false,
  deleteSeedlingRequest: false,
  deleteSeedlingSuccess: false,
  deleteSeedlingFailed: false,
  updateSeedlingRequest: false,
  updateSeedlingSuccess: false,
  updateSeedlingFailed: false,
  swiperRequest: false,
  swiperSuccess: false,
  swiperFailed: false,
  usersRequest: false,
  usersSuccess: false,
  usersFailed: false,
  deliveryTypesRequest: false,
  deliveryTypesSuccess: false,
  deliveryTypesFailed: false,
  deliveryDatesRequest: false,
  deliveryDatesSuccess: false,
  deliveryDatesFailed: false,
  deliveryPointsRequest: false,
  deliveryPointsSuccess: false,
  deliveryPointsFailed: false,
  deliveryEventsRequest: false,
  deliveryEventsSuccess: false,
  deliveryEventsFailed: false,
  deleteNotificationsRequest: false,
  deleteNotificationsSuccess: false,
  deleteNotificationsFailed: false,
};

const apiAdminSlice = createSlice({
  name: 'apiAdmin',
  initialState,
  reducers: {
    setSkip: (state, action: PayloadAction<number>) => ({
      ...state,
      skip: action.payload,
    }),
    setLastPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      lastPage: action.payload,
    }),
    getQuestionsRequest: state => ({
      ...state, questionsRequest: true,
    }),
    getQuestionsSuccess: state => ({
      ...state, 
      questionsRequest: false,
      questionsFailed: false,
    }),
    getQuestionsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      questionsFailed: true,
      questionsRequest: false,
      errorObject: action.payload,
    }),
    answerRequest: state => ({
      ...state,
      answerRequest: true,
      answerSuccess: false,
    }),
    answerSuccess: state => ({
      ...state,
      answerRequest: false,
      answerSuccess: true,
      answerFailed: false,
    }),
    answerFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      answerRequest: false,
      answerSuccess: false,
      answerFailed: true,
      errorObject: action.payload,
    }),
    deleteQuestionRequest: state => ({
      ...state,
      deleteQuestionRequest: true,
      deleteQuestionSuccess: false,
    }),
    deleteQuestionSuccess: state => ({
      ...state,
      deleteQuestionRequest: false,
      deleteQuestionSuccess: true,
      deleteQuestionFailed: false,
    }),
    deleteQuestionFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deleteQuestionRequest: false,
      deleteQuestionSuccess: false,
      deleteQuestionFailed: true,
      errorObject: action.payload,
    }),
    postCategoryRequest: state => ({
      ...state,
      сategoryRequest: true,
      сategorySuccess: false,
    }),
    postCategorySuccess: state => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: true,
      сategoryFailed: false,
    }),
    postCategoryFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: false,
      сategoryFailed: true,
      errorObject: action.payload,
    }),
    deleteCategoryRequest: state => ({
      ...state,
      сategoryRequest: true,
      сategorySuccess: false,
      сategoryFailed: false,
    }),
    deleteCategorySuccess: state => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: true,
      сategoryFailed: false,
    }),
    deleteCategoryFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: false,
      сategoryFailed: true,
      errorObject: action.payload,
    }),
    patchCategoryRequest: state => ({
      ...state,
      сategoryRequest: true,
      сategorySuccess: false,
    }),
    patchCategorySuccess: state => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: true,
      сategoryFailed: false,
    }),
    patchCategoryFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      сategoryRequest: false,
      сategorySuccess: false,
      сategoryFailed: true,
      errorObject: action.payload,
    }),
    postSeedlingRequest: state => ({
      ...state,
      seedlingRequest: true,
      seedlingSuccess: false,
    }),
    postSeedlingSuccess: state => ({
      ...state,
      seedlingRequest: false,
      seedlingSuccess: true,
      seedlingFailed: false,
    }),
    postSeedlingFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      seedlingRequest: false,
      seedlingSuccess: false,
      seedlingFailed: true,
      errorObject: action.payload,
    }),
    deleteSeedlingRequest: state => ({
      ...state,
      deleteSeedlingRequest: true,
      deleteSeedlingSuccess: false,
    }),
    deleteSeedlingSuccess: state => ({
      ...state,
      deleteSeedlingRequest: false,
      deleteSeedlingSuccess: true,
      deleteSeedlingFailed: false,
    }),
    deleteSeedlingFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deleteSeedlingRequest: false,
      deleteSeedlingSuccess: false,
      deleteSeedlingFailed: true,
      errorObject: action.payload,
    }),
    patchSeedlingRequest: state => ({
      ...state,
      updateSeedlingRequest: true,
      updateSeedlingSuccess: false,
    }),
    patchSeedlingSuccess: state => ({
      ...state,
      updateSeedlingRequest: false,
      updateSeedlingSuccess: true,
      updateSeedlingFailed: false,
    }),
    patchSeedlingFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      updateSeedlingRequest: false,
      updateSeedlingSuccess: false,
      updateSeedlingFailed: true,
      errorObject: action.payload,
    }),
    postSwipergRequest: state => ({
      ...state,
      swiperRequest: true,
      swiperSuccess: false,
    }),
    postSwipergSuccess: state => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: true,
      swiperFailed: false,
    }),
    postSwipergFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: false,
      swiperFailed: true,
      errorObject: action.payload,
    }),
    patchSwiperRequest: state => ({
      ...state,
      swiperRequest: true,
      swiperSuccess: false,
    }),
    patchSwiperSuccess: state => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: true,
      swiperFailed: false,
    }),
    patchSwiperFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: false,
      swiperFailed: true,
      errorObject: action.payload,
    }),
    deleteSwiperRequest: state => ({
      ...state,
      swiperRequest: true,
      swiperSuccess: false,
    }),
    deleteSwiperSuccess: state => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: true,
      swiperFailed: false,
    }),
    deleteSwiperFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      swiperRequest: false,
      swiperSuccess: false,
      swiperFailed: true,
      errorObject: action.payload,
    }),
    getUsersRequest: state => ({
      ...state, usersRequest: true,
    }),
    getUsersSuccess: state => ({
      ...state, 
      usersRequest: false,
      usersFailed: false,
    }),
    getUsersFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      usersFailed: true,
      usersRequest: false,
      errorObject: action.payload,
    }),
    patchUserRequest: state => ({
      ...state,
      userRequest: true,
      userSuccess: false,
    }),
    patchUserSuccess: state => ({
      ...state,
      userRequest: false,
      userSuccess: true,
      userFailed: false,
    }),
    patchUserFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      userRequest: false,
      userSuccess: false,
      userFailed: true,
      errorObject: action.payload,
    }),
    postDeliveryTypesRequest: state => ({
      ...state,
      deliveryTypesRequest: true,
      deliveryTypesSuccess: false,
    }),
    postDeliveryTypesSuccess: state => ({
      ...state, 
      deliveryTypesRequest: false,
      deliveryTypesSuccess: true,
      deliveryTypesFailed: false,
    }),
    postDeliveryTypesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryTypesFailed: true,
      deliveryTypesRequest: false,
      deliveryTypesSuccess: false,
      errorObject: action.payload,
    }),
    patchDeliveryTypesRequest: state => ({
      ...state,
      deliveryTypesRequest: true,
      deliveryTypesSuccess: false,
    }),
    patchDeliveryTypesSuccess: state => ({
      ...state,
      deliveryTypesRequest: false,
      deliveryTypesSuccess: true,
      deliveryTypesFailed: false,
    }),
    patchDeliveryTypesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryTypesRequest: false,
      deliveryTypesSuccess: false,
      deliveryTypesFailed: true,
      errorObject: action.payload,
    }),
    deleteDeliveryTypesRequest: state => ({
      ...state,
      deliveryTypesRequest: true,
      deliveryTypesSuccess: false,
    }),
    deleteDeliveryTypesSuccess: state => ({
      ...state,
      deliveryTypesRequest: false,
      deliveryTypesSuccess: true,
      deliveryTypesFailed: false,
    }),
    deleteDeliveryTypesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryTypesRequest: false,
      deliveryTypesSuccess: false,
      deliveryTypesFailed: true,
      errorObject: action.payload,
    }),
    postDeliveryDatesRequest: state => ({
      ...state,
      deliveryDatesRequest: true,
      deliveryDatesSuccess: false,
      deliveryEventsSuccess: false,
      deliveryPointsSuccess: false,
    }),
    postDeliveryDatesSuccess: state => ({
      ...state, 
      deliveryDatesRequest: false,
      deliveryDatesSuccess: true,
      deliveryDatesFailed: false,
    }),
    postDeliveryDatesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryDatesFailed: true,
      deliveryDatesRequest: false,
      deliveryDatesSuccess: false,
      errorObject: action.payload,
    }),

    patchDeliveryDatesRequest: state => ({
      ...state,
      deliveryDatesRequest: true,
      deliveryDatesSuccess: false,
    }),
    patchDeliveryDatesSuccess: state => ({
      ...state,
      deliveryDatesRequest: false,
      deliveryDatesSuccess: true,
      deliveryDatesFailed: false,
    }),
    patchDeliveryDatesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryDatesRequest: false,
      deliveryDatesSuccess: false,
      deliveryDatesFailed: true,
      errorObject: action.payload,
    }),
    deleteDeliveryDatesRequest: state => ({
      ...state,
      deliveryDatesRequest: true,
      deliveryDatesSuccess: false,
    }),
    deleteDeliveryDatesSuccess: state => ({
      ...state,
      deliveryDatesRequest: false,
      deliveryDatesSuccess: true,
      deliveryDatesFailed: false,
    }),
    deleteDeliveryDatesFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryDatesRequest: false,
      deliveryDatesSuccess: false,
      deliveryDatesFailed: true,
      errorObject: action.payload,
    }),
    
    postDeliveryPointsRequest: state => ({
      ...state,
      deliveryPointsRequest: true,
      deliveryPointsSuccess: false,
      deliveryDatesSuccess: false,
      deliveryEventsSuccess: false,
    }),
    postDeliveryPointsSuccess: state => ({
      ...state, 
      deliveryPointsRequest: false,
      deliveryPointsSuccess: true,
      deliveryPointsFailed: false,
    }),
    postDeliveryPointsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryPointsFailed: true,
      deliveryPointsRequest: false,
      deliveryPointsSuccess: false,
      errorObject: action.payload,
    }),
    patchDeliveryPointsRequest: state => ({
      ...state,
      deliveryPointsRequest: true,
      deliveryPointsSuccess: false,
    }),
    patchDeliveryPointsSuccess: state => ({
      ...state,
      deliveryPointsRequest: false,
      deliveryPointsSuccess: true,
      deliveryPointsFailed: false,
    }),
    patchDeliveryPointsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryPointsRequest: false,
      deliveryPointsSuccess: false,
      deliveryPointsFailed: true,
      errorObject: action.payload,
    }),
    deleteDeliveryPointsRequest: state => ({
      ...state,
      deliveryPointsRequest: true,
      deliveryPointsSuccess: false,
    }),
    deleteDeliveryPointsSuccess: state => ({
      ...state,
      deliveryPointsRequest: false,
      deliveryPointsSuccess: true,
      deliveryPointsFailed: false,
    }),
    deleteDeliveryPointsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryPointsRequest: false,
      deliveryPointsSuccess: false,
      deliveryPointsFailed: true,
      errorObject: action.payload,
    }),
    getDeliveryEventsRequest: state => ({
      ...state,
      deliveryEventsRequest: true,
    }),
    getDeliveryEventsSuccess: state => ({
      ...state, 
      deliveryEventsRequest: false,
      deliveryEventsFailed: false,
    }),
    getDeliveryEventsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryEventsFailed: true,
      deliveryEventsRequest: false,
      errorObject: action.payload,
    }),
    postDeliveryEventsRequest: state => ({
      ...state,
      deliveryEventsRequest: true,
      deliveryEventsSuccess: false,
      deliveryDatesSuccess: false,
      deliveryPointsSuccess: false,
    }),
    postDeliveryEventsSuccess: state => ({
      ...state, 
      deliveryEventsRequest: false,
      deliveryEventsSuccess: true,
      deliveryEventsFailed: false,
    }),
    postDeliveryEventsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state, 
      deliveryEventsFailed: true,
      deliveryEventsRequest: false,
      deliveryEventsSuccess: false,
      errorObject: action.payload,
    }),
    deleteDeliveryEventsRequest: state => ({
      ...state,
      deliveryEventsRequest: true,
      deliveryEventsSuccess: false,
    }),
    deleteDeliveryEventsSuccess: state => ({
      ...state,
      deliveryEventsRequest: false,
      deliveryEventsSuccess: true,
      deliveryEventsFailed: false,
    }),
    deleteDeliveryEventsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryEventsRequest: false,
      deliveryEventsSuccess: false,
      deliveryEventsFailed: true,
      errorObject: action.payload,
    }),
    patchDeliveryEventsRequest: state => ({
      ...state,
      deliveryEventsRequest: true,
      deliveryEventsSuccess: false,
    }),
    patchDeliveryEventsSuccess: state => ({
      ...state,
      deliveryEventsRequest: false,
      deliveryEventsSuccess: true,
      deliveryEventsFailed: false,
    }),
    patchDeliveryEventsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deliveryEventsRequest: false,
      deliveryEventsSuccess: false,
      deliveryEventsFailed: true,
      errorObject: action.payload,
    }),
    deleteNotificationsRequest: state => ({
      ...state,
      deleteNotificationsRequest: true,
      deleteNotificationsSuccess: false,
    }),
    deleteNotificationsSuccess: state => ({
      ...state,
      deleteNotificationsRequest: false,
      deleteNotificationsSuccess: true,
      deleteNotificationsFailed: false,
    }),
    deleteNotificationsFailed: (state, action: PayloadAction<TAPIError>) => ({
      ...state,
      deleteNotificationsRequest: false,
      deleteNotificationsSuccess: false,
      deleteNotificationsFailed: true,
      errorObject: action.payload,
    }),
  }
});

const apiAdminReducer = apiAdminSlice.reducer;

export const {
  setSkip,
  setLastPage,
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsFailed,
  answerRequest,
  answerSuccess,
  answerFailed,
  deleteQuestionRequest,
  deleteQuestionSuccess,
  deleteQuestionFailed,
  postCategoryRequest,
  postCategorySuccess,
  postCategoryFailed,
  deleteCategoryRequest,
  deleteCategorySuccess,
  deleteCategoryFailed,
  patchCategoryRequest,
  patchCategorySuccess,
  patchCategoryFailed,
  postSeedlingRequest,
  postSeedlingSuccess,
  postSeedlingFailed,
  deleteSeedlingRequest,
  deleteSeedlingSuccess,
  deleteSeedlingFailed,
  patchSeedlingRequest,
  patchSeedlingSuccess,
  patchSeedlingFailed,
  postSwipergRequest,
  postSwipergSuccess,
  postSwipergFailed,
  patchSwiperRequest,
  patchSwiperSuccess,
  patchSwiperFailed,
  deleteSwiperRequest,
  deleteSwiperSuccess,
  deleteSwiperFailed,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailed,
  patchUserRequest,
  patchUserSuccess,
  patchUserFailed,
  postDeliveryTypesRequest,
  postDeliveryTypesSuccess,
  postDeliveryTypesFailed,
  postDeliveryDatesRequest,
  postDeliveryDatesSuccess,
  postDeliveryDatesFailed,
  postDeliveryPointsRequest,
  postDeliveryPointsSuccess,
  postDeliveryPointsFailed,
  getDeliveryEventsRequest,
  getDeliveryEventsSuccess,
  getDeliveryEventsFailed,
  postDeliveryEventsRequest,
  postDeliveryEventsSuccess,
  postDeliveryEventsFailed,
  deleteDeliveryEventsRequest,
  deleteDeliveryEventsSuccess,
  deleteDeliveryEventsFailed,
  patchDeliveryDatesRequest,
  patchDeliveryDatesSuccess,
  patchDeliveryDatesFailed,
  deleteDeliveryDatesRequest,
  deleteDeliveryDatesSuccess,
  deleteDeliveryDatesFailed,
  patchDeliveryPointsRequest,
  patchDeliveryPointsSuccess,
  patchDeliveryPointsFailed,
  deleteDeliveryPointsRequest,
  deleteDeliveryPointsSuccess,
  deleteDeliveryPointsFailed,
  patchDeliveryTypesRequest,
  patchDeliveryTypesSuccess,
  patchDeliveryTypesFailed,
  deleteDeliveryTypesRequest,
  deleteDeliveryTypesSuccess,
  deleteDeliveryTypesFailed,
  patchDeliveryEventsRequest,
  patchDeliveryEventsSuccess,
  patchDeliveryEventsFailed,
  deleteNotificationsRequest,
  deleteNotificationsSuccess,
  deleteNotificationsFailed,
} = apiAdminSlice.actions;

export default apiAdminReducer;
