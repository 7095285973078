import { FC } from 'react';
import linksimageStyles from './links-image.module.css';
import accountOnIcon from '../../images/account-on.svg';
import accountOffIcon from '../../images/account-off.svg';

interface ILinkAccountProps {
  auth: boolean;
}

const LinkAccount: FC<ILinkAccountProps> = ({ auth }) => {
  const image = auth ? accountOnIcon : accountOffIcon;

  return (
    <img src={image} alt='Личный кабинет' className={linksimageStyles.image} />
  )
}

export default LinkAccount;