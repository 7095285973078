import { FC } from 'react';
import linksStyles from './links-image.module.css';
import cartIcon from '../../images/cart.svg';

interface ILinkCartProps {
  count: number;
}

const LinkCart: FC<ILinkCartProps> = ({ count }) => (
  <>
    <img src={cartIcon} alt='Корзина' className={linksStyles.image} />
    {(count > 0) && <p className={linksStyles.cart}>{count}</p>}
  </>
)

export default LinkCart;