import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  TCategory, 
  TDeliveryDate, 
  TDeliveryPoint, 
  TDeliveryType, 
  TNotification, 
  TOrder, 
  TOrdersAllowed, 
  TSeedling,
  TSlide 
} from '../services/types/data';

type TMainState = {
  readonly slides: ReadonlyArray<TSlide> | null;
  readonly categories: ReadonlyArray<TCategory> | null;
  readonly seedlings: ReadonlyArray<TSeedling>;
  readonly seedling: TSeedling | null;
  readonly deliveryTypes: ReadonlyArray<TDeliveryType> | null;
  readonly deliveryDates: ReadonlyArray<TDeliveryDate>;
  readonly deliveryPoints: ReadonlyArray<TDeliveryPoint> | null;
  readonly notifications: ReadonlyArray<TNotification>;
  readonly orders: ReadonlyArray<TOrder>;
  readonly order: TOrder | null;
  readonly deliveryChooseDates: ReadonlyArray<TDeliveryDate> | null;
  readonly ordersAllowed: boolean;
};

const initialState: TMainState = {
  slides: null,
  categories: null,
  seedlings: [],
  seedling: null,
  deliveryTypes: null,
  deliveryDates: [],
  deliveryPoints: null,
  notifications: [],
  orders: [],
  order: null,
  deliveryChooseDates: null,
  ordersAllowed: false,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    getSwiper: (state, action: PayloadAction<Array<TSlide>>) => ({
      ...state, slides: action.payload,
    }),
    getCategories: (state, action: PayloadAction<Array<TCategory>>) => ({
      ...state, categories: action.payload,
    }),
    getSeedlings: (state, action: PayloadAction<Array<TSeedling>>) => ({
      ...state, seedlings: action.payload,
    }),
    getSeedling: (state, action: PayloadAction<TSeedling>) => ({
      ...state, seedling: action.payload,
    }),
    getDeliveryTypes: (state, action: PayloadAction<Array<TDeliveryType>>) => ({
      ...state, deliveryTypes: action.payload,
    }),
    getDeliveryDates: (state, action: PayloadAction<Array<TDeliveryDate>>) => ({
      ...state, deliveryDates: action.payload,
    }),
    getDeliveryPoints: (state, action: PayloadAction<Array<TDeliveryPoint>>) => ({
      ...state, deliveryPoints: action.payload,
    }),
    getNotifications: (state, action: PayloadAction<Array<TNotification>>) => ({
      ...state, notifications: action.payload,
    }),
    getOrders: (state, action: PayloadAction<Array<TOrder>>) => ({
      ...state, orders: action.payload,
    }),
    getOrder: (state, action: PayloadAction<TOrder>) => ({
      ...state, order: action.payload,
    }),
    getDeliveryChooseDate: (state, action: PayloadAction<Array<TDeliveryDate>>) => ({
      ...state, deliveryChooseDates: action.payload,
    }),
    getOrdersAllowed: (state, action: PayloadAction<TOrdersAllowed>) => ({
      ...state, ordersAllowed: action.payload['orders-allowed'],
    }),
  }
});

const mainReducer = mainSlice.reducer;

export const { 
  getSwiper,
  getCategories,
  getSeedlings,
  getSeedling,
  getDeliveryTypes,
  getDeliveryDates,
  getDeliveryPoints,
  getNotifications,
  getOrders,
  getOrder,
  getDeliveryChooseDate,
  getOrdersAllowed,
} = mainSlice.actions;

export default mainReducer;