import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  cancelOrderFailed,
  cancelOrderRequest,
  cancelOrderSuccess,
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { fetchCancelOrder } from '../../services/api/api-orders';

const cancelOrderThunk: AppThunk = (
  id: string,
  token: string,
) => async (dispatch) => {
  try {
    dispatch(cancelOrderRequest());
    await fetchCancelOrder(id, token);
    dispatch(cancelOrderSuccess());
  } catch (error) {
    dispatch(cancelOrderFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default cancelOrderThunk;