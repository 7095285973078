import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getSwiperFailed, 
  getSwiperRequest, 
  getSwiperSuccess 
} from '../../store/api-slice';
import { TAPIError } from '../../services/types/api';
import { getSwiper } from '../../store/main-slice';
import { fetchSwiper } from '../../services/api/api-swiper';

const getSwiperThunk: AppThunk = () => async (dispatch) => {
  try {
    dispatch(getSwiperRequest());
    const response = await fetchSwiper();
    dispatch(getSwiper(response.data));
    dispatch(getSwiperSuccess());
  } catch (error) {
    dispatch(getSwiperFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
}

export default getSwiperThunk;
