import { AppThunk } from '../../services/types/store';
import { setCartItems } from '../../store/cart-slice';
import { TDataCartElement, TSeedlingBase } from '../../services/types/data';
import { fetchSeedling } from '../../services/api/api-products';

const setCartElementsThunk: AppThunk = () => async (
  dispatch,
  getState,
) => {
  const dataCart = getState().cart.dataCart;
  const discount = (100 - (getState().user.discount)) * 0.01;
  const isWholesaler = getState().user['is-wholesaler'];

  const promises = dataCart.map(async (item) => {
    const seedlingInfo = (await fetchSeedling(item.seedling)).data;
    return { seedling: seedlingInfo, count: item.count };
  });
  const cartItems: Array<TDataCartElement<TSeedlingBase>> = await Promise.all(promises);

  const amount = Math.trunc(cartItems.reduce((
    acc: number,
    item: TDataCartElement<TSeedlingBase>,
  ) => {
    const price = isWholesaler ? item.seedling['wholesale-price'] : item.seedling['retail-price']
    return acc + item.count * price * discount;
  }, 0));

  dispatch(setCartItems({
    items: cartItems,
    amount,
  }));

};

export default setCartElementsThunk;