import { useState } from 'react';
import FocusLock from 'react-focus-lock';
import styles from './header.module.css';
import ButtonMenu from '../button-menu/button-menu';
import Navigation from '../navigation/navigation';
import Menu from '../menu/menu';
import Divider from '../../ui/divider';

function Header() {
  const [open, setOpen] = useState(false);
  const menuId = 'menu';

  const onClickMenu = () => setOpen(!open);

  const onClickClose = () => setOpen(false);

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <FocusLock disabled={!open}>
          <ButtonMenu open={open} onClickMenu={onClickMenu} aria-controls={menuId} />
          <Menu open={open} onClose={onClickClose} id={menuId} />
        </FocusLock>
        <Navigation />
      </div>
      <Divider />
    </header>
  );
}

export default Header;