import { Link } from 'react-router-dom';
import styles from './not-found.module.css';
import pageNotFound from '../../images/not-found.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav, pathNames } from '../../utils/data';

export function NotFound404() {
  return (
    <HelmetProvider>
      <div className={styles.container}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <img alt='page not found' src={pageNotFound} className={styles.image} />
        <Link to={pathNames.main} className={styles.link}>Перейти на главную</Link>
      </div>
    </HelmetProvider>
  )
}