import { 
  FC, 
  useState, 
  useEffect 
} from 'react';
import Input from '../../ui/inputs/input';
import CloseButton from '../../ui/buttons/close-button';
import styles from './list-search-seedlings.module.css';
import { useDispatch, useSelector } from '../../services/hooks';
import getSeedlingsThunk from '../../thunks/products/seedlings';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import Modal from '../modal/modal';
import { 
  TDataOrderElement, 
  TFormOrder, 
  TSeedling, 
} from '../../services/types/data';

interface IListSearchSeedlingsProps {
  setSearchVisible: (item: boolean) => void;
  setOrderItems: React.Dispatch<React.SetStateAction<TDataOrderElement[]>>;
  orderItems: Array<TDataOrderElement>;
  dataOrder: TFormOrder;
  setDataOrder: React.Dispatch<React.SetStateAction<TFormOrder>>;
  discount: number;
  isWholesaler: boolean;
}

const ListSearchSeedlings: FC<IListSearchSeedlingsProps> = ({
  setSearchVisible,
  setOrderItems,
  orderItems,
  dataOrder,
  setDataOrder,
  discount,
  isWholesaler,
}) => {
  const { seedlings } = useSelector(state => state.main);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  interface ISeedling {
    title: string;
    id: string | null;
  }

  const [ seedlingValue, setSeedlingValue ] = useState<ISeedling>({
    title: '',
    id: null,
  })

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSeedlingValue({
      ...seedlingValue,
      title: value
    });
  };

  const onClickClose = () => {
    setSearchVisible(false);
    setSeedlingValue({
      ...seedlingValue,
      title: ''
    });
  };

  useEffect(() => {
    if (seedlingValue.title === '') {
    } else dispatch(getSeedlingsThunk({
      skip: 0,
      limit: 50,
      search: seedlingValue.title,
    }))
  }, [seedlingValue.title, dispatch]);

  const onClick = (seedling: TSeedling) => {
    const { id, stock } = seedling;
    if (stock === 0) {
      setText('Товар закончился');
      setVisible(true);
      return
    }
    if (!dataOrder.items.some(item => item['seedling-id'] === id)) {
      const newItems = [...orderItems, {
        seedling,
        count: 1,
        price: isWholesaler ? 
          seedling['wholesale-price'] * discount : 
          seedling['retail-price'] * discount,
      }]
      setOrderItems(newItems);
      setDataOrder(prevData => ({
        ...prevData,
        items: [...prevData.items, {
          'seedling-id': id,
          count: 1,
        }]
      }))
    } else {
      setText('Такой товар уже есть в заказе');
      setVisible(true);
    }
  }

  const handleCloseModal = () => {
    setVisible(false);
  };

  const modal = (
    <Modal header='Добавление товара' onClose={handleCloseModal}>
      <Text text={text} className={styles.modaltext} />
    </Modal>
  );

  return (
    <div>
      <div className={styles.container}>
        <Input
          name='search'
          extraClass={styles.input}
          onChange={onChangeInput}
          value={seedlingValue.title}
          placeholder='Введите название саженца'
        />
        <CloseButton
          onClose={onClickClose} 
          size='small'
          />
      </div>
      {seedlingValue.title && seedlings.length > 0 && (
        <List className={styles.box}>
          {seedlings.map(el => (
            <ListItem 
              key={el.id} 
              className={styles.item}
              onClick={() => onClick(el)}
              style={{ opacity: el.stock > 0 ? 1 : 0.5}}
            >
              <Title type='h4' text={el.title} className={styles.title} />
              <Text text={`(${el.category.title})`} />
            </ListItem>
          ))}
        </List>
      )}
      {visible && modal}
    </div>
  )
};

export default ListSearchSeedlings;