import { 
  useCallback, 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import getUsersThunk from '../../thunks/users/users';
import { LIMIT_USERS } from '../../utils/constants';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import styles from './users.module.css';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import cn from 'classnames';
import EditButton from '../../ui/buttons/edit-button';
import { Loader } from '../../ui/loader/loader';
import ScrollButton from '../../ui/buttons/scroll-button';
import { TUser } from '../../services/types/data';
import Form from '../../ui/form/form';
import Button from '../../ui/buttons/button';
import stylesButton from '../../components/questions/questions.module.css';
import Checkbox from '../../ui/inputs/checkbox';
import Input from '../../ui/inputs/input';
import updateUserThunk from '../../thunks/users/update-user';


export function UsersPage() {
  const dispatch = useDispatch();
  const { 
    skip, 
    lastPage,
    usersRequest,
  } = useSelector(state => state.apiAdmin);
  const { users } = useSelector(state => state.admin);
  const { 
    'is-admin': isAdmin, 
    'is-superuser': isSuperUser 
  } = useSelector(state => state.user);
  const { updateUserSuccess } = useSelector(state => state.api);
  
  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);
  const [visible, setVisible] = useState(false);
  const userInit = {
    id: '',
    email: '',
    'first-name': '',
    'last-name': '',
    discount: 0,
    'phone-number': '',
    'postal-code': '',
    region: '',
    city: '',
    location: '',
    'is-active': true,
    'is-superuser': false,
    'is-admin': false,
    'is-wholesaler': false,
  };
  const [user, setUser] = useState<TUser>(userInit);
  const [usersUpdate, setUsersUpdate] = useState<Array<TUser>>([]);
  const [success, setSuccess] = useState(false);
  
  useEffect(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    if (updateUserSuccess) {
      setVisible(false);
      setSuccess(true);
    };
  }, [updateUserSuccess]);

  useEffect(() => {
    if (success) {
      setUsersUpdate(prevUsers => [...prevUsers.filter(item => item.id !== user.id), user]);
    };
  }, [success, user]);

  const getUsers = useCallback((currentSkip: number) => {
    dispatch(getUsersThunk({
      skip: currentSkip,
      limit: LIMIT_USERS,
    }));
  }, [dispatch]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getUsers(skip);
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, lastPage, getUsers]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (usersRequest) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, usersRequest]
  );

  useEffect(() => {
    getUsers(0);
  }, [getUsers]);

  const onClickEdit = (user: TUser) => {
    setSuccess(false);
    const dataUser = usersUpdate.find(
      item => user.id === item.id
    ) || user
    setUser(dataUser);
    setVisible(true);
  };

  const handleCloseCancel = () => {
    setVisible(false);
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const dataUpdateUser = {
      'is-active': user['is-active'],
      'is-wholesaler': user['is-wholesaler'],
      discount: user.discount,
    };

    dispatch(updateUserThunk(dataUpdateUser, user.id, isSuperUser || isAdmin));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const target = (event as React.ChangeEvent<HTMLInputElement>).target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
      setUser({...user, [name]: value})
  };

  console.log(user)

  const form = (
    <Form
      name='user'
      onSubmit={onClickSubmit}
      className={styles.form}
      legend='Пользователь'
    >
      <Title 
        type='h4' 
        text={`${user['first-name']} ${user['last-name']}`}
        className={styles.title}
      />
      {!user['is-superuser'] && (
        <Checkbox 
          name='is-active'
          label='Активный'
          checked={user?.['is-active'] || false}
          onChange={handleChange}
        />
      )}
      <Checkbox 
        name='is-wholesaler'
        label='Оптовик'
        checked={user?.['is-wholesaler'] || false}
        onChange={handleChange}
      />
      <Input
        placeholder='Скидка'
        label='Скидка'
        extraClass={styles.label}
        name='discount'
        onChange={handleChange}
        type='number'
        value={user?.['discount']}
        required
        min={0}
        max={100}
      />
      <div className={styles.form_box}>
        <Button
          className={stylesButton.button_admin}
          type='submit'
        >
          <Text text='Изменить' />
        </Button>
        <Button
          className={stylesButton.button_admin}
          clickHandler={handleCloseCancel}
        >
          <Text text='Отменить' />
        </Button>
      </div>
    </Form>
  )

  return (
    <section className={styles.content}>
      <List className={styles.list}>
        {users.map(item => {
          const dataUser = usersUpdate.find(
            user => user.id === item.id
          ) || item
          return (
            <ListItem 
              key={item.id}
              className={cn(
                styles.item, 
                visible && user.id === item.id && styles.flex
              )}
            >
              {visible && user.id === item.id ? form : (<>
                <div className={cn(styles.box, styles.start)}>
                  <Title 
                    type='h4' 
                    text={`${item['first-name']} ${item['last-name']}`}
                    className={styles.title}
                  />
                  <Text text={`${item.email}`} className={cn(styles.text, styles.mail)} />
                  <Text 
                    text={item['phone-number'] || 'не указан'}
                    className={cn(styles.text, styles.tel)}
                  />
                </div>
                <div className={styles.box}>
                  <Text text={`Оптовик: ${dataUser['is-wholesaler'] ? 'Да' : 'Нет'}`} />
                  <Text text={`Скидка: ${dataUser.discount}%`} />
                </div>
                <div 
                  className={cn(
                    styles.text,
                    styles.active,
                    !dataUser['is-active'] && styles.active_not
                  )}
                ></div>
                <EditButton
                  onClickHandler={() => {onClickEdit(item)}} 
                  extraClass={styles.end}
                />
              </>)}
            </ListItem>
          )
        })}
        {usersRequest && users.length > 0 && (
          <li>
            <Loader size='large' />
          </li>
        )}
        {!usersRequest && users.length === 0 && (
          <li>
            <Text text='По вашему запросу ничего не найдено' />
          </li>
        )}
        <li ref={loadMoreCallback}></li>
      </List>
      <ScrollButton />
    </section>
  )
}
