import { 
  FC, 
  useEffect,
  useRef, 
  useState,
} from 'react';
import { 
  TDataOrderElement, 
  TFormOrder, 
  TSeedlingBase 
} from '../../services/types/data';
import ListItem from '../../ui/list-item';
import { Link, useLocation } from 'react-router-dom';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import InputBox from '../input-box/input-box';
import { priceFormat } from '../../utils/functions';
import CloseButton from '../../ui/buttons/close-button';
import styles from './cart-item.module.css';
import { useDispatch } from '../../services/hooks';
import updateDataCartThunk from '../../thunks/carts/update-data-cart';
import deleteDataCartThunk from '../../thunks/carts/delete-data-cart';
import cn from 'classnames';
import updateCartElementsThunk from '../../thunks/carts/update-cart-elements';

interface ICartItemProps {
  item: TSeedlingBase;
  count: number;
  price: number;
  dataOrder?: TFormOrder;
  setDataOrder?: React.Dispatch<React.SetStateAction<TFormOrder>>;
  disabled?: boolean;
  isActive?: boolean;
  setAddOrderItems?: React.Dispatch<React.SetStateAction<TDataOrderElement[]>>;
  addOrderItems?: Array<TDataOrderElement>;
}

const CartItem: FC<ICartItemProps> = ({
  item,
  count,
  price,
  dataOrder,
  setDataOrder,
  disabled,
  isActive,
  setAddOrderItems,
  addOrderItems,
}) => {
  const {
    id,
    title,
    image,
    'short-description': description,
    stock,
  } = item;
  const [inputValue, setInputValue] = useState(count);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(count);
  }, [count]);

  useEffect(() => {
    if (toggle) {
      const updateDataCart = setTimeout(() => {
        const element = {
          seedling: id,
          count: inputValue
        };

        if (pathname === '/cart') {
          dispatch(updateDataCartThunk(element, isActive));
          dispatch(updateCartElementsThunk());
        } else {
          if (dataOrder && setDataOrder) {
            const items = dataOrder.items.map(item => {
              if (item['seedling-id'] === id) return {
                'seedling-id': id,
                count: inputValue
              }
              else return item
            })
            setDataOrder({
              ...dataOrder,
              items
            })
          }
        }
        setIsLoading(false)
      }, 1000)
  
      return () => clearTimeout(updateDataCart)
    }
  }, [toggle, inputValue, dispatch, isActive, id, pathname, setDataOrder, dataOrder]);

  const decrease = () => {
      if (inputValue > 1) {
        setInputValue(inputValue - 1)
        setToggle(true)
        setIsLoading(true)
      } else setInputValue(1)
  };

  const increase = () => {
      if (inputValue < stock) {
        setInputValue(inputValue + 1)
        setToggle(true)
        setIsLoading(true)
      } else setInputValue(stock)
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target: number = + e.target.value.replace(/\D/g, '');
    if (target === 0) {
      setInputValue(1);
    } else if (target > item.stock) {
      setInputValue(item.stock)
    } else setInputValue(target)
    setToggle(true)
    setIsLoading(true)
  };

  const onDelete = () => {
    if (pathname === '/cart') {
      dispatch(deleteDataCartThunk(id, isActive))
    } else {
      if (dataOrder && setDataOrder) {
        const items = dataOrder.items.filter(item => item['seedling-id'] !== id)
        setDataOrder({
          ...dataOrder,
          items
        })
      }
      if (addOrderItems && setAddOrderItems) {
        const items = addOrderItems.filter(item => item.seedling.id !== id)
        setAddOrderItems(items)
      }
    }
  };

  return (
    <ListItem className={styles.item}>
      <Link
        to={{ pathname: `/seedlings/${id}` }}
        className={styles.image}
        style={{ backgroundImage: `URL(${image})` }}
      />
      <Link
        to={{ pathname: `/seedlings/${id}` }}
        className={styles.link}
      >
        <Title text={title} type='h3' className={styles.title}  />
      </Link>
      <Text text={description} className={styles.caption} />
      {disabled ? (
        <Text text={`${inputValue} шт.`} className={styles.count} />
      ) : (
        <InputBox
          value={String(inputValue)}
          onChange={handleValueChange}
          onClickButtonUp={increase}
          onClickButtonDown={decrease}
          inputRef={inputRef}
          className={styles.input}
        />
      )}
      <div className={styles.stock}>
        {!disabled && <Text text={`Доступно ${stock}шт.`} className={styles.text} />}
        <Text text={`${priceFormat(price)} / 1шт.`} className={styles.text} />
      </div>
      <Text 
        text={`Сумма ${priceFormat(price * count)}`} 
        className={cn(styles.cost, isLoading && styles.load)}
      />
      <CloseButton 
        onClose={onDelete} 
        size='large' 
        del={true}
        extraClass={styles.close}
        disabled={disabled}
      />
    </ListItem>
  )
};

export default CartItem;