import { AxiosError } from 'axios';
import { getCookie, setCookie } from '../../services/helpers/api';
import makeErrorObject from '../../services/helpers/make-error-object';
import { AppThunk } from '../../services/types/store';
import { 
  updateTokenFailed, 
  updateTokenRequest, 
  updateTokenSuccess 
} from '../../store/api-slice';
import { resetToken, updateToken } from '../../store/user-slice';
import { TIMER_ACCESS_TOKEN, TOKEN } from '../../utils/constants';
import { TAPIError } from '../../services/types/api';
import { fetchUpdateToken } from '../../services/api/api-auth';

const updateTokenThunk: AppThunk = () => async (dispatch) => {
  try {
    const token = getCookie(TOKEN) || '';
    dispatch(updateTokenRequest());
    const response = await fetchUpdateToken(token);
    dispatch(updateTokenSuccess());
    dispatch(updateToken(response.data['access-token']));
    setCookie(TOKEN, response.data['refresh-token']);
    setTimeout(() => dispatch(resetToken()), TIMER_ACCESS_TOKEN);
  } catch (error) {
    dispatch(updateTokenFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateTokenThunk;