import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './categories-item.module.css';
import Text from '../../ui/text';
import ListItem from '../../ui/list-item';

interface ICategoriesItemProps {
  title: string;
  image?: string;
  isSelect: boolean;
  id: string;
  active?: string;
}

const CategoriesItem: FC<ICategoriesItemProps> = ({ 
  title,
  image,
  isSelect,
  id,
  active,
}) => {
  const path = title === 'Все категории' ? '/seedlings' : `/seedlings/category/${id}`;

  return (
    <ListItem  
      className={isSelect ? styles.select : styles.card}
      style={isSelect ? {} : { backgroundImage: `URL(${image})` }}
    >
      {isSelect ? (
        <Link to={{ pathname: path }}
          className={cn(styles.link_select, active === id ? styles.active : '')}
        >
          <Text text={title} />
        </Link>
      ) : (
        <Link to={{ pathname: path }} className={styles.link_card}>
          <Text text={title} className={styles.caption}/>
        </Link>
      )}
    </ListItem>
  )
};

export default CategoriesItem;
