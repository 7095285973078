import { 
  FC, 
  useEffect, 
  useState 
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import styles from './questions.module.css';
import Form from '../../ui/form/form';
import { useDispatch, useSelector } from '../../services/hooks';
import { TAPIDataQuestion, TAPIDataScore } from '../../services/types/api';
import postQuestionThunk from '../../thunks/questions/post-question';
import Title from '../../ui/title/title';
import { TQuestion } from '../../services/types/data';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import { dateFormatter } from '../../utils/functions';
import postAnswerThunk from '../../thunks/questions/post-answer';
import cn from 'classnames';
import deleteQuestionThunk from '../../thunks/questions/delete-question';
import patchAnswerThunk from '../../thunks/questions/update-answer';
import ModalDelete from '../modal-delete/modal-delete';
import Modal from '../modal/modal';
import postScoreThunk from '../../thunks/scores/post-score';

interface IQuestionsProps {
  questions: Array<TQuestion>;
  admin?: boolean;
  loadMoreCallback?: (el: HTMLLIElement) => void;
  isActive?: boolean;
}

const Questions: FC<IQuestionsProps> = ({ 
  questions, 
  admin,
  loadMoreCallback,
  isActive,
}) => {
  const [nameButton, setNameButton] = useState({
    questions: 'Задать вопрос',
    answer: 'Ответить',
  });
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { pathname } = useLocation();
  const { 
    postQuestionSuccess, 
    postScoreSuccess,
    errorObject,
  } = useSelector(state => state.api);
  const { questionsRequest } = useSelector(state => state.apiAdmin);
  const [questionId, setQuestionId] = useState('');
  const [modalVisible, setModalVisible] = useState({
    delete: false,
    score: false,
  });
  const [textQuestion, setTextQuestion] = useState('');
  const [textError, setTextError] = useState('');
  const [rating, setRating] = useState<number | null>(null);

  const onClickToggle = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (visible) setNameButton(prevName => ({
      ...prevName,
      questions: 'Скрыть форму',
    }));
    else setNameButton(prevName => ({
      ...prevName,
      questions: 'Задать вопрос',
    }));
  }, [visible]);

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (pathname === '/admin-panel/questions') {
      if (nameButton.answer === 'Ответить') dispatch(postAnswerThunk(questionId, value));
      if (nameButton.answer === 'Редактировать') dispatch(patchAnswerThunk(questionId, value));
    } else {
      const dataForm: TAPIDataQuestion = {
        'seedling-id': id || '',
        text: value,
      };
      dispatch(postQuestionThunk(dataForm));
    }
    setVisible(false);
    setValue('');
  };

  useEffect(() => {
    if (postQuestionSuccess) {
      setVisible(false);
    }
    if (postScoreSuccess) {
      setModalVisible({
        score: false,
        delete: false,
      })
    }
    if (errorObject && errorObject.statusCode === 409) {
      setTextError('Вы уже оценивали данный товар')
    }
  }, [postQuestionSuccess, postScoreSuccess, errorObject]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onClickAnswered = (
    e: React.MouseEvent<HTMLElement>,
    question: TQuestion,
  ) => {
    const target = e.target as HTMLElement
    setNameButton({
      ...nameButton,
      answer: target.innerText,
    })
    setQuestionId(question.id);
    setValue(question.answer || '')
    if (nameButton.questions === 'Скрыть форму' && questionId === question.id) setVisible(false);
    else setVisible(true)
  };

  const onClickDelete = (id: string, text: string) => {
    setQuestionId(id);
    setVisible(false);
    setModalVisible({
      ...modalVisible,
      delete: true,
    });
    setTextQuestion(text.slice(0, 50));
  };

  const confirmDelete = () => {
    dispatch(deleteQuestionThunk(questionId));
    setModalVisible({
      ...modalVisible,
      delete: false,
    });
  };

  const onClickScore = () => {
    setModalVisible({
      ...modalVisible,
      score: true,
    });
    setTextError('');
  };

  const form = (
    <Form
      name='question'
      onSubmit={onClickSubmit}
      className={styles.form}
      classFieldset={styles.fieldset}
      style={{ marginTop: '20px' }}
    >
      <textarea
        name='text'
        cols={40}
        rows={10}
        required
        value={value}
        onChange={handleChange}
        className={styles.area}
        maxLength={700}
      >
      </textarea>
      <Button
        type='submit'
        className={admin ? styles.button_admin : styles.button}
      >
        <Text text='Отправить' />
      </Button>
    </Form>
  );

  const handleCloseModal = () => {
    setModalVisible({
      score: false,
      delete: false,
    });
    setRating(null);
    setTextError('');
  };

  const handleStarClick = (starValue: number) => {
    setRating(starValue);
  };

  const handlePostStar = () => {
    if (rating && id) {
      const postData: TAPIDataScore = {
        'seedling-id': id || '',
        value: rating,
      };
      dispatch(postScoreThunk(postData));
    }
  }

  const modalScore = (
    <Modal header='Выберите нужное количество' onClose={handleCloseModal}>
      <div className={styles.score}>
        {[...Array(5)].map((star, index) => {
          const starValue = index + 1;
          return (
            <button
              key={starValue}
              className={styles.star}
              onClick={() => handleStarClick(starValue)}
            >
              {rating && starValue <= rating ? '★' : '☆'}
            </button>
          );
        })}
      </div>
      {textError && <Text text={textError} className={styles.error} />}
      <div className={cn(styles.box_button, styles.score)}>
        <Button
          className={styles.button}
          clickHandler={handlePostStar}
          disabled={rating ? false : true}
        >
          <Text text='Отправить' />
        </Button>
        <Button
          className={styles.button}
          clickHandler={handleCloseModal}
        >
          <Text text='Отменить' />
        </Button>
      </div>
    </Modal>
  )

  return (
    <div className={styles.content}>
      {!admin && isActive && (
        <>
          <div className={styles.box_button}>
            <Button
              className={styles.button}
              clickHandler={onClickScore}
            >
              <Text text='Оценить товар' />
            </Button>
            <Button
              className={styles.button}
              clickHandler={onClickToggle}
            >
              <Text text={nameButton.questions} />
            </Button>
          </div>
          {visible && form}
        </>
      )}
      <List className={styles.container}>
        {questions?.map(item => (
          <ListItem key={item.id} className={styles.item}>
            <Title 
              type='h4' 
              text={`${item.user['first-name']} ${item.user['last-name']}`} 
              className={styles.name}
            />
            <Text 
              text={dateFormatter(new Date(item['created-at']))} 
              className={styles.date}
            />
            {admin && (
              <Text 
                text={`${item.seedling.title}, ${item.seedling.category.title}`}
                className={styles.discription}
              />
            )}
            <Text text={item.text} className={cn(styles.text, styles.question)} />
            {item.answer && <Text text={item.answer} className={cn(styles.text, styles.answer)} />}
            {admin && (<>
              <div className={styles.box}>
                <Button
                  className={styles.button_admin}
                  clickHandler={(e) => {onClickAnswered(e, item)}}
                >
                  <Text 
                    text={(visible && questionId === item.id) ? 
                      nameButton.questions : item.answer ? 
                      'Редактировать' : 'Ответить'
                    }
                  />
                </Button>
                <Button
                  className={styles.button_admin}
                  clickHandler={() => {onClickDelete(item.id, item.text)}}
                >
                  <Text text='Удалить' />
                </Button>
              </div>
              {visible && questionId === item.id && form}
            </>)}
          </ListItem>
        ))}
        {questions.length === 0 && admin && !questionsRequest && (
          <li>
            <Text text='По вашему запросу ничего не найдено' />
          </li>
        )}
        {admin && <li ref={loadMoreCallback}></li>}
      </List>
      {modalVisible.score && modalScore}
      {modalVisible.delete && (
        <ModalDelete
          header='Подтвердите удаление вопроса:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={textQuestion}
        />
      )}
    </div>
  )
};

export default Questions;