import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getSeedlingFailed, 
  getSeedlingRequest, 
  getSeedlingSuccess,
} from '../../store/api-slice';
import { getSeedling } from '../../store/main-slice';
import { TAPIError } from '../../services/types/api';
import { fetchSeedling } from '../../services/api/api-products';

const getSeedlingThunk: AppThunk = (
  id: string,
) => async (dispatch) => {
  try {
    dispatch(getSeedlingRequest());
    const response = await fetchSeedling(id);
    dispatch(getSeedling(response.data));
    dispatch(getSeedlingSuccess());
  } catch (error) {
    dispatch(getSeedlingFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getSeedlingThunk;