import { forwardRef, CSSProperties } from 'react';
import { TRefList } from '../services/types/data';

interface IListProps {
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
  id?: string;
}

const List = forwardRef<TRefList, IListProps>(({ 
  className, 
  style, 
  children,
  id,
}, ref) => (
  <ul 
    className={className}
    ref={ref}
    style={style}
    id={id}
  >
    {children}
  </ul>
));

export default List;
