import { FC } from 'react';
import styles from './swiper-item.module.css';
import { TSlide } from '../../services/types/data';
import Title from '../../ui/title/title';
import ListItem from '../../ui/list-item';

interface ISwiperItemProps {
  slide: TSlide;
}

const SwiperItem: FC<ISwiperItemProps> = ({ slide }) => {
  const { image, text } = slide;
  return (
    <ListItem 
      className={styles.container}
      style={{ backgroundImage: `URL(${image})` }}
    >
      <Title type='h2' text={text} />
    </ListItem>
  )
}

export default SwiperItem;
