import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteDeliveryTypesFailed,
  deleteDeliveryTypesRequest,
  deleteDeliveryTypesSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteDeliveryTypes } from '../../services/api/api-delivery-types';

const deleteDeliveryTypeThunk: AppThunk = (
  typeId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteDeliveryTypesRequest());
    await fetchDeleteDeliveryTypes(typeId, token);
    dispatch(deleteDeliveryTypesSuccess());
  } catch (error) {
    dispatch(deleteDeliveryTypesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteDeliveryTypeThunk;