import { FC } from 'react';
import styles from './links-image.module.css';
import notificationIcon from '../../images/notification.svg';
import cn from 'classnames';

interface ILinkNotificationProps {
  count: number;
}

const LinkNotification: FC<ILinkNotificationProps> = ({ count }) => (
  <>
    <img src={notificationIcon} alt='Колокольчик' className={cn(styles.image, styles.notification)} />
    {(count > 0) && <p className={cn(styles.cart, styles.count)}>{count < 100 ? count : '99+'}</p>}
  </>
)

export default LinkNotification;