import { AxiosRequestConfig } from 'axios';
import { TAPIDataOrder, TAPIParamsObject } from '../types/api';
import { ORDERS_URL } from '../../utils/constants';
import { 
  blogAPI,
  injectBearerToken,
  makeParams,
} from '../helpers/api';
import { 
  IFetchCancelOrder,
  IFetchCreateStatement,
  IFetchOrder,
  IFetchOrders,
  IFetchPostOrder,
  IFetchUpdateOrder,
  IfetchOrdersAllowed,
  IfetchOrdersChangeAllowed,
} from './api.type';

export const fetchPostOrder: IFetchPostOrder = (
  data: TAPIDataOrder,
  token?: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: ORDERS_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token || ''));
};

export const fetchOrders: IFetchOrders = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all, visible, user, created, read, editable, 'delivery-date': deliveryDate
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: ORDERS_URL,
    params: makeParams(id, skip, limit, category, search, unanswered, all, visible, user, created, read, editable, deliveryDate),
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchOrdersMy: IFetchOrders = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all, visible, user, created, read, editable, 'delivery-date': deliveryDate
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/my`,
    params: makeParams(id, skip, limit, category, search, unanswered, all, visible, user, created, read, editable, deliveryDate),
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchOrder: IFetchOrder = (
  id: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/${id}`,
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchUpdateOrder: IFetchUpdateOrder = (
  data: TAPIDataOrder,
  id: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/${id}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchCancelOrder: IFetchCancelOrder = (
  id: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/${id}/cancel`,
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchCreateStatement: IFetchCreateStatement = (
  listId: Array<string>,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/get-to-work`,
    method: 'post',
    data: {
      id: listId,
    },
    responseType: 'blob',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchOrdersAllowed: IfetchOrdersAllowed = () => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/allowed`,
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchOrdersChangeAllowed: IfetchOrdersChangeAllowed = (
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${ORDERS_URL}/change_allowed`,
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
