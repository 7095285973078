import { RefObject } from 'react';

export function getTouchEventData(
  e:
    | TouchEvent
    | MouseEvent
    | React.TouchEvent<HTMLElement>
    | React.MouseEvent<HTMLElement>
) {
  return 'changedTouches' in e ? e.changedTouches[0] : e;
};

export function getRefValue<T>(ref: RefObject<T>) {
  return ref.current as T;
};

export const priceFormat = (price: number, round = 0) => {
  const formatedPrice = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: round,
  }).format(price);
  return formatedPrice;
};

export const dateFormatter = (date: Date) => {
  const formatedDate = new Intl.DateTimeFormat('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(date);
  return formatedDate;
};

export function filterEmptyStrings<T extends object>(data: T): Partial<T> {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== '') {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as Partial<T>);
};
