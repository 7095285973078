import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  getDeliveryPointsFailed,
  getDeliveryPointsRequest,
  getDeliveryPointsSuccess,
} from '../../store/api-slice';
import { getDeliveryPoints } from '../../store/main-slice';
import { fetchDeliveryPoints } from '../../services/api/api-delivery-points';

const getDeliveryPointsThunk: AppThunk = (
  visible?: boolean,
) => async (dispatch) => {
  try {
    dispatch(getDeliveryPointsRequest());
    const response = await fetchDeliveryPoints(visible);
    dispatch(getDeliveryPoints(response.data));
    dispatch(getDeliveryPointsSuccess());
  } catch (error) {
    dispatch(getDeliveryPointsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getDeliveryPointsThunk;