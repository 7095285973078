import { AppThunk } from '../../services/types/store';
import { TAPIDataOrder, TAPIError } from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  postOrderFailed, 
  postOrderRequest, 
  postOrderSuccess 
} from '../../store/api-slice';
import { fetchPostOrder } from '../../services/api/api-orders';
import { getOrder } from '../../store/main-slice';

const postOrderThunk: AppThunk = (
  data: TAPIDataOrder,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postOrderRequest());
    const response = await fetchPostOrder(data, token);
    dispatch(getOrder(response.data));
    dispatch(postOrderSuccess());
  } catch (error) {
    dispatch(postOrderFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postOrderThunk;