import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIDataDeliveryPoint } from '../../services/types/api';
import { 
  patchDeliveryPointsFailed,
  patchDeliveryPointsRequest,
  patchDeliveryPointsSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPatchDeliveryPoints } from '../../services/api/api-delivery-points';

const updateDeliveryPointThunk: AppThunk = (
  data: TAPIDataDeliveryPoint,
  pointId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchDeliveryPointsRequest());
    await fetchPatchDeliveryPoints(data, pointId, token);
    dispatch(patchDeliveryPointsSuccess());
  } catch (error) {
    dispatch(patchDeliveryPointsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateDeliveryPointThunk;