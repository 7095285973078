import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../ui/buttons/button';
import Form from '../../ui/form/form';
import InputPassword from '../../ui/inputs/input-password';
import Input from '../../ui/inputs/input';
import Text from '../../ui/text';
import Title from '../../ui/title/title';
import { useFormWithValidation } from '../../utils/validation';
import styles from './login.module.css';
import cn from 'classnames';
import { useDispatch, useSelector } from '../../services/hooks';
import loginThunk from '../../thunks/auth/login';
import { messages } from '../../utils/messages';
import { TAPIObjectError } from '../../services/types/api';
import { client } from '../../services/helpers/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

export function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 'is-active': isActive } = useSelector(state => state.user);
  const { errorObject } = useSelector(state => state.api);
  const { values, handleChange, isValid } = useFormWithValidation();
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const textError = errorObject?.detail?.[0] as TAPIObjectError;
    if (errorObject?.statusCode === 403) {
      setErrorText(messages.activeError)
    }
    if (errorObject?.statusCode === 400 && textError.msg === 'The client-id is not correct') {
      client.remove(values.email.toLowerCase());
      dispatch(loginThunk(values));
    }
    if (errorObject?.statusCode === 400 && textError.msg !== 'The client-id is not correct') {
      setErrorText(messages.loginError);
    }
  }, [errorObject, dispatch, values]);

  const loginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginThunk(values));
  };

  useEffect(() => {
    if (isActive) {
      navigate('/', { replace: true });
    }
  }, [isActive, navigate]);

  useEffect(() => {
    setErrorText('')
  }, [values]);

  useEffect(() => {
    setErrorText('');
  }, []);

  return (
    <HelmetProvider>
      <section className={styles.container}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <div className={styles.content}>
          <Title type='h3' text='Вход' />
          <Form 
            name='login'
            onSubmit={loginSubmit}
          >
            <Input
              type='email'
              placeholder='E-mail'
              onChange={handleChange}
              name='email'
              required
              extraClass={styles.input}
            />
            <InputPassword 
              handleChange={handleChange}
              className={styles.input}
            />
            <Button
              type='submit'
              disabled={!isValid}
              className={cn(styles.button, styles.save)}
            >
              Войти
            </Button>
          </Form>
          {errorText && <Text 
            text={errorText} 
            className={cn(styles.text, styles.error)} 
          />}
          <div className={cn(styles.item, styles.column)}>
            <Text 
              text='Вы — новый пользователь?' 
              className={styles.text} 
            />
            <Link to='/signup'>
              <Text
                text='Зарегистрироваться'
                className={styles.link}
              />
            </Link>
          </div>
          <div className={cn(styles.item, styles.column)}>
            <Text 
              className={styles.text}
              text='Забыли пароль?'
            />
            <Link to='/forgot-password'>
              <Text
                text='Восстановить пароль'
                className={styles.link}
              />
            </Link>
          </div>
        </div>
      </section>
    </HelmetProvider>
  )
}