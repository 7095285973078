import { AxiosRequestConfig } from 'axios';
import { 
  TAPIDataDeliveryDate, 
  TAPIParamsObject, 
  TAPIUpdateDeliveryDate,
} from '../types/api';
import { DELIVERY_DATES_URL } from '../../utils/constants';
import { 
  blogAPI,
  injectBearerToken,
  makeParams,
} from '../helpers/api';
import { 
  IFetchDeleteDeliveryDates,
  IFetchDeliveryDates,
  IFetchPatchDeliveryDates,
  IFetchPostDeliveryDates,
} from './api.type';

export const fetchDeliveryDates: IFetchDeliveryDates = (
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all, visible
  } = queryParams ?? {};

  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_DATES_URL,
    params: makeParams(id, skip, limit, category, search, unanswered, all, visible),
    method: 'get',
  }
  return blogAPI(requestConfig);
};

export const fetchPostDeliveryDates: IFetchPostDeliveryDates = (
  data: TAPIDataDeliveryDate,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: DELIVERY_DATES_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchDeliveryDates: IFetchPatchDeliveryDates = (
  data: TAPIUpdateDeliveryDate,
  dateId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_DATES_URL}/${dateId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteDeliveryDates: IFetchDeleteDeliveryDates = (
  dateId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${DELIVERY_DATES_URL}/${dateId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};
