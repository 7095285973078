import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Text from '../../ui/text';
import styles from './categories.module.css';
import Button from '../../ui/buttons/button';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import stylesButton from '../../components/questions/questions.module.css';
import cn from 'classnames';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import { TAPICategory } from '../../services/types/api';
import postCategoryThunk from '../../thunks/products/post-category';
import deleteCategoryThunk from '../../thunks/products/delete-category';
import { TCategory } from '../../services/types/data';
import updateCategoryThunk from '../../thunks/products/update-category';
import ModalDelete from '../../components/modal-delete/modal-delete';
import FormAdmin from '../../components/form-admin/form-admin';
import { useFormCategory } from '../../utils/validation';
import { categoryInit } from '../../utils/data';

export function CategoriesPage() {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.main);
  const { 
    сategorySuccess,
    errorObject, 
    сategoryFailed 
  } = useSelector(state => state.apiAdmin);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState<string | null>(null);
  const [nameButton, setNameButton] = useState('Создать');
  const [modalVisible, setModalVisible] = useState(false);

  const [category, setCategory] = useState(categoryInit);
  const [error, setError] = useState('');

  const { handleChange } = useFormCategory(category, setCategory);

  useEffect(() => {
    if (file) setError('');
  }, [file]);

  useEffect(() => {
    if (сategorySuccess) {
      setVisible(false);
      setModalVisible(false);
      setCategory(categoryInit);
    }
  }, [сategorySuccess]);

  useEffect(() => {
    if (сategoryFailed && errorObject?.statusCode === 409) {
      setError(`В категории имеются саженцы`)
    }
  }, [сategoryFailed, errorObject?.statusCode]);

  useEffect(() => {
    if (visible) {
      if (category.id) setNameButton('Создать');
      else setNameButton('Скрыть форму');
    }
    else {
      if (!category.id) {
        setNameButton('Создать');
        setCategory({
          id: '',
          title: '',
          image: '',
          'display-order': 0,
          available: true,
        });
      }
    }
  }, [visible, category.id]);

  const onClickToggle = () => {
    if (nameButton === 'Создать') {
      setVisible(true);
      setCategory(categoryInit);
    }
    else setVisible(false);
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.textContent?.includes('Создать')) {
      if (file) {
        const dataPostCategory: TAPICategory = {
          title: category.title,
          image: file,
          'display-order': category['display-order'],
          available: category.available,
        };
        dispatch(postCategoryThunk(dataPostCategory));
      } else {
        setError('*Необходимо выбрать изображение');
      }
    } else {
      const dataUpdateCategory: TAPICategory = {
        title: category.title,
        image: file || '',
        'display-order': category['display-order'],
        available: category.available,
      };
      dispatch(updateCategoryThunk(dataUpdateCategory, category.id));
    }
  };

  const onClickEdit = (category: TCategory) => {
    setCategory(category);
    setVisible(true);
  };

  const onClickDelete = (category: TCategory) => {
    setCategory(category)
    setVisible(false);
    setModalVisible(true);
  };

  const confirmDelete = () => {
    dispatch(deleteCategoryThunk(category.id));
  };

  const handleCloseModal = () => {
    setVisible(false);
    setModalVisible(false);
    setCategory(categoryInit);
    setError('')
  };

  const handleChangeImage = (file: string) => {
    setFile(file)
  };

  const form = (
    <FormAdmin
      form='category'
      onSubmit={onClickSubmit}
      className={styles.form}
      classFieldset={styles.fieldset}
      handleChange={handleChange}
      values={category}
      handleChangeImage={handleChangeImage}
      error={error}
      handleCancel={handleCloseModal}
      id={category.id}
      legend={visible && !category.id ? 'Создание категории' : 'Редактирование категории'}
    />
  );

  return (
    <section className={styles.content}>
      <Button
        className={cn(stylesButton.button_admin, styles.button)}
        clickHandler={onClickToggle}
      >
        <Text text={nameButton} />
      </Button>
      {visible && !category.id && form}
      <List className={styles.list}>
        {categories?.map(item => (
          <ListItem key={item.id} className={styles.item}>
            {((category.id !== item.id) || (category.id === item.id && modalVisible)) && (
              <>
                <img src={item.image} alt={item.title} className={styles.image} />
                <div className={styles.container}>
                  <Title
                    type='h4'
                    text={item.title}
                    className={styles.title}
                  />
                  <Text 
                    text={`Порядок отображения: ${item['display-order']}`} 
                    className={styles.text} 
                  />
                  <Text 
                    text={item.available ? 'Отображается' : 'Не отображается'} 
                    className={styles.text} 
                  />
                </div>
              </>
            )}
            {visible && category.id === item.id ? form : (
              <div className={styles.box}>
                <EditButton onClickHandler={() => {onClickEdit(item)}} />
                <CloseButton 
                    onClose={() => onClickDelete(item)} 
                    size='small'
                    del={true}
                  />
              </div>
            )}
          </ListItem>
        ))}
      </List>
      {modalVisible && (
        <ModalDelete
          header='Подтвердите удаление категории:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={error === 'В категории имеются саженцы' ? `В категории ${category.title} имеются саженцы` : category.title}
          error={!!error}
        />
      )}
    </section>
  )
}