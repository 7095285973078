import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TWsState = {
  readonly wsConnected: boolean,
  readonly countNotification: number;
  readonly error: Event | undefined,
};

const initialState: TWsState = {
  wsConnected: false,
  countNotification: 0,
  error: undefined,
};

const wsSlice = createSlice({
  name: 'ws',
  initialState,
  reducers: {
    connectionStart: (state) => ({
      ...state
    }),
    connectionSuccess: (state) => ({
      ...state, 
      wsConnected: true,
      error: undefined,
    }),
    getMessage: (state, action: PayloadAction<number>) => ({
      ...state,
      countNotification: action.payload,
      error: undefined,
    }),
    connectionError: (state, action: PayloadAction<Event>) => ({
      ...state,
      wsConnected: false,
      countNotification: 0,
      error: action.payload,
    }),
    connectionClosed: (state) => ({
      ...state,
      wsConnected: false,
      countNotification: 0,
      error: undefined,
    }),
    connectionFinish: (state) => ({
      ...state,
      wsConnected: false,
      countNotification: 0,
      error: undefined,
    }),
  }
});

const wsReducer = wsSlice.reducer;

export const { 
  connectionStart,
  connectionSuccess,
  getMessage,
  connectionError,
  connectionClosed,
  connectionFinish,
} = wsSlice.actions;

export default wsReducer;