import { 
  useCallback, 
  useEffect,
  useMemo,
  useRef, 
  useState 
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Text from '../../ui/text';
import styles from './delivery.module.css';
import Button from '../../ui/buttons/button';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Title from '../../ui/title/title';
import stylesButton from '../../components/questions/questions.module.css';
import cn from 'classnames';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import { 
  TAPIDataDeliveryEvent, 
  TAPIObjectError, 
  TAPIUpdateDeliveryEvent 
} from '../../services/types/api';
import { 
  TDeliveryDate, 
  TDeliveryEvent, 
  TDeliveryPoint, 
  TFormDeliveryEvent 
} from '../../services/types/data';
import ModalDelete from '../../components/modal-delete/modal-delete';
import { 
  useFormDeliveryDate, 
  useFormDeliveryEvent, 
  useFormDeliveryPoint
} from '../../utils/validation';
import Select from '../../ui/inputs/select';
import FormDelivery from '../../components/form-delivery/form-delivery';
import { deliveryDateInit, visibleDeliveryInit } from '../../utils/data';
import postDeliveryEventThunk from '../../thunks/delivery-events/post-delivery-event';
import { dateFormatter, filterEmptyStrings } from '../../utils/functions';
import { messages } from '../../utils/messages';
import getDeliveryEventsThunk from '../../thunks/delivery-events/delivery-events';
import { LIMIT_DELIVERY_EVENTS } from '../../utils/constants';
import Textarea from '../../ui/inputs/textarea';
import Modal from '../../components/modal/modal';
import FormDeliveryDate from '../../components/form-delivery-date/form-delivery-date';
import FormDeliveryPoint from '../../components/form-delivery-point/form-delivery-point';
import deleteDeliveryEventThunk from '../../thunks/delivery-events/delete-delivery-events';
import updateDeliveryEventThunk from '../../thunks/delivery-events/update-delivery-event';

export function DeliveryPage() {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.user);
  const { 
    deliveryDates,
    deliveryPoints,
    deliveryTypes,
  } = useSelector(state => state.main);
  const { deliveryEvents } = useSelector(state => state.admin);
  const { 
    skip, 
    lastPage,
    deliveryEventsSuccess,
    deliveryEventsFailed,
    deliveryDatesFailed,
    deliveryPointsFailed,
    deliveryDatesSuccess,
    deliveryPointsSuccess,
    errorObject,
  } = useSelector(state => state.apiAdmin);

  const [visible, setVisible] = useState(visibleDeliveryInit);
  const [nameButton, setNameButton] = useState({
    form: 'Создать',
    filter: 'Показать все',
  });

  const [errorText, setErrorText] = useState('');
  const [errorDate, setErrorDate] = useState('');
  const [errorPoint, setErrorPoint] = useState('');

  const deliveryEventInit = useMemo(() => ({
    id: '',
    point: deliveryPoints ? deliveryPoints[0]?.point : '',
    date: deliveryDates?.length ? deliveryDates[0].date : '',
    comment: '',
    'already-use': false,
  }), [deliveryPoints, deliveryDates]);

  const deliveryPointInit: TDeliveryPoint = {
    id: '',
    point: '',
    location: '',
    comment: '',
    show: true,
    'delivery-type-id': deliveryTypes?.length ? deliveryTypes[0].type : '',
    'already-use': false,
    'dates-required': false,
  };

  const [deliveryDate, setDeliveryDate] = useState<TDeliveryDate>(deliveryDateInit);
  const [deliveryPoint, setDeliveryPoint] = useState<TDeliveryPoint>(deliveryPointInit);
  const [deliveryEvent, setDeliveryEvent] = useState<TFormDeliveryEvent>(deliveryEventInit);

  const { handleChange: handleChangeDate } = useFormDeliveryDate(deliveryDate, setDeliveryDate, setErrorDate);
  const { handleChange: handleChangePoint } = useFormDeliveryPoint(deliveryPoint, setDeliveryPoint, setErrorPoint);
  const { handleChange } = useFormDeliveryEvent(deliveryEvent, setDeliveryEvent, setErrorText);

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const getDeliveryEvents = useCallback((currentSkip: number) => {
    dispatch(getDeliveryEventsThunk(token, {
      skip: currentSkip,
      limit: LIMIT_DELIVERY_EVENTS,
      all: nameButton.filter === 'Показать все' ? false : true,
    }));
  }, [dispatch, token, nameButton.filter]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getDeliveryEvents(skip);
        }, 500);
      }
    },
    [loadMoreTimeoutRef, lastPage, getDeliveryEvents, skip]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (deliveryEventsSuccess) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, deliveryEventsSuccess]
  );

  useEffect(() => {
    if (deliveryEventsSuccess) {
      setVisible(visibleDeliveryInit);  
    } else {
      if (deliveryDatesSuccess) {
        setVisible(prevVisible => ({
          ...prevVisible,
          date: false,
        }));
      }
      if (deliveryPointsSuccess) {
        setVisible(prevVisible => ({
          ...prevVisible,
          point: false,
        }));
      }
    }
  }, [deliveryEventsSuccess, deliveryDatesSuccess, deliveryPointsSuccess]);

  useEffect(() => {
    if (deliveryEventsFailed && errorObject?.statusCode === 422) {
      setErrorText(messages.eventError);
      setErrorDate('');
      setErrorPoint('');
    }
    if (deliveryDatesFailed && errorObject?.statusCode === 422) {
      setErrorDate(messages.dateError);
      setErrorText('');
      setErrorPoint('');
    }
    if (deliveryPointsFailed && errorObject?.statusCode === 422) {
      setErrorText('');
      setErrorDate('');
      const textError = errorObject?.detail?.[0] as TAPIObjectError;
      if (textError.loc[1] === 'point') setErrorPoint(messages.pointError);
      if (textError.loc[1] === 'location') setErrorPoint(messages.locationError);
    }
  }, [errorObject, deliveryEventsFailed, deliveryDatesFailed, deliveryPointsFailed]);

  useEffect(() => {
    setNameButton(prevName => ({
      ...prevName,
      form: visible.add ? 'Скрыть форму' : 'Создать',
    }));
    if (visible.add) {
      setDeliveryEvent(deliveryEventInit);
      setErrorText('');
      setErrorDate('');
    }
  }, [visible.add, deliveryEventInit]);

  useEffect(() => {
    token && getDeliveryEvents(0);
  }, [token, getDeliveryEvents]);

  useEffect(() => {
    deliveryEventsSuccess && getDeliveryEvents(0);
  }, [deliveryEventsSuccess, getDeliveryEvents]);

  const onClickFilter = () => {
    setNameButton({
      ...nameButton,
      filter: nameButton.filter === 'Показать все' ? 'Показать актуальные' : 'Показать все',
    });
  };

  const onClickToggle = () => {
    if (nameButton.form === 'Создать') {
      setVisible({
        ...visible,
        add: true,
      });
    }
    else setVisible({
      ...visible,
      add: false,
    });
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const dateId = deliveryDates?.find(({ date }) => date === deliveryEvent.date)?.id;
    if (target.textContent?.includes('Изменить')) {
      const dataUpdateEvent: TAPIUpdateDeliveryEvent = {
        'date-id': dateId || '',
        comment: deliveryEvent.comment,
      };
      dispatch(updateDeliveryEventThunk(dataUpdateEvent, deliveryEvent.id));
    } else {
      const pointId = deliveryPoints?.find(({ point }) => point === deliveryEvent.point)?.id;
      const dataPostDeliveryEvent: TAPIDataDeliveryEvent = {
        'point-id': pointId || '',
        'date-id': dateId || '',
        comment: deliveryEvent.comment,
      };
      const filteredData = filterEmptyStrings(dataPostDeliveryEvent);
      dispatch(postDeliveryEventThunk(filteredData));
    }
  };

  const handleCloseModal = () => {
    setVisible({
      add: false,
      edit: false,
      delete: false,
      date: false,
      point: false,
    });
    setDeliveryEvent(deliveryEventInit);
    setErrorText('');
  };

  const onClickEdit = (event: TDeliveryEvent) => {
    setDeliveryEvent({
      id: event.id,
      point: event.point.point,
      date: event.date.date,
      comment: event.comment,
      'already-use': event['already-use'],
    });
    setVisible({
      ...visible,
      add: false,
      edit: true,
    });
    setErrorText('');
  };

  const onClickDelete = (event: TDeliveryEvent) => {
    setDeliveryEvent({
      id: event.id,
      point: event.point.point,
      date: event.date.date,
      comment: event.comment,
      'already-use': event['already-use'],
    });
    setVisible({
      ...visible,
      add: false,
      delete: true,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteDeliveryEventThunk(deliveryEvent.id));
    setVisible({
      ...visible,
      delete: false,
    });
  };

  const onClickAddDate = () => {
    setDeliveryDate(deliveryDateInit);
    setVisible({
      ...visible,
      date: true,
    });
  };

  const onClickAddPoint = () => {
    setDeliveryPoint(deliveryPointInit);
    setVisible({
      ...visible,
      point: true,
    });
  };

  const OnClickCancel = () => {
    setVisible({
      ...visible,
      date: false,
      point: false,
    });
  };

  const modal = (
    <Modal
      header=''
      onClose={OnClickCancel}
    >
      {visible.date && (
        <FormDeliveryDate
          handleCancel={OnClickCancel}
          value={deliveryDate}
          isAdd={true}
          handleChange={handleChangeDate}
          error={errorDate}
        />
      )}
      {visible.point && (
        <FormDeliveryPoint
          handleCancel={OnClickCancel}
          value={deliveryPoint}
          handleChange={handleChangePoint}
          error={errorPoint}
        />
      )}
    </Modal>
  );

  const form = (
    <FormDelivery
      name='delivery'
      legend='Событие доставки'
      onClickSubmit={onClickSubmit}
      handleCancel={handleCloseModal}
      id={deliveryEvent.id}
    >
      <div className={cn(styles.box, styles.box_select)}>
        <Select
          name='date'
          valuesDeliveryDates={deliveryDates}
          onChange={handleChange}
          value={deliveryEvent.date}
          label='Выбрать дату доставки'
          className={styles.select}
        />
        {!visible.date && (
          <Button
            className={cn(stylesButton.button_admin)}
            clickHandler={onClickAddDate}
          >
            <Text text='Создать дату' />
          </Button>
        )}
      </div>
      {deliveryEventsFailed && errorText && <Text
        text={errorText}
        className={cn(styles.text, styles.error)} 
      />}
      {visible.add ? (
        <div className={cn(styles.box, styles.box_select)}>
          <Select
            name='point'
            valuesDeliveryPoints={deliveryPoints}
            onChange={handleChange}
            value={deliveryEvent.point}
            label='Выбрать точку доставки'
            className={styles.select}
          />
          {!visible.point && (
            <Button
              className={cn(stylesButton.button_admin)}
              clickHandler={onClickAddPoint}
            >
              <Text text='Создать точку' />
            </Button>
          )}
        </div>
      ) : (
        <Text text='Точка доставки: ' className={styles.point}>
          <span className={styles.span}>{deliveryEvent.point}</span>
        </Text>
      )}
      <Textarea
        name='comment'
        placeholder='Комментарий'
        handleChange={handleChange}
        value={deliveryEvent.comment || ''}
        maxLength={250}
        label='Комментарий'
      />
    </FormDelivery>
  );

  const modalEdit = (
    <Modal
      header='Редактирование события доставки'
      onClose={handleCloseModal}
      extraClass={styles.modal}
      classTitle={styles.modal_title}
    >
      {form}
    </Modal>
  );

  return (
    <article className={styles.content}>
      <div className={styles.box}>
        <Button
          className={cn(stylesButton.button_admin, styles.button)}
          clickHandler={onClickFilter}
        >
          <Text text={nameButton.filter} />
        </Button>
        <Button
          className={cn(stylesButton.button_admin, styles.button)}
          clickHandler={onClickToggle}
        >
          <Text text={nameButton.form} />
        </Button>
      </div>
      {visible.add && form}
      <List className={styles.list}>
        {deliveryEvents?.map(item => (
          <ListItem className={styles.item} key={item.id}>
            <Title
              type='h4'
              text={dateFormatter(new Date(item.date.date))}
              className={cn(styles.title, styles.grid_item)}
            />
            <Text text={item.point.point} className={cn(styles.text, styles.grid_item)} />
            <Text text={item.comment} className={styles.comment} />
            <EditButton onClickHandler={() => onClickEdit(item)} />
            <CloseButton
              onClose={() => onClickDelete(item)}
              size='small'
              del={!item['already-use'] ? true : false}
              disabled={item['already-use'] && true}
            />
          </ListItem>
        ))}
        {deliveryEvents.length === 0 && (
          <li>
            <Text text='Актуальных событий нет' />
          </li>
        )}
        <li ref={loadMoreCallback}></li>
      </List>
      {((visible.date && !visible.edit) || visible.point) && modal}
      {visible.edit && (visible.date ? modal : modalEdit)}
      {visible.delete && (
        <ModalDelete
          header='Подтвердите удаление события доставки:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={`${dateFormatter(new Date(deliveryEvent.date))} ${deliveryEvent.point}`}
        />
      )}
    </article>
  )
}