import { AxiosRequestConfig } from 'axios';
import { SWIPER_URL } from '../../utils/constants';
import { blogAPI, injectBearerToken } from '../helpers/api';
import { 
  IFetchDeleteSwiper, 
  IFetchPatchSwiper, 
  IFetchPostSwiper, 
  IFetchSwiper 
} from './api.type';
import { TAPISwiper } from '../types/api';

export const fetchSwiper: IFetchSwiper = () => {
  const requestConfig : AxiosRequestConfig = {
    url: SWIPER_URL,
    method: 'get',
  };
  return blogAPI(requestConfig);
};

export const fetchPostSwiper: IFetchPostSwiper = (
  data: TAPISwiper,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: SWIPER_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchPatchSwiper: IFetchPatchSwiper = (
  data: TAPISwiper,
  slideId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${SWIPER_URL}/${slideId}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteSwiper: IFetchDeleteSwiper = (
  slideId: string,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${SWIPER_URL}/${slideId}`,
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};