import { AppThunk } from '../../services/types/store';
import { TAPIDataDeliveryType, TAPIError } from '../../services/types/api';
import { 
  postDeliveryTypesFailed, 
  postDeliveryTypesRequest,
  postDeliveryTypesSuccess
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPostDeliveryTypes } from '../../services/api/api-delivery-types';

const postDeliveryTypeThunk: AppThunk = (
  data: TAPIDataDeliveryType,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postDeliveryTypesRequest());
    await fetchPostDeliveryTypes(data, token);
    dispatch(postDeliveryTypesSuccess());
  } catch (error) {
    dispatch(postDeliveryTypesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postDeliveryTypeThunk;