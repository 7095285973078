import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Text from '../../ui/text';
import Title from '../../ui/title/title';
import styles from '../login/login.module.css';
import cn from 'classnames';
import { useDispatch, useSelector } from '../../services/hooks';
import { messages } from '../../utils/messages';
import activationThunk from '../../thunks/auth/activation';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

export function ActivationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 'is-active': isActive } = useSelector(state => state.user);
  const { errorObject, activationSuccess } = useSelector(state => state.api);
  const location = useLocation();
  const index = location.search.indexOf('&code=');
  const id = location.search.slice(4, index);
  const code = location.search.slice(index + 6);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (activationSuccess) navigate('/login');
    if (errorObject?.statusCode === 400) setErrorText(messages.activationError)
  }, [activationSuccess, errorObject, navigate]);

  useEffect(() => {
    if (isActive) navigate('/', { replace: true })
    dispatch(activationThunk(id, code));
    setErrorText('');
  }, [isActive, navigate, id, code, dispatch]);

  return (
    <HelmetProvider>
      <section className={styles.container}>
      <Helmet>
        <title>{linksNav.title}</title>
      </Helmet>
        <div className={styles.content}>
          <Title type='h3' text='Активация нового пользователя' />
          {errorText && <Text 
            text={errorText} 
            className={cn(styles.text, styles.error)}
          />}
        </div>
      </section>
    </HelmetProvider>
  )
}