import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Elements from '../../components/elements/elements';
import Selection from '../../components/selection/selection';
import styles from './seedlings.module.css';
import mainStyles from '../main/main.module.css';
import Element from '../../components/element/element';

export function SeedlingsPage() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const content = useMemo(() => {
    if (pathname === '/seedlings' 
        || pathname.includes('category') 
        || pathname.includes('search')) {
      return <Elements />
    } else {
      return <Element />
    }
  }, [pathname]);

  return (
    <main className={mainStyles.content}>
      <section className={styles.content}>
        <Selection />
        {content}
      </section>
    </main>
  )
};
