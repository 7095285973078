import { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Text from '../../ui/text';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import styles from './profile-layout.module.css';
import { useDispatch, useSelector } from '../../services/hooks';
import logoutThunk from '../../thunks/auth/logout';
import cn from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

function ProfileLayout() {
  const dispatch = useDispatch();
  const { 
    'is-admin': isAdmin,
    'is-superuser': isSuperUser,
    token,
  } = useSelector(state => state.user);
  const [isToken, setIsToken] = useState(true);

  useEffect(() => {
    if (!isToken && token) {
      dispatch(logoutThunk(token));
      setIsToken(true);
    }
  }, [isToken, dispatch, token]);

  const onClickLogout = () => {
    if (token) dispatch(logoutThunk(token));
    else setIsToken(false);
  };

  return (
    <HelmetProvider>
      <main className={styles.container}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <nav className={styles.navigation}>
          <List className={styles.list}>
            <ListItem>
              <NavLink
                to={'orders'}
                className={({ isActive }) =>
                  isActive ? styles.link_active : styles.link
                }
              >
                {(isAdmin || isSuperUser) ? 'Заказы' : 'История заказов'}
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink
                to={'notifications'}
                className={({ isActive }) =>
                  isActive ? styles.link_active : styles.link
                }
              >
                Уведомления
              </NavLink>
            </ListItem>
            {(isAdmin || isSuperUser) && <>
              <ListItem>
                <NavLink
                  to={'delivery'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Доставка
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={'questions'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Вопросы
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={'categories'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Категории
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={'seedlings'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Саженцы
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={'swiper'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Слайды
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={'users'}
                  className={({ isActive }) =>
                    isActive ? styles.link_active : styles.link
                  }
                >
                  Пользователи
                </NavLink>
              </ListItem>
            </>}
            <ListItem>
              <NavLink
                to={(isAdmin || isSuperUser) ? 'profile' : '/profile'}
                end
                className={({ isActive }) =>
                  isActive ? styles.link_active : styles.link
                }
              >
                Профиль
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink
                to={'set-password'}
                className={({ isActive }) =>
                  isActive ? styles.link_active : styles.link
                }
              >
                Сменить пароль
              </NavLink>
            </ListItem>
            <ListItem
              onClick={onClickLogout}
            >
              <Text text='Выход' className={cn(styles.link, styles.link_exit)} />
            </ListItem>
          </List>
        </nav>
        <Outlet />
      </main>
    </HelmetProvider>
  )
}

export default ProfileLayout;
