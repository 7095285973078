import { AppThunk } from '../../services/types/store';
import { cart } from '../../services/helpers/api';
import { clearDataCart } from '../../store/cart-slice';
import { TAPIError } from '../../services/types/api';
import { updateMyCartFailed } from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import updateMyCartThunk from './update-my-cart';

const clearCartThunk: AppThunk = () => async (dispatch, getState) => {
  const token = getState().user.token;
  try {
    cart.remove();
    dispatch(clearDataCart());
    if (token) dispatch(updateMyCartThunk([], token));
  } catch (error) {
    dispatch(updateMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default clearCartThunk;