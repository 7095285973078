import { AppThunk } from '../../services/types/store';
import { fetchDeleteQuestion } from '../../services/api/api-questions';
import { TAPIError } from '../../services/types/api';
import { 
  deleteQuestionFailed, 
  deleteQuestionRequest, 
  deleteQuestionSuccess 
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';

const deleteQuestionThunk: AppThunk = (
  questionId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteQuestionRequest());
    await fetchDeleteQuestion(questionId, token);
    dispatch(deleteQuestionSuccess());
  } catch (error) {
    dispatch(deleteQuestionFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteQuestionThunk;