import Divider from '../../ui/divider';
import Text from '../../ui/text';
import { contacts } from '../../utils/data';
import Address from '../address/address';
import styles from './footer.module.css';

function Footer() {
  const year = new Date().getFullYear()

  return (
    <>
      <Divider />
      <footer className={styles.footer}>
        <Text className={styles.copy}>
          &copy;&nbsp;{contacts.logo}&nbsp;{year}
        </ Text>
        <Address isFooter={true} />
      </footer>
    </>
  )
}

export default Footer;
