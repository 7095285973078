import { TMessages } from '../services/types/data';

export const messages: TMessages = {
  userEmailError: 'Пользователь с такой электронной почтой уже существует',
  userEmailSuccess: 'На указанную электронную почту была отправлена ссылка для активации учётной записи',
  passwordForgotSuccess: 'На указанную электронную почту была отправлена ссылка для восстановления пароля',
  passwordForgotErrorCode: 'Введён неверный код активации',
  passwordForgotErrorEmail: 'Указана неверная почта',
  activeError: 'Учётная запись не была активирована или заблокирована администратором',
  loginError: 'Указаны неверная почта или пароль',
  passwordError: 'Введены неверные данные',
  eventError: 'На такую дату с такой точкой уже создано событие',
  dateError: 'Такая дата доставки уже существует',
  pointError: 'Точка доставки с таким названием уже существует',
  locationError: 'Точка доставки с таким местонахождением уже существует',
  typeError: 'Такой тип доставки уже существует',
  activationError: 'Данная ссылка устарела. Попробуйте войти в личный кабинет или обратитесь к администрации питомника',
  swiperError: 'Слайд с таким названием уже существует',
};
