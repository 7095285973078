import { FC } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import visibleIcon from '../../images/visible.svg';
import invisibleIcon from '../../images/invisible.svg';

interface ISearcButtonProps {
  extraClass?: string;
  onClickHandler: () => void;
  visible: boolean,
}

const EyesButton: FC<ISearcButtonProps> = ({ 
  extraClass, 
  onClickHandler,
  visible,
}) => (
  <button 
    type='button' 
    className={cn(styles.eyes, extraClass)} 
    onClick={onClickHandler}
  >
    <img 
      src={visible ? invisibleIcon : visibleIcon} 
      alt='Иконка глаза' 
      className={cn(styles.image, styles.image_small)} 
    />
  </button>
);

export default EyesButton;