import { AxiosRequestConfig } from 'axios';
import { TAPIParamsObject } from '../types/api';
import { NOTIFICATIONS_URL } from '../../utils/constants';
import { 
  makeParams,
  blogAPI,
  injectBearerToken,
} from '../helpers/api';
import { 
  IFetchDeleteNotifications,
  IFetchNotifications,
  IFetchReadNotifications, 
} from './api.type';

export const fetchNotifications: IFetchNotifications = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all, visible, user, created, read
  } = queryParams ?? {};
  const url = `${NOTIFICATIONS_URL}/my-notifications`
  const requestConfig: AxiosRequestConfig = {
    url,
    params: makeParams(id, skip, limit, category, search, unanswered, all, visible, user, created, read),
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchReadNotifications: IFetchReadNotifications = (
  token: string,
  notificationId: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${NOTIFICATIONS_URL}/${notificationId}`,
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchDeleteNotifications: IFetchDeleteNotifications = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered, all, visible, user, created
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: `${NOTIFICATIONS_URL}/deleting-old`,
    params: makeParams(id, skip, limit, category, search, unanswered, all, visible, user, created),
    method: 'DELETE',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};