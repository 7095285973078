import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  TAPIError, 
  TAPIPassword 
} from '../../services/types/api';
import { AppThunk } from '../../services/types/store';
import { 
  changePasswordFailed, 
  changePasswordRequest, 
  changePasswordSuccess 
} from '../../store/api-slice';
import { fetchChangePassword } from '../../services/api/api-auth';

const setPasswordThunk: AppThunk = (
  data: TAPIPassword,
  token: string,
) => async (dispatch) => {
  try {
    dispatch(changePasswordRequest());
    await fetchChangePassword(data, token);
    dispatch(changePasswordSuccess());
  } catch (error) {
    dispatch(changePasswordFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default setPasswordThunk;
