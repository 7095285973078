import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import Button from '../../ui/buttons/button';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Text from '../../ui/text';
import styles from './swiper.module.css';
import stylesButton from '../../components/questions/questions.module.css';
import cn from 'classnames';
import FormAdmin from '../../components/form-admin/form-admin';
import { TAPIObjectError, TAPISwiper } from '../../services/types/api';
import postSwiperThunk from '../../thunks/swiper/post-swiper';
import updateSwiperThunk from '../../thunks/swiper/update-swiper';
import { TSlide } from '../../services/types/data';
import Title from '../../ui/title/title';
import EditButton from '../../ui/buttons/edit-button';
import CloseButton from '../../ui/buttons/close-button';
import deleteSwiperThunk from '../../thunks/swiper/delete-swiper';
import ModalDelete from '../../components/modal-delete/modal-delete';
import { useFormSwiper } from '../../utils/validation';
import Textarea from '../../ui/inputs/textarea';
import { slideInit } from '../../utils/data';
import { messages } from '../../utils/messages';
import Modal from '../../components/modal/modal';


export function SwiperPage() {
  const { slides } = useSelector(state => state.main);
  const { 
    swiperSuccess, 
    errorObject,
    swiperFailed,
  } = useSelector(state => state.apiAdmin);
  const [nameButton, setNameButton] = useState('Создать');
  const [visible, setVisible] = useState({
    form: false,
    delete: false,
  });
  const [slide, setSlide] = useState(slideInit);
  const [file, setFile] = useState<string | null>(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const { handleChange } = useFormSwiper(slide, setSlide);
  const [errorTitle, setErrorTitle] = useState('');

  useEffect(() => {
    if (swiperFailed && errorObject?.statusCode === 422) {
      const textError = errorObject?.detail?.[0] as TAPIObjectError;
      (textError.loc[1] === 'title') && setErrorTitle(messages.swiperError);
    }
  }, [swiperFailed, errorObject]);

  useEffect(() => {
    if (file) setError('');
  }, [file]);

  useEffect(() => {
    if (swiperSuccess) {
      setVisible({
        form: false,
        delete: false,
      });
      setSlide(slideInit);
      setFile(null);
    }
  }, [swiperSuccess]);

  useEffect(() => {
    if (visible.form) {
      if (slide.id) setNameButton('Создать');
      else setNameButton('Скрыть форму');
    }
    else {
      if (!slide.id) {
        setNameButton('Создать');
        setSlide(slideInit);
      }
    }
  }, [visible.form, slide.id]);

  const onClickEdit = (slide: TSlide) => {
    setSlide(slide);
    setVisible({
      ...visible,
      form: true,
    });
  };

  const onClickToggle = () => {
    if (nameButton === 'Создать') {
      setVisible({
        ...visible,
        form: true,
      });
      setSlide(slideInit);
    }
    else setVisible({
      ...visible,
      form: false,
    });
  };

  const handleChangeImage = (file: string) => {
    setFile(file)
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.textContent?.includes('Создать')) {
      if (file) {
        const dataPostSwiper: TAPISwiper = {
          title: slide.title,
          text: slide.text,
          image: file,
          'display-order': slide['display-order'],
          available: slide.available,
        };
        dispatch(postSwiperThunk(dataPostSwiper));
      } else {
        setError('*Необходимо выбрать изображение');
      }
    } else {
      const dataUpdateSwiper: TAPISwiper = {
        title: slide.title,
        text: slide.text,
        image: file || '',
        'display-order': slide['display-order'],
        available: slide.available,
      };
      dispatch(updateSwiperThunk(dataUpdateSwiper, slide.id));
    }
  };

  const onClickDelete = (slide: TSlide) => {
    setSlide(slide)
    setVisible({
      form: false,
      delete: true,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteSwiperThunk(slide.id));
    setVisible({
      ...visible,
      delete: false,
    });
  };

  const handleCloseModal = () => {
    setVisible({
      form: false,
      delete: false,
    });
    setSlide(slideInit);
  };

  const form = (
    <FormAdmin 
      form='swiper'
      onSubmit={onClickSubmit}
      className={styles.form}
      classFieldset={styles.fieldset}
      handleChange={handleChange}
      values={slide}
      handleChangeImage={handleChangeImage}
      error={error}
      handleCancel={handleCloseModal}
      id={slide.id}
      legend={visible.form && !slide.id ? 'Создание слайда' : 'Редактирование слайда'}
    >
      <Textarea
        name='text'
        handleChange={handleChange}
        rows={5}
        maxLength={260}
        required
        value={slide.text}
        label='Текст слайда'
      />
    </FormAdmin>
  );

  const modalError = (
    <Modal header='' onClose={() => setErrorTitle('')}>
      <Text 
        text={errorTitle}
        className={styles.text_order}
      />
    </Modal>
  );

  return (
    <section className={styles.content}>
      <Button
        className={cn(stylesButton.button_admin, styles.button)}
        clickHandler={onClickToggle}
      >
        <Text text={nameButton} />
      </Button>
      {visible.form && !slide.id && form}
      <List className={styles.list}>
        {slides?.map(item => (
          <ListItem 
            key={item.id} 
            className={cn(
              styles.item, 
              visible.form && slide.id === item.id ? styles.content : styles.container
            )}
          >
            {((slide.id !== item.id) || (slide.id === item.id && visible.delete)) && (
              <>
                <img src={item.image} alt={item.title} className={styles.image} />
                <Title
                  type='h4'
                  text={item.title}
                  className={styles.title}
                />
                <Text text={item.text} className={styles.text} />
                <Text text={`Порядок отображения: ${item['display-order']}`} />
                <Text text={item.available ? 'Отображается' : 'Не отображается'} />
              </>
            )}
            {visible.form && slide.id === item.id ? 
              form : 
              <>
                <EditButton 
                  onClickHandler={() => {onClickEdit(item)}} 
                  extraClass={styles.end}
                />
                <CloseButton
                  onClose={() => onClickDelete(item)} 
                  size='small'
                  del={true}
                  extraClass={styles.end}
                />
              </>
            }
          </ListItem>
        ))}
      </List>
      {errorTitle && modalError}
      {visible.delete && (
        <ModalDelete
          header='Подтвердите удаление слайда:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={slide.title}
        />
      )}
    </section>
  )
}