import { AxiosRequestConfig } from 'axios';
import { TAPIDataUser, TAPIParamsObject } from '../types/api';
import { USERS_URL } from '../../utils/constants';
import { 
  blogAPI,
  injectBearerToken,
  makeParams,
} from '../helpers/api';
import { 
  IFetchUpdateUser, 
  IFetchUsers, 
  IFetchUsersMeData, 
} from './api.type';

export const fetchUsersMeData: IFetchUsersMeData = (
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${USERS_URL}/me`,
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchUpdateUser: IFetchUpdateUser = (
  data: TAPIDataUser,
  token: string,
  id: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${USERS_URL}/${id}`,
    method: 'PATCH',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};

export const fetchUsers: IFetchUsers = (
  token: string,
  queryParams?: TAPIParamsObject,
) => {
  const {
    id, skip, limit, category, search, unanswered
  } = queryParams ?? {};
  const requestConfig: AxiosRequestConfig = {
    url: USERS_URL,
    params: makeParams(id, skip, limit, category, search, unanswered),
    method: 'get',
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};