import { 
  FC, 
  useEffect, 
  useState 
} from 'react';
import styles from './input-box.module.css';
import cn from 'classnames';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';

interface IInputBoxProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  className?: string,
  value: string,
  reset?: boolean,
  onClickButtonUp: () => void,
  onClickButtonDown: () => void,
  inputRef?: React.Ref<HTMLInputElement>;
  disabled?: boolean;
}

const InputBox: FC<IInputBoxProps> = ({
    onChange,
    className,
    value = '1',
    reset,
    onClickButtonUp,
    onClickButtonDown,
    inputRef,
    disabled,
  }) => {

  const [ inputValue, setInputValue ] = useState(value);

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
    if (reset) {
      setInputValue('1')
    }
  }, [value, reset]);

  return (
    <div className={cn(styles.box, className)}>
      <Button 
        clickHandler={onClickButtonDown}
        className={styles.button}
        disabled={disabled}
      >
        <Text text='-' className={styles.text} />
      </Button>
      <input
        onChange={onChange}
        className={styles.input}
        value={inputValue}
        maxLength={4}
        type='text'
        name='count'
        ref={inputRef}
      />
      <Button 
        clickHandler={onClickButtonUp}
        className={styles.button}
      >
        <Text text='+' className={styles.text} />
      </Button>
    </div>
  )
}

export default InputBox;
