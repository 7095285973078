import { AppThunk } from '../../services/types/store';
import { TAPIError, TAPIUpdateDeliveryDate } from '../../services/types/api';
import { 
  patchDeliveryDatesFailed,
  patchDeliveryDatesRequest,
  patchDeliveryDatesSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchPatchDeliveryDates } from '../../services/api/api-delivery-dates';

const updateDeliveryDateThunk: AppThunk = (
  data: TAPIUpdateDeliveryDate,
  dateId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(patchDeliveryDatesRequest());
    await fetchPatchDeliveryDates(data, dateId, token);
    dispatch(patchDeliveryDatesSuccess());
  } catch (error) {
    dispatch(patchDeliveryDatesFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default updateDeliveryDateThunk;