import { AppThunk } from '../../services/types/store';
import { TAPIError } from '../../services/types/api';
import { 
  deleteSwiperFailed,
  deleteSwiperRequest,
  deleteSwiperSuccess,
} from '../../store/api-admin-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { fetchDeleteSwiper } from '../../services/api/api-swiper';

const deleteSwiperThunk: AppThunk = (
  slideId: string,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(deleteSwiperRequest());
    await fetchDeleteSwiper(slideId, token);
    dispatch(deleteSwiperSuccess());
  } catch (error) {
    dispatch(deleteSwiperFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteSwiperThunk;