import { AppThunk } from '../../services/types/store';
import {
  TAPICategory, 
  TAPIError 
} from '../../services/types/api';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import { 
  postCategoryFailed, 
  postCategoryRequest, 
  postCategorySuccess 
} from '../../store/api-admin-slice';
import { fetchPostCategory } from '../../services/api/api-products';

const postCategoryThunk: AppThunk = (
  data: TAPICategory,
) => async (dispatch, getState) => {
  const token = getState().user.token || '';
  try {
    dispatch(postCategoryRequest());
    await fetchPostCategory(data, token);
    dispatch(postCategorySuccess());
  } catch (error) {
    dispatch(postCategoryFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default postCategoryThunk;