import { NavLink, Outlet } from 'react-router-dom';
import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import styles from './delivery-layout.module.css';

function DeliveryLayout() {
  return (
    <section className={styles.container}>
      <nav>
        <List className={styles.list}>
          <ListItem>
            <NavLink
              to={''}
              end
              className={({ isActive }) =>
                isActive ? styles.link_active : styles.link
              }
            >
              События
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              to={'type'}
              className={({ isActive }) =>
                isActive ? styles.link_active : styles.link
              }
            >
              Тип
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              to={'point'}
              className={({ isActive }) =>
                isActive ? styles.link_active : styles.link
              }
            >
              Точка
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              to={'date'}
              className={({ isActive }) =>
                isActive ? styles.link_active : styles.link
              }
            >
              Дата
            </NavLink>
          </ListItem>
        </List>
      </nav>
      <Outlet />
    </section>
  )
}

export default DeliveryLayout;
