import { FC } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import editIcon from '../../images/edit.svg';

interface IEditButtonProps {
  extraClass?: string;
  onClickHandler: () => void;
}

const EditButton: FC<IEditButtonProps> = ({ 
  extraClass, 
  onClickHandler,
}) => (
  <button 
    type='button' 
    className={cn(styles.outline, extraClass)} 
    onClick={onClickHandler}
  >
    <img 
      src={editIcon} 
      alt='Карандаш' 
      className={cn(styles.image, styles.image_small)} 
    />
  </button>
);

export default EditButton;
