import { FC, CSSProperties } from 'react';
import styles from './form.module.css';
import cn from 'classnames';

interface IFormProps {
  className?: string;
  name: string;
  onSubmit: (e: React.FormEvent) => void;
  children: React.ReactNode;
  style?: CSSProperties;
  classFieldset?: string;
  legend?: string;
};

const Form: FC<IFormProps> = ({
  className,
  name,
  onSubmit,
  children,
  style,
  classFieldset,
  legend,
}) => (
  <form 
    name={name} 
    className={cn(styles.form, className)} 
    onSubmit={onSubmit}
    style={style}
  >
    <fieldset 
      className={cn(
        classFieldset ? classFieldset : styles.fieldset,
        legend ? styles.fieldset_legend : styles.border,
      )}
    >
        {legend && <legend className={styles.legend}>{legend}</legend>}
        {children}
    </fieldset>
  </form>
)

export default Form;