import { AppThunk } from '../../services/types/store';
import { cart } from '../../services/helpers/api';
import { TUpdateCartElement } from '../../services/types/data';
import { updateDataCart } from '../../store/cart-slice';
import { TAPIError } from '../../services/types/api';
import { updateMyCartFailed } from '../../store/api-slice';
import { AxiosError } from 'axios';
import makeErrorObject from '../../services/helpers/make-error-object';
import updateMyCartThunk from './update-my-cart';

const deleteDataCartThunk: AppThunk = (
  id: string,
  isActive: boolean,
) => async (dispatch, getState) => {
  const dataCart = getState().cart.dataCart;
  const token = getState().user.token;
  try {
    const newDataCart = [...dataCart].filter(el => el.seedling !== id);
    dispatch(updateDataCart(newDataCart));
    if (!isActive) {
      cart.set(newDataCart);
    } else {
      const dataUpdate: Array<TUpdateCartElement> = newDataCart.map(el => ({
        'seedling-id': el.seedling,
        count: el.count,
      }));
      token && dispatch(updateMyCartThunk(dataUpdate, token));
    }

  } catch (error) {
    dispatch(updateMyCartFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default deleteDataCartThunk;