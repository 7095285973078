import List from '../../ui/list';
import ListItem from '../../ui/list-item';
import Text from '../../ui/text';
import Title from '../../ui/title/title';
import { VIDEO_URL } from '../../utils/constants';
import { linksNav, linksVideo, textAbout } from '../../utils/data';
import mainStyles from '../main/main.module.css';
import styles from './about.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export function AboutPage() {
  return (
    <HelmetProvider>
      <main className={mainStyles.content}>
        <Helmet>
          <title>{linksNav.about}</title>
        </Helmet>
        <section className={styles.section}>
          <Title type='h3' text='Наша деятельность' className={styles.title} />
          {textAbout.map((text, index) => (
            <Text text={text} key={index} className={styles.text} />
          ))}
        </section>
        <section>
          <Title type='h3' text='Видео о нашем питомнике' className={styles.title} />
          <List className={styles.list}>
            {linksVideo.map((link, index) => (
              <ListItem key={index}>
                <iframe src={`${VIDEO_URL}${link}`} title={`Видео №${index}`} allowFullScreen className={styles.iframe}>
                </iframe>
              </ListItem>
            ))}
          </List>
        </section>
      </main>
    </HelmetProvider>
  )
}