import { useEffect } from 'react';
import Swiper from '../../components/swiper/swiper';
import styles from './main.module.css';
import Categories from '../../components/categories/categories';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { linksNav } from '../../utils/data';

export function MainPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HelmetProvider>
      <main className={styles.content}>
        <Helmet>
          <title>{linksNav.title}</title>
        </Helmet>
        <Swiper />
        <Categories />
      </main>
    </HelmetProvider>
  )
};