import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getQuestionsFailed,
  getQuestionsRequest,
  getQuestionsSuccess,
  setLastPage,
  setSkip,
} from '../../store/api-admin-slice';
import { fetchQuestions } from '../../services/api/api-questions';
import { TAPIError, TAPIParamsObject } from '../../services/types/api';
import { LIMIT_QUESTIONS } from '../../utils/constants';
import { getQuestions } from '../../store/admin-slice';

const getQuestionsThunk: AppThunk = (
  params: TAPIParamsObject,
) => async (dispatch, getState) => {
  const questions = getState().admin.questions;
  try {
    dispatch(getQuestionsRequest());
    dispatch(setSkip(params.skip ? params.skip + LIMIT_QUESTIONS : LIMIT_QUESTIONS));
    const data = (await fetchQuestions(params)).data;
    const newQuestions = params.skip === 0 ? [...data] : [...questions, ...data];
    dispatch(getQuestions(newQuestions));
    dispatch(setLastPage(data.length < LIMIT_QUESTIONS));
    dispatch(getQuestionsSuccess());
  } catch (error) {
    dispatch(getQuestionsFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getQuestionsThunk;