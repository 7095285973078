import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUser } from '../services/types/data';

type TUserState = {
  readonly id: string | null;
  readonly token: string | null;
  readonly email: string | null;
  readonly 'first-name': string | null;
  readonly 'last-name': string | null;
  readonly discount: number;
  readonly 'phone-number': string | null;
  readonly 'postal-code': string | null;
  readonly region: string | null;
  readonly city: string | null;
  readonly location: string | null;
  readonly 'is-active': boolean;
  readonly 'is-superuser': boolean;
  readonly 'is-admin': boolean;
  readonly 'is-wholesaler': boolean;
};

const initialState: TUserState = {
  id: null,
  token: null,
  email: null,
  'first-name': null,
  'last-name': null,
  discount: 0,
  'phone-number': null,
  'postal-code': null,
  region: null,
  city: null,
  location: null,
  'is-active': false,
  'is-superuser': false,
  'is-admin': false,
  'is-wholesaler': false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    resetToken: state => ({
      ...state,
      token: null,
    }),
    setUserData: (state, action: PayloadAction<TUser>) => ({
      ...state,
      ...action.payload,
    }),
    updateToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    clearUser: state => ({
      ...state,
      ...initialState,
    }),
  },
});

const userReducer = userSlice.reducer;

export const {
  setToken,
  resetToken,
  setUserData,
  updateToken,
  clearUser,
} = userSlice.actions;

export default userReducer;
