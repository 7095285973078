import { 
  useEffect, 
  useState, 
  useRef 
} from 'react';
import { 
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from '../../services/hooks';
import styles from './element.module.css';
import getSeedlingThunk from '../../thunks/products/seedling';
import Title from '../../ui/title/title';
import Text from '../../ui/text';
import Button from '../../ui/buttons/button';
import Divider from '../../ui/divider';
import cn from 'classnames';
import { TButtonState, TDataCartElement } from '../../services/types/data';
import InputBox from '../input-box/input-box';
import { Loader } from '../../ui/loader/loader';
import addDataCartThunk from '../../thunks/carts/add-data-cart';
import updateDataCartThunk from '../../thunks/carts/update-data-cart';
import { priceFormat } from '../../utils/functions';
import Questions from '../questions/questions';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Element() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { seedling } = useSelector(state => state.main);
  const { 
    seedlingSuccess, 
    postQuestionSuccess,
    postScoreSuccess,
  } = useSelector(state => state.api);
  const { dataCart } = useSelector(state => state.cart);
  const { 
    'is-wholesaler': isWholesaler,
    'is-active': isActive,
    token,
  } = useSelector(state => state.user);
  const { id } = useParams<{ id?: string }>();
  const [ inputValue, setInputValue ] = useState(1);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: '',
    class: '',
    disabled: false,
  });

  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getSeedlingThunk(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (postQuestionSuccess || postScoreSuccess) {
      dispatch(getSeedlingThunk(id))
    };
  }, [postQuestionSuccess, postScoreSuccess, dispatch, id]);

  useEffect(() => {
    if (seedling && seedling.stock === 0) {
      setButtonState(prevState => ({
        ...prevState,
        text: 'В корзину',
        disabled: true,
      }))
      setInputValue(0);
    }
  }, [seedling]);

  useEffect(() => {
    if (seedling?.stock !== 0) {
      if(dataCart.some((el) => `${el.seedling}` === id)) {
        setButtonState(prevState =>({
          ...prevState,
          text: 'Оформить',
          class: styles.button_active,
        }))
        const qty = dataCart.find((el) => `${el.seedling}` === id)!.count;
        setInputValue(qty);
      } else setButtonState(prevState => ({
        ...prevState,
        text: 'В корзину',
      }))
    }
  }, [dataCart, id, seedling?.stock]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target: number = + e.target.value.replace(/\D/g, '');
    if (target === 0) {
      setInputValue(1);
    } else if (target > seedling!.stock) {
      setInputValue(seedling!.stock)
    } else setInputValue(target)
  };

  const onClickButtonUp = () => {
    if (inputValue < seedling!.stock) {
      setInputValue(inputValue + 1)
    } else setInputValue(seedling!.stock)
  };

  const onClickButtonDown = () => {
    if (inputValue > 1) {
      setInputValue(inputValue - 1)
    } else setInputValue(1)
  };

  const onClickButtonCart = () => {
    if (buttonState.text === 'В корзину') {
      if (seedling) {
        const element: TDataCartElement<string> = {
          seedling: seedling.id,
          count: inputValue,
        };
        dispatch(addDataCartThunk(element, token));
      }
    } 
    if (buttonState.text === 'Оформить') {
      const element = {
        seedling: id,
        count: inputValue
      };
      dispatch(updateDataCartThunk(element, isActive))
      navigate('/cart')
    }
  };

  if (!seedlingSuccess || (!seedling)) {
    return <Loader size='large' />
  }

  return (
    <HelmetProvider>
      <article className={styles.content}>
        <Helmet>
          <title>{seedling.title}</title>
        </Helmet>
        <img src={seedling.image} alt={seedling.title} className={styles.image} />
        <Title type='h3' text={seedling.title || ''} className={styles.title} />
        <div className={cn(styles.box, seedling.score && styles.color)}>
          {[...Array(5)].map((_, index) => {
            const fullStars = Math.floor(seedling.score);
            const hasHalfStar = seedling.score % 1 > 0;
            if (index < fullStars) {
              return <span key={index}>★</span>;
            } else if (index === fullStars && hasHalfStar) {
              return <span key={index}>★</span>;
            }
            return <span key={index}>☆</span>;
          })}
          <Text 
            text={seedling.score ? ` ${seedling.score.toFixed(1)}` : ' Нет оценок'}
            className={styles.caption}
          />
        </div>
        <div className={styles.container}>
          <Text 
            text={seedling.stock > 0 ? `Всего ${seedling.stock} шт.` : 'Нет в наличии'} 
            className={styles.text} 
          />
          <Text className={styles.text}>
            Цена {isWholesaler ? 
              priceFormat(seedling['wholesale-price']) : 
              priceFormat(seedling['retail-price'])
            }
          </Text>
          <InputBox
            value={String(inputValue)}
            onChange={handleValueChange}
            onClickButtonUp={onClickButtonUp}
            onClickButtonDown={onClickButtonDown}
            inputRef={inputRef}
            disabled={buttonState.disabled}
          />
          <Button 
            className={cn(styles.button, buttonState.class)} 
            clickHandler={onClickButtonCart}
            disabled={buttonState.disabled}
          >
            <Text text={buttonState.text} />
          </Button>
        </div>
        <div 
          dangerouslySetInnerHTML={{__html: seedling['full-description']}}
          className={styles.inner}
        ></div>
        {(seedling.questions.length > 0 || isActive) && <Divider className={styles.divider} />}
        <Questions 
          questions={seedling.questions} 
          isActive={isActive}
        />
      </article>
    </HelmetProvider>
  )
};

export default Element;