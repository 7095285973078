import { 
  useCallback, 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import { useDispatch, useSelector } from '../../services/hooks';
import getQuestionsThunk from '../../thunks/questions/questions';
import { LIMIT_QUESTIONS } from '../../utils/constants';
import Text from '../../ui/text';
import styles from '../../components/questions/questions.module.css';
import Questions from '../../components/questions/questions';
import Button from '../../ui/buttons/button';

export function QuestionsPage() {
  const dispatch = useDispatch();
  const { 
    skip, 
    answerSuccess, 
    deleteQuestionSuccess,
    lastPage,
    questionsRequest,
  } = useSelector(state => state.apiAdmin);
  const { questions } = useSelector(state => state.admin);
  const [nameButton, setNameButton] = useState('Показать неотвеченные');

  const observerRef = useRef<IntersectionObserver>();
  const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 500);
  const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

  const getQuestions = useCallback((currentSkip: number) => {
    if (nameButton === 'Показать неотвеченные') {
      dispatch(getQuestionsThunk({
        skip: currentSkip,
        limit: LIMIT_QUESTIONS,
      }))
    } else {
      dispatch(getQuestionsThunk({
        skip: currentSkip,
        limit: LIMIT_QUESTIONS,
        unanswered: true,
      }))
    }
  }, [dispatch, nameButton]);

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !lastPage) {
        clearTimeout(loadMoreTimeoutRef.current);
        loadMoreTimeoutRef.current = setTimeout(() => {
          getQuestions(skip);
        }, 500);
      }
    },
    [loadMoreTimeoutRef, skip, getQuestions, lastPage]
  );

  const loadMoreCallback = useCallback(
    (el: HTMLLIElement) => {
      if (questionsRequest) return;
      if (observerRef.current) observerRef.current.disconnect();
      const option: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      observerRef.current = new IntersectionObserver(handleObserver, option);
      if (el) observerRef.current.observe(el);
    },
    [handleObserver, questionsRequest]
  );

  useEffect(() => {
    getQuestions(0);
  }, [getQuestions]);

  useEffect(() => {
    if (answerSuccess || deleteQuestionSuccess) {
      getQuestions(0);
    }
  }, [answerSuccess, deleteQuestionSuccess, getQuestions]);

  const onClickFilter = () => {
    setNameButton(
      nameButton === 'Показать все' ? 'Показать неотвеченные' : 'Показать все'
    )
  };

  return (
    <section className={styles.content}>
      <Button
        className={styles.button_admin}
        clickHandler={onClickFilter}
      >
        <Text text={nameButton} />
      </Button>
      <Questions 
        questions={questions} 
        admin={true} 
        loadMoreCallback={loadMoreCallback}
      />
    </section>
  )
}