import { 
  Navigate, 
  Outlet, 
  useLocation, 
  useNavigate
} from 'react-router-dom';
import { useSelector } from '../../services/hooks';
import { useEffect, useState } from 'react';
import { Loader } from '../../ui/loader/loader';
import { TOKEN } from '../../utils/constants';
import { deleteCookie, getCookie } from '../../services/helpers/api';

export function PrivateRoutes() {
  const { usersMeSuccess, errorObject } = useSelector(state => state.api);
  const { 
    'is-active': isActive,
    'is-admin': isAdmin,
    'is-superuser': isSuperUser,
  } = useSelector(state => state.user);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const refreshToken = getCookie(TOKEN);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin || isSuperUser) {
      if (location.pathname.includes('admin-panel')) {
        navigate(location.pathname, { replace: true });
      }
      else navigate('/admin-panel/orders', { replace: true });
    };
  }, [isAdmin, isSuperUser, location.pathname, navigate]);

  useEffect(() => {
    if (usersMeSuccess) {
      setLoading(false);
    } else setLoading(true);
    if (errorObject) setLoading(false)
  }, [usersMeSuccess, errorObject]);

  if (loading && refreshToken) {
    return <Loader size='small' />
  }

  return (
    isActive ? <Outlet /> : <Navigate to='/login' replace state={{ from: location }} />
  )
}