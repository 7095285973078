import { 
  useEffect, 
  useMemo, 
  useState, 
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from '../../services/hooks';
import Title from '../../ui/title/title';
import getOrderThunk from '../../thunks/orders/order';
import { 
  dateFormatter, 
  filterEmptyStrings, 
  priceFormat 
} from '../../utils/functions';
import Text from '../../ui/text';
import CartItem from '../../components/cart-item/cart-item';
import styles from './order.module.css';
import cn from 'classnames';
import List from '../../ui/list';
import FormOrder from '../../components/form-order/form-order';
import { 
  TDataOrderElement, 
  TDeliveryPoint, 
  TDeliveryType, 
  TFormOrder, 
} from '../../services/types/data';
import { useFormOrder } from '../../utils/validation';
import updateOrderThunk from '../../thunks/orders/update-order';
import Modal from '../../components/modal/modal';
import getDeliveryChooseDateThunk from '../../thunks/delivery-events/delivery-events-date';
import Button from '../../ui/buttons/button';
import ListSearchSeedlings from '../../components/list-search-seedlings/list-search-seedlings';
import cancelOrderThunk from '../../thunks/orders/cancel-order';
import ModalDelete from '../../components/modal-delete/modal-delete';
import createStatementThunk from '../../thunks/orders/create-statement';
import { TAPIObjectError } from '../../services/types/api';


function OrderPage() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { 
    token,
    'is-admin': isAdmin,
    'is-superuser': isSuperuser,
  } = useSelector(state => state.user);
  const { 
    order, 
    deliveryTypes, 
    deliveryPoints, 
    deliveryChooseDates 
  } = useSelector(state => state.main);
  const { 
    patchOrderSuccess,
    cancelOrderSuccess,
    createStatementSuccess,
    errorObject,
  } = useSelector(state => state.api);
  const deliveryTypesShow: Array<TDeliveryType> | null = deliveryTypes?.filter(type => type.show) || null;
  const deliveryPointsShow: Array<TDeliveryPoint> | null = deliveryPoints?.filter(point => point.show) || null;

  const dataOrderInit: TFormOrder = {
    id: '',
    items: order?.items.map(el => ({
      'seedling-id': el.seedling.id,
      count: el.count,
    })) || [],
    email: order?.email || '',
    'first-name': order?.['first-name'] || '',
    'last-name': order?.['last-name'] || '',
    'phone-number': order?.['phone-number'] || '+7',
    'delivery-type': order?.delivery.type.type || '',
    'delivery-point': order?.delivery.point.point || '',
    'delivery-date': order?.delivery?.date?.date || '',
    'delivery-comment': order?.delivery.comment || '',
  };
  const [dataOrder, setDataOrder] = useState<TFormOrder>(dataOrderInit);
  const { handleChange } = useFormOrder(dataOrder, setDataOrder);
  const [deliveryPointsFilter, setDeliveryPointsFilter] = useState(deliveryPointsShow);
  const [visible, setVisible] = useState({
    success: false,
    cancel: false,
    error: false,
  });
  const [modalText, setModalText] = useState('');

  const [edit, setEdit] = useState(false);

  const [addSeedling, setAddSeedling] = useState(false);
  const [addOrderItems, setAddOrderItems] = useState<Array<TDataOrderElement>>([]);

  const deliveryPointId = deliveryPoints?.find(point => point.point === dataOrder['delivery-point'])?.id;

  useEffect(() => {
    if (patchOrderSuccess || cancelOrderSuccess) {
      setAddOrderItems([]);
      setVisible({
        success: true,
        cancel: false,
        error: false,
      });
      setEdit(false);
      window.scrollTo(0, 0);
    }
  }, [patchOrderSuccess, cancelOrderSuccess]);

  useEffect(() => {
    const textError = errorObject?.detail?.[0] as TAPIObjectError;
    if (errorObject?.statusCode === 422 && textError.msg === 'The delivery date is required') {
      setModalText('Нет доступных дат для выбранной точки доставки. Выберите другую точку доставки');
      setVisible(prevVisible => ({
        ...prevVisible,
        error: true,
      }));
    }
    if (errorObject?.statusCode === 422 && textError.msg === 'Phone number invalid') {
      setModalText('Введите корректный номер телефона');
      setVisible(prevVisible => ({
        ...prevVisible,
        error: true,
      }));
    }
  }, [dispatch, errorObject]);

  useEffect(() => {
    setVisible({
      success: false,
      cancel: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    token && dispatch(getOrderThunk(id, token))
    if (createStatementSuccess && (isAdmin || isSuperuser)) {
      token && dispatch(getOrderThunk(id, token))
    }
  }, [token, cancelOrderSuccess, createStatementSuccess, id, dispatch, isAdmin, isSuperuser]);

  useEffect(() => {
    // console.log('order && !edit', order && !edit)
    if (order && !edit) {
      setDataOrder({
        id: order.id,
        items: order.items.map(el => ({
          'seedling-id': el.seedling.id,
          count: el.count,
        })),
        email: order.email || '',
        'first-name': order['first-name'],
        'last-name': order['last-name'],
        'phone-number': order['phone-number'],
        'delivery-type': order.delivery.type.type,
        'delivery-point': order.delivery.point.point,
        'delivery-date': order.delivery?.date?.date || '',
        'delivery-comment': order.delivery.comment || '',
      });
      setAddOrderItems([]);
    }
  }, [order, edit]);

  const dataOrderType = dataOrder['delivery-type'];
  const dataOrderPoint = dataOrder['delivery-point'];

  const deliveryTypeId = deliveryPointsShow && deliveryTypesShow?.find(type => type.type === dataOrderType)?.id;
  const currentPointsFilter = useMemo(
    () => deliveryPointsShow?.filter(point => point['delivery-type-id'] === deliveryTypeId),
    [deliveryPointsShow, deliveryTypeId]
  );
  // const pointShow = currentPointsFilter?.length 
  //   ? (currentPointsFilter.some(point => point.point === dataOrderPoint) 
  //   ? dataOrderPoint 
  //   : currentPointsFilter[0].point) 
  //   : '';
  const [pointShow, setPointShow] = useState(dataOrderPoint)

  useEffect(() => {
    // console.log('currentPointsFilter', currentPointsFilter?.length, currentPointsFilter?.some(point => point.point === dataOrderPoint))
    // currentPointsFilter?.length && setPoointShow(currentPointsFilter[0].point)
    if (order && currentPointsFilter?.length) {
      const pointValue = order.delivery.point.point;
      if (currentPointsFilter.some(point => point.point === pointValue)) setPointShow(pointValue)
      else setPointShow(currentPointsFilter[0].point)
    } else setPointShow('')

  }, [currentPointsFilter, order]);
  // // console.log(pointShow, dataOrderPoint, currentPointsFilter)

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // console.log(deliveryTypeId, order?.delivery.type.id)
    deliveryTypeId && setToggle(true);
  }, [deliveryTypeId])

  useEffect(() => {
    // console.log('deliveryTypeId && toggle && edit', deliveryTypeId && toggle && edit)
    if (deliveryTypeId && toggle && edit) {
      // console.log('deliveryTypeId && toggle && edit', 'okkkkk')
      currentPointsFilter && setDeliveryPointsFilter(currentPointsFilter)
      setDataOrder(prevData => ({
        ...prevData,
        'delivery-point': pointShow,
      }))
      setToggle(false);
    }
  }, [dataOrderType, deliveryTypeId, pointShow, toggle, currentPointsFilter, edit]); 

  const deliveryPointShowId = deliveryPointsShow?.find(point => point.point === dataOrderPoint)?.id;

  useEffect(() => {
    // console.log('deliveryPointShowId && edit', deliveryPointShowId && edit)
    if (deliveryPointShowId && edit) {
      dispatch(getDeliveryChooseDateThunk(deliveryPointShowId));
    }
  }, [dataOrderPoint, edit, deliveryPointShowId, dispatch]);

  const deliveryDate = (deliveryPointId === order?.delivery.point.id && order?.delivery?.date?.date) || null;

  // console.log(deliveryDate, dataOrder['delivery-date'], order?.delivery?.date?.date)


  // const [pointShow, setPointShow] = useState(dataOrderPoint)

  // useEffect(() => {
  //   console.log('currentPointsFilter', currentPointsFilter?.length, currentPointsFilter?.some(point => point.point === dataOrderPoint))
  //   // currentPointsFilter?.length && setPoointShow(currentPointsFilter[0].point)
  //   if (order && currentPointsFilter?.length) {
  //     const pointValue = order.delivery.point.point;
  //     if (currentPointsFilter.some(point => point.point === pointValue)) setPointShow(pointValue)
  //     else setPointShow(currentPointsFilter[0].point)
  //   } else setPointShow('')

  // }, [currentPointsFilter, order]);



  useEffect(() => {
    // console.log('deliveryChooseDates && edit', deliveryChooseDates?.length, edit, deliveryDate)
    if (deliveryChooseDates?.length && edit) {
      // console.log('77777777777777777777')
      setDataOrder(prevData => ({
        ...prevData,
        // 'delivery-date': deliveryDate,
        'delivery-date': deliveryPointId === order?.delivery.point.id ? deliveryDate : deliveryChooseDates[0].date,

        // 'delivery-date': deliveryChooseDates.some(date => date.date === deliveryDate) ? deliveryDate : deliveryChooseDates[0].date,
      }))
    }
    if (deliveryChooseDates?.length === 0 && edit) {
      setDataOrder(prevData => ({
        ...prevData,
        'delivery-date': null,
      }));
    }
  }, [deliveryChooseDates, edit, deliveryDate, deliveryPointId, order?.delivery.point.id]);

  const onClickEdit = () => {
    setEdit(!edit);
  };

  const onClickCancelOrder = () => {
    setVisible({
      ...visible,
      cancel: true,
    });
  };

  const confirmDelete = () => {
    dispatch(cancelOrderThunk(id, token));
  };

  const onClickSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (dataOrder.items.length === 0) {
      setModalText('Добавьте товар в заказ или отмените заказ');
      setVisible(prevVisible => ({
        ...prevVisible,
        error: true,
      }));
    } else {
      let dataUpdateOrder: TFormOrder = ({
        ...dataOrder,
        'delivery-type': deliveryTypesShow?.find(type => type.type === dataOrder['delivery-type'])?.id || '',
        'delivery-point': deliveryPointsShow?.find(point => point.point === dataOrder['delivery-point'])?.id || '',
        'delivery-date': deliveryChooseDates?.find(date => date.date === dataOrder['delivery-date'])?.id || null,
      });
      if (!dataUpdateOrder['delivery-date'] && 
        deliveryPointId === order?.delivery?.point?.id && 
        order?.delivery?.date?.id) {
        dataUpdateOrder = {
          ...dataUpdateOrder,
          'delivery-date': order?.delivery?.date?.id,
        }
      }
      const filteredDataOrder = filterEmptyStrings(dataUpdateOrder);
      dispatch(updateOrderThunk(filteredDataOrder, id, token));
    }
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleCloseModal = () => {
    setVisible({
      success: false,
      cancel: false,
      error: false,
    });
  };

  const createStatement = () => {
    dispatch(createStatementThunk([id], token));
  }

  const modalSuccess = (
    <Modal header={cancelOrderSuccess ? 'Заказ отменён' : 'Заказ изменён'} onClose={handleCloseModal}>
      <Text 
        text={`Номер заказа: № ${order?.number} от ${order && dateFormatter(new Date(order['created-at']))}`}
        className={styles.text_order}
      />
    </Modal>
  );

  const modalError = (
    <Modal header='' onClose={handleCloseModal}>
      <Text 
        text={modalText}
        className={styles.text_order}
      />
    </Modal>
  );

  const discount = (100 - (order?.discount || 0)) * 0.01;

  const dataAmount = order && dataOrder.items.length > 0 && dataOrder.items.map(el => {
    const item = addOrderItems.find(elem => elem.seedling.id === el['seedling-id']) ||
      order.items.find(elem => elem.seedling.id === el['seedling-id'])

    if (item) {
      return ({
        count: el.count,
        price: item.price,
      })
    } else return ({
      count: 0,
      price: 0,
    })
  });

  const amount = dataAmount && (dataAmount.reduce((
    acc, item
  ) => acc + item.count * item.price, 0));

  const onClickAdd = () => {
    setAddSeedling(true);
  };

  return (
    <section className={styles.content}>
      <Title 
        type='h3' 
        text={`№ ${order?.number} от ${order && 
          dateFormatter(new Date(order['created-at']))}`
        }
        className={styles.title}
      />
      {order?.cancelled ? (
        <Title
          type='h3'
          className={styles.cancel}
          text='Заказ отменён'
        />
      ) : (
        <>
          {(!order?.['not-editable'] || isSuperuser || isAdmin) && (
            <>
              <Button
                clickHandler={onClickCancelOrder}
                className={cn(styles.button, styles.right, styles.cancel)}
              >
                <Text text='Отменить заказ' />
              </Button>
              <Button
                className={cn(styles.button, styles.right)}
                clickHandler={onClickEdit}
              >
                <Text text={edit ? 'Отменить редактирование' : 'Редактировать заказ'} />
              </Button>
            </>
          )}
          {order?.['not-editable'] && (
            <Text
              className={cn(styles.italic, styles.right)}
              text={`Заказ принят в обработку${!(isSuperuser || isAdmin) ? 'и не подлежит редактированию' : ''}`}
            />
          )}
        </>
      )}
      <List className={styles.list}>
        {dataOrder && order?.items.map((item, index) => {
          const countItem = dataOrder.items.find(el => {
            return (
              el['seedling-id'] === item.seedling.id && 
              !addOrderItems.some(elem => elem.seedling.id === el['seedling-id'])
            )})?.count
          return countItem && (
            <CartItem
              key={item.seedling.id}
              item={item.seedling}
              count={countItem}
              price={item.price}
              dataOrder={dataOrder}
              setDataOrder={setDataOrder}
              disabled={!edit}
            />
          )
        })}
        {addOrderItems.map(item => {
          const countItem = dataOrder.items.find(el => el['seedling-id'] === item.seedling.id)?.count
          const price = order?.user?.['is-wholesaler'] ? 
            item.seedling['wholesale-price'] : 
            item.seedling['retail-price']
          return countItem && (
            <CartItem
              key={item.seedling.id}
              item={item.seedling}
              count={countItem}
              price={price * discount}
              dataOrder={dataOrder}
              setDataOrder={setDataOrder}
              setAddOrderItems={setAddOrderItems}
              addOrderItems={addOrderItems}
            />
          )
        })}
      </List>
      <Text className={cn(styles.right, styles.amount)}>
        {(Math.trunc(amount || 0) !== order?.amount && edit) ?
          `Итого к оплате: ${priceFormat(Math.trunc(amount || 0))}` :
          order && `Итого к оплате: ${priceFormat(order.amount)}`
        }
        {order && Math.trunc(amount || 0) !== order?.amount && edit && 
          <span className={cn(styles.italic, styles.span)}>
            {`(${priceFormat(order.amount)})`}
          </span>
        }
      </Text>
      {order?.discount !== 0 && <Text
        text={order ? `(Применена скидка ${order.discount}%)` : ''}
        className={cn(styles.right, styles.italic)}
      />}
      {edit && (
        <div className={styles.box}>
          {addSeedling ? (
            <ListSearchSeedlings
              setSearchVisible={setAddSeedling}
              setOrderItems={setAddOrderItems}
              orderItems={addOrderItems}
              dataOrder={dataOrder}
              setDataOrder={setDataOrder}
              discount={discount}
              isWholesaler={order?.user?.['is-wholesaler'] || false}
            />
          ) : (
            <Button
              className={styles.button}
              clickHandler={onClickAdd}
              >
              <Text text='Добавить товар' />
            </Button>
          )}
        </div>
      )}
      {!edit && !order?.cancelled && (
        <Button
          clickHandler={createStatement}
          className={cn(styles.button, styles.right, styles.table)}
        >
          <Text text='Сформировать ведомость' />
        </Button>
      )}
      {edit ? (
        <FormOrder
          value={dataOrder}
          handleClickSubmit={onClickSubmit}
          handleChange={handleChange}
          pointsFilter={deliveryPointsFilter}
          handleCancel={handleCancel}
          isValid={true}
          edit={true}
        />
      ) : (
        <div className={styles.container}>
          <Text className={styles.info}>
            <span className={styles.italic}>Покупатель: </span>
            {`${order?.['first-name']} ${order?.['last-name']}`}
          </Text>
          <Text 
            text={order?.email} 
            className={cn(styles.text, styles.mail, styles.info)}
          />
          <Text 
            text={order?.['phone-number']}
            className={cn(styles.text, styles.tel, styles.info)}
          />
          <Text className={styles.info}>
            <span className={styles.italic}>Тип доставки: </span>
            {order?.delivery.type.type}
          </Text>
          <Text className={styles.info}>
            <span className={styles.italic}>Точка доставки: </span>
            {order?.delivery.point.point}
          </Text>
          {order?.delivery.date && (
            <Text className={styles.info}>
              <span className={styles.italic}>Дата доставки: </span>
              {dateFormatter(new Date(order.delivery.date.date))}
            </Text>
          )}
          {order?.delivery.comment && (
            <Text className={styles.info}>
              <span className={styles.italic}>Комментарий: </span>
              {order.delivery.comment}
            </Text>
          )}
        </div>
      )}
      {visible.success && modalSuccess}
      {visible.error && modalError}
      {visible.cancel && (
        <ModalDelete
          header='Подтвердите отмену заказа:'
          handleCloseModal={handleCloseModal}
          confirmDelete={confirmDelete}
          text={`№ ${order?.number} от ${order && 
            dateFormatter(new Date(order['created-at']))}`
          }
        />
      )}
    </section>
  )
};

export default OrderPage;