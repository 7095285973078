import { AxiosRequestConfig } from 'axios';
import { TAPIDataScore } from '../types/api';
import { SCORES_URL } from '../../utils/constants';
import { blogAPI, injectBearerToken } from '../helpers/api';
import { IFetchPostScore } from './api.type';

export const fetchPostScore: IFetchPostScore = (
  data: TAPIDataScore,
  token: string,
) => {
  const requestConfig: AxiosRequestConfig = {
    url: SCORES_URL,
    method: 'POST',
    data,
  };
  return blogAPI(injectBearerToken(requestConfig, token));
};