import makeErrorObject from '../../services/helpers/make-error-object';
import { AxiosError } from 'axios';
import { AppThunk } from '../../services/types/store';
import { 
  getOrderFailed,
  getOrderRequest,
  getOrderSuccess,
} from '../../store/api-slice';
import { getOrder } from '../../store/main-slice';
import { TAPIError } from '../../services/types/api';
import { fetchOrder } from '../../services/api/api-orders';

const getOrderThunk: AppThunk = (
  id: string,
  token: string,
) => async (dispatch) => {
  try {
    dispatch(getOrderRequest());
    const response = await fetchOrder(id, token);
    dispatch(getOrder(response.data));
    dispatch(getOrderSuccess());
  } catch (error) {
    dispatch(getOrderFailed(makeErrorObject(error as AxiosError<TAPIError>)));
  }
};

export default getOrderThunk;